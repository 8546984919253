<template>
  <div v-if="isVisibleAssistantModal" class="config-assistant-modal">
    <generic-modal @close-confirmation="handleAssistantModal">
      <template #body>
        <p class="generic-modal-content">
          {{ langFilter(getModalBody) }}
          <span class="link" @click="goToConfig">
            {{ langFilter("clickHere").toLowerCase() }}
          </span>
        </p>
      </template>
    </generic-modal>
  </div>
  <div v-if="loadingData" class="loading-data-container">
    <img :src="require('@/assets/img/general/carga.gif')" alt="loading" />
  </div>
  <div
    class="answer-princ-div-body"
    :class="{ 'solution-answer-map-opened': mapOpened }"
  >
    <!-- question -->
    <div class="solution-div-question">
      <div
        v-if="shouldShowNotification"
        :id="`solution-notification-circle-${answer.solution_id}-${answer.question_id}`"
        :name="`solution-notification-circle-${answer.solution_id}`"
        class="solution-notification-circle"
      />
      <p :id="'question-' + answer.question_id" class="solution-title-answer">
        {{ answerIndex + 1 }}- {{ langFilter(answer.question) }}
      </p>
      <!-- <img
        v-if="space_id == 19930 && askedGptIncludesAnswer"
        @click="restartGpt(answerIndex, answer.question, false)"
        :src="Retry"
        class="chatGptRetry"
        alt="retry"
      /> -->
      <img
        v-if="showBot"
        :src="askedGptIncludesAnswer ? CloseGPT : ChatbotLogo"
        :class="askedGptIncludesAnswer ? 'closeChatLogo' : 'chatBotLogo'"
        alt="ChatBotLogo"
        @click="handleChatBoxAction"
      />
      <p
        class="solution-description-answer"
        v-html="answer.question_description"
      />
      <div v-if="askedGptIncludesAnswer" class="gpt-by-question">
        <div class="selector-gpt">
          <chat-files
            :lang="lang"
            :project-id="projectId"
            :assistant-type="assistantType"
          />
          <button
            :class="buttonSelected === 0 && 'gpt-type-selected'"
            :disabled="askingGpt"
            @click="selectButton(0)"
          >
            {{ langFilter('editPrompt') }}
          </button>
          <button
            :disabled="askingGpt"
            :class="buttonSelected === 1 && 'gpt-type-selected'"
            @click="selectButton(1)"
          >
            {{ langFilter('currentQuestion') }}
          </button>
          <button
            :disabled="askingGpt"
            :class="buttonSelected === 2 && 'gpt-type-selected'"
            @click="selectButton(2)"
          >
            {{ langFilter('expertChat') }}
          </button>
        </div>
        <chat-bot-project
          v-if="showChat"
          :assistantsProject="assistants"
          :lang="lang"
          :projectId="projectId"
          :questionId="answer.question_id"
          :showAllChat="showAllChat"
          :preButtonSelected="answer.question_id ? buttonSelected : null"
          is-from-itinerary
          @copy-content="useResponse($event)"
          @reload-gpt="reloadChatGpt($event)"
          @reload-edit-prompt="
            editedPrompt = true;
            showQuestionChat = true;
          "
          @set-edited-prompt="buttonSelected = 1"
          @edit-prompt="editPrompt()"
          @asking-gpt="askingGpt = $event"
        />
      </div>
    </div>
    <div
      v-if="answer.question_type == 1 && hided !== answer.question_id"
      :id="[ showMemberBox ? `froala-activated-${answer.question_id}` : '' ]"
      :disabled="!showMemberBox"
      :class="[showMemberBox ? 'froala-activated' : 'solution-answer-body',]"
      v-html="froalaAnswer"
    />
    <textarea
      v-else-if="answer.question_type == 2"
      :value="froalaAnswer"
      class="solution-answer-body"
      cols="30"
      rows="10"
      :disabled="isTutorInProject"
      @keyup="writeSolution($event, answer.answer_id)"
    />

    <div v-else-if="answer.question_type == 0" class="solution-answer-body">
      <div class="solution-file-answer div-center">
        <button
          v-if="!isTutorInProject"
          class="solutions-upload-file-btn"
          @click="$refs.file.click()"
        >
          {{ langFilter("uploadFile") }}
        </button>
        <input
          v-if="!isTutorInProject"
          ref="file"
          style="display: none"
          type="file"
          @change="uploadSolutionFile($event, answer.question_id, answerIndex)"
        />
        <p v-if="!isTutorInProject" class="max-size">[Max: 5Mb]</p>
        <div
          v-if="answer.answer != ''"
          class="file-uploaded"
          @click="openFile(answer.answer)"
        >
          {{ getAnswerFilename(answer.answer) }}
        </div>
        <p v-else class="file-uploaded-txt">
          {{ langFilter("noAnswersYet") }}
        </p>
      </div>
    </div>
    <form class="solution-div-question-form" v-else>
      <ul>
        <li
          v-for="(option, optionIndex) in answer.answer_options.split(
            '[;separator;]'
          )"
          :key="optionIndex"
        >
          <input
            :id="`option-${answer.answer_id}-${option}`"
            class="solution-input-answer"
            :type="answer.question_type == 10 ? 'checkbox' : 'radio'"
            :checked="checkOption(answer.answer, option)"
            :disabled="isTutorInProject || checkboxDisabled == answer.answer_id"
            @click="
              uncheckSameOption(
                $event,
                answer,
                option,
                answer.question_type,
                optionIndex,
                false
              )
            "
            @change="
              selectOption(
                $event,
                answer,
                option,
                answer.question_type,
                optionIndex,
                true
              )
            "
          />
          <label
            :for="`option-${answer.answer_id}-${option}`"
            class="'solution-label-answer"
            :class="[
              isTutorInProject
                ? 'solution-label-admin'
                : 'solution-label-member',
            ]"
          >
            {{ langFilter(option) }}
          </label>
        </li>
      </ul>
    </form>
    <p
      v-if="savingMsg == answer.answer_id && !isTutorInProject"
      class="solution-answer-time"
    >
      {{ langFilter("saving") }}...
    </p>
    <p v-else-if="answer.answer_date" class="solution-answer-time">
      {{ langFilter("savedAt") }}
      {{ getDataTime(1, answer.answer_date) }}
      {{ compareDates(answer.answer_date) }}
    </p>
    <div
      v-if="isTutoringAllowed && hasTutorsOrAnswer"
      class="solution-tutor-comment-div"
    >
      <p class="answer-comment-title">{{ langFilter("comments") }}</p>
      <div
        :id="isTutorInProject ? 'froala-activated-' + answer.question_id : ''"
        :disabled="!isTutorInProject"
        :class="[
          isTutorInProject ? 'froala-activated' : 'solution-answer-body',
        ]"
        v-html="froalaFeedbackAnswer"
      />
      <p
        v-if="savingMsg == answer.answer_id && role != 2"
        class="solution-answer-time"
      >
        {{ langFilter("saving") }}...
      </p>
      <p v-else-if="answer.feedback_answer_date" class="solution-answer-time">
        {{ langFilter("savedAt") }}
        {{ getDataTime(1, answer.feedback_answer_date) }}
        {{ compareDates(answer.feedback_answer_date) }}
      </p>
    </div>
    <!-- <div v-if="
      loadedPrev !== solution_id &&
      answers.length === answerIndex + 1 &&
      answers.length < 2
    " class="footer-solution-just-1" /> -->
  </div>
</template>

<script>
/* eslint-disable */
import translationMixin from "../../mixins/translationMixin.js";
import generalMethodsMixin from "@/mixins/generalMethodsMixin.js";
import FroalaEditor from "../../../node_modules/froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import $ from "jquery";
import axios from "axios";
import ChatbotLogo from "./images/chatbot.svg";
import CloseGPT from "./images/close-gpt.png";
import Send from "./images/send.png";
import Retry from "../solution/images/retry.png";
import Copy from "./images/copy.png";
import FileIcon from "./images/file.svg";
import FileGptIcon from "./images/file-gpt-icon.png";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/table.min.js";
import { ROLES_IN_PROJECT, ASSISTANTS_TYPE } from "@/constants/index.js";
import DOMPurify from 'dompurify';
const { TUTOR, MEMBER } = ROLES_IN_PROJECT;
const { EXPERT } = ASSISTANTS_TYPE;

export default {
  props: [
    "role",
    "mapOpened",
    "answer",
    "edited",
    "answerIndex",
    "answerIndex2",
    "solutionId",
    "projectId",
    "loadedPrev",
    "lang",
    "cdti",
    "spaceId",
    "assistants",
  ],
  inject: ["projectHasTutors", "showAlert", "spaceConfig"],
  data() {
    return {
      buttonSelected: 1,
      showChat: true,
      saving: "",
      savingMsg: "",
      currentDate: "",
      questionSelected: "",
      indexSelected: "0",
      oldQuestion: 0,
      checkboxDisabled: false,
      changeTriggered: false,
      askedGpt: [],
      ChatGPT: ChatbotLogo,
      CloseGPT: CloseGPT,
      ChatbotLogo: ChatbotLogo,
      Send: Send,
      Retry: Retry,
      Copy: Copy,
      FileIcon: FileIcon,
      FileGptIcon: FileGptIcon,
      filesUploaded: [],
      gptResponses: [],
      gptResponsesError: [],
      continueChatting: [],
      continueChattingInput: [],
      previousUserMessages: [],
      previousBotMessages: [],
      modifiedPrompt: "",
      searchingGpt: false,
      hided: null,
      editingGptPrompt: -1,
      showAllChat: false,
      prevStateBot: [],
      prevStateUser: [],
      showChat: true,
      sendedRespGpt: false,
      editedPrompt: false,
      selectedTab: 1,
      askingGpt: false,
      assistantType: EXPERT,
      froalaAnswer: this.answer.answer,
      froalaFeedbackAnswer: this.answer.feedback_answer,
      isVisibleAssistantModal: false,
      messages: [],
      loadingData: false,
      traducciones: [
        {
          name: "comments",
          es: "Comentarios del tutor",
          en: "Tutor comments",
        },
        {
          name: "savedAt",
          es: "Guardado a las ",
          en: "Saved at ",
        },
        {
          name: "on",
          es: "del",
          en: "on",
        },
        {
          name: "optional",
          es: "Escriba aquí los comentarios...",
          en: "Write here your comments...",
        },
        {
          name: "noOptions",
          es: "No hay opciones para esta pregunta",
          en: "There are no options for this question",
        },
        {
          name: "saving",
          es: "Guardando",
          en: "Saving",
        },
        {
          name: "uploadFile",
          es: "Subir archivo",
          en: "Upload file",
        },
        {
          name: "maxSize",
          es: "El tamaño máximo del archivo es de 5Mb",
          en: "File size must be less than 5MB",
        },
        {
          name: "noAnswersYet",
          es: "No hay respuestas aún",
          en: "No answers yet",
        },
        {
          name: "noFile",
          es: "No hay archivo",
          en: "No file",
        },
        {
          name: "Which of the following sectors are your company working on?",
          es: "¿En qué sector está trabajando su empresa?",
          en: "Which of the following sectors are your company working on?",
        },
        {
          name: "Manufacturing",
          es: "Manufactura",
          en: "Manufacturing",
        },
        {
          name: "Service",
          es: "Servicio",
          en: "Service",
        },
        {
          name: "Digitalization & IOT",
          es: "Digitalización e IOT",
          en: "Digitalization & IOT",
        },
        {
          name: "Construction",
          es: "Construcción",
          en: "Construction",
        },
        {
          name: "Pharma",
          es: "Farmacéutica",
          en: "Pharma",
        },
        {
          name: "Education",
          es: "Educación",
          en: "Education",
        },
        {
          name: "Hospitality",
          es: "Hospitalidad",
          en: "Hospitality",
        },
        {
          name: "Agriculture Forestry And Fishing",
          es: "Agricultura, silvicultura y pesca",
          en: "Agriculture Forestry And Fishing",
        },
        {
          name: "Electricity, Gas, Steam And Air Conditioning Suppl",
          es: "Suministro de electricidad, gas, vapor y aire acondicionado",
          en: "Electricity, Gas, Steam And Air Conditioning Suppl",
        },
        {
          name: "Transportation And Storage",
          es: "Transporte y almacenamiento",
          en: "Transportation And Storage",
        },
        {
          name: "Information And Communication",
          es: "Información y comunicación",
          en: "Information And Communication",
        },
        {
          name: "Financial And Insurance Activities",
          es: "Actividades financieras y de seguros",
          en: "Financial And Insurance Activities",
        },
        {
          name: "Public Administration And Defense, Compulsary Social Security",
          es: "Administración pública y defensa, seguridad social obligatoria",
          en: "Public Administration And Defense, Compulsary Social Security",
        },
        {
          name: "Human Health And Social Work Securities",
          es: "Actividades de salud humana y servicios sociales",
          en: "Human Health And Social Work Securities",
        },
        {
          name: "Other Activities",
          es: "Otras actividades",
          en: "Other Activities",
        },
        {
          name: "Does your benefit maintain with any levels of sales?",
          es: "¿Su beneficio se mantiene con cualquier nivel de ventas?",
          en: "Does your benefit maintain with any levels of sales?",
        },
        {
          name: "Yes, I have the same benefit with any amount of sales",
          es: "No, mi beneficio disminuye con más ventas que tenemos",
          en: "Yes, I have the same benefit with any amount of sales",
        },
        {
          name: "No, my benefit reduces with more sales we have",
          es: "No, mi beneficio aumenta con más ventas que tenemos",
          en: "No, my benefit reduces with more sales we have",
        },
        {
          name: "No, my benefit increases with more sales we have",
          es: "No he empezado a vender",
          en: "No, my benefit increases with more sales we have",
        },
        {
          name: "I have not started to sell",
          es: "No estoy seguro",
          en: "I have not started to sell",
        },
        {
          name: "",
          es: "Sí, tengo el mismo beneficio con cualquier cantidad de ventas",
          en: "",
        },
        {
          name: "I am unsure",
          es: "No estoy seguro",
          en: "I am unsure",
        },
        {
          name: "Technology Readiness Level. Which of the following best describes the current:",
          es: "Nivel de preparación tecnológica. ¿Cuál de los siguientes describe mejor la situación actual?",
          en: "Technology Readiness Level. Which of the following best describes the current:",
        },
        {
          name: "TRL 1 – Basic Principles Observed and Reported",
          es: "TRL 1 - Principios básicos observados y reportados",
          en: "TRL 1 – Basic Principles Observed and Reported",
        },
        {
          name: "TRL 2 – Potential Application Validated",
          es: "TRL 2 - Aplicación potencial validada",
          en: "TRL 2 – Potential Application Validated",
        },
        {
          name: "TRL 3. Experimental proof of concept",
          es: "TRL 3 - Prueba de concepto experimental",
          en: "TRL 3. Experimental proof of concept",
        },
        {
          name: "TRL 4. Component and/or breadboard validation in laboratory environment",
          es: "TRL 4 - Validación de componentes y/o prototipo en un ambiente de laboratorio",
          en: "TRL 4. Component and/or breadboard validation in laboratory environment",
        },
        {
          name: "TRL 5 – Component and/or breadboard validation in relevant environment",
          es: "TRL 5 - Validación de componentes y/o prototipo en un ambiente relevante",
          en: "TRL 5 – Component and/or breadboard validation in relevant environment",
        },
        {
          name: "TRL 6 – System/subsystem model or prototype demonstration in a relevant environment",
          es: "TRL 6 - Demostración de modelo o prototipo de sistema/subsistema en un ambiente relevante",
          en: "TRL 6 – System/subsystem model or prototype demonstration in a relevant environment",
        },
        {
          name: "TRL 7 – System prototype demonstration in an operational environment",
          es: "TRL 7 - Demostración de prototipo de sistema en un ambiente operacional",
          en: "TRL 7 – System prototype demonstration in an operational environment",
        },
        {
          name: "TRL 8 – Actual system completed and qualified through test and demonstration",
          es: "TRL 8 - Sistema real completado y calificado a través de pruebas y demostración",
          en: "TRL 8 – Actual system completed and qualified through test and demonstration",
        },
        {
          name: "TRL 9 – Actual system proven through successful mission operations",
          es: "TRL 9 - Sistema real demostrado a través de operaciones de misión exitosas",
          en: "TRL 9 – Actual system proven through successful mission operations",
        },
        {
          name: "It does not apply to my bussiness",
          es: "No aplica a mi negocio",
          en: "It does not apply to my bussiness",
        },
        {
          name: "How many leads you contact convert into a paying client?",
          es: "¿Cuántos clientes potenciales que contacta convierte en clientes que pagan?",
          en: "How many leads you contact convert into a paying client?",
        },
        {
          name: "Less than 25%",
          es: "Menos del 25%",
          en: "Less than 25%",
        },
        {
          name: "Between 26 - 50%",
          es: "Entre el 26 - 50%",
          en: "Between 26 - 50%",
        },
        {
          name: "Between 51-75%",
          es: "Entre el 51-75%",
          en: "Between 51-75%",
        },
        {
          name: "More than 76%",
          es: "Más del 76%",
          en: "More than 76%",
        },
        {
          name: "I am unsure of the conversion rate",
          es: "No estoy seguro de la tasa de conversión",
          en: "I am unsure of the conversion rate",
        },
        {
          name: "How much have you raised on the first five years of your company/startup (founders funds, FFF Funds, Angel Rounds and Venture Capital)?",
          es: "¿Cuánto ha recaudado en los primeros cinco años de su empresa/startup (fondos de los fundadores, rondas de amigos, familiares y ángeles y capital de riesgo)?",
          en: "How much have you raised on the first five years of your company/startup (founders funds, FFF Funds, Angel Rounds and Venture Capital)?",
        },
        {
          name: "Less than 250.000€",
          es: "Menos de 250.000€",
          en: "Less than 250.000€",
        },
        {
          name: "More than 5.000.000€",
          es: "Más de 5.000.000€",
          en: "More than 5.000.000€",
        },
        {
          name: "How would you best describe your business experience?",
          es: "¿Cómo describiría mejor su experiencia empresarial?",
          en: "How would you best describe your business experience?",
        },
        {
          name: "Experience as a C-Level executive in any sector",
          es: "Experiencia como ejecutivo de alto nivel en cualquier sector",
          en: "Experience as a C-Level executive in any sector",
        },
        {
          name: "Experience in your industry sector",
          es: "Experiencia en su sector industrial",
          en: "Experience in your industry sector",
        },
        {
          name: "Experience in Sales or Technology",
          es: "Experiencia en ventas o tecnología",
          en: "Experience in Sales or Technology",
        },
        {
          name: "No Experience",
          es: "No tengo experiencia",
          en: "No Experience",
        },
        {
          name: "Dedication to the project (Choose only one):",
          es: "Dedicación al proyecto (elija solo uno):",
          en: "Dedication to the project (Choose only one):",
        },
        {
          name: "1 founder dedicated part-time",
          es: "1 fundador dedicado a tiempo parcial",
          en: "1 founder dedicated part-time",
        },
        {
          name: "1 founder dedicated full-time",
          es: "1 fundador dedicado a tiempo completo",
          en: "1 founder dedicated full-time",
        },
        {
          name: "2 founders dedicated part-time",
          es: "2 fundadores dedicados a tiempo parcial",
          en: "2 founders dedicated part-time",
        },
        {
          name: "1 founder full-time and 1 part-time",
          es: "1 fundador a tiempo completo y 1 a tiempo parcial",
          en: "1 founder full-time and 1 part-time",
        },
        {
          name: "2 founders, or more, dedicated full-time",
          es: "2 fundadores o más dedicados a tiempo completo",
          en: "2 founders, or more, dedicated full-time",
        },
        {
          name: "How do you sell to your clients?",
          es: "¿Cómo vende a sus clientes?",
          en: "How do you sell to your clients?",
        },
        {
          name: "What stage is your product at the moment?",
          es: "¿En qué etapa se encuentra su producto en este momento?",
          en: "What stage is your product at the moment?",
        },
        {
          name: "I have developed wireframes/designs/blueprints/models",
          es: "He desarrollado wireframes/diseños/planos/modelos",
          en: "I have developed wireframes/designs/blueprints/models",
        },
        {
          name: "I have developed a Minimum Viable Product",
          es: "He desarrollado un producto mínimo viable (MVP)",
          en: "I have developed a Minimum Viable Product",
        },
        {
          name: "I have launched my MVP have using customers",
          es: "He lanzado mi MVP y tengo clientes usándolo",
          en: "I have launched my MVP have using customers",
        },
        {
          name: "It is just an idea at the moment",
          es: "Solo es una idea en este momento",
          en: "It is just an idea at the moment",
        },
        {
          name: "It does not apply to my bussiness",
          es: "No aplica a mi negocio",
          en: "It does not apply to my bussiness",
        },
        {
          name: "What is the size of target market in total sales?",
          es: "¿Cuál es el tamaño del mercado objetivo en ventas totales?",
          en: "What is the size of target market in total sales?",
        },
        {
          name: "<$50 Million",
          es: "Menos de 50 millones de euros",
          en: "<$50 Million",
        },
        {
          name: "$50 - $100 Million",
          es: "50 - 100 millones de euros",
          en: "$50 - $100 Million",
        },
        {
          name: ">$100 Million",
          es: "Más de 100 millones de euros",
          en: ">$100 Million",
        },
        {
          name: "Looking at the present, How would you rate the success of your company/project?",
          es: "11- Mirando al presente, ¿cómo calificaría el éxito de su empresa/proyecto?",
          en: "Looking at the present, How would you rate the success of your company/project?",
        },
        {
          name: "gptError",
          es: "Ha ocurrido un error al conectar con el Asistente, por favor inténtelo de nuevo de más tarde o contacte con:",
          en: "An error has occurred while connecting to the Assistant, please try again later or contact with:",
        },
        {
          name: "tryAgain",
          es: "Volver a Intentar",
          en: "Try Again",
        },
        {
          name: "continueChatting",
          es: "Seguir la Conversación",
          en: "Continue Chatting",
        },
        {
          name: "expertRemovedFromProject",
          es: "Se ha eliminado el experto del proyecto. Si deseas configurar uno, haz",
          en: "The expert has been removed from the project. If you wish to configure one, do",
        },
        {
          name: "projectDoestNotHaveAssistant",
          es: "Aún no tienes un asistente experto configurado. Si deseas configurar uno, haz",
          en: "Yo don't have an expert assistant set up yet. If you wish to configure one, do",
        },
        {
          name: "clickHere",
          es: "Click aquí",
          en: "Click here",
        },
        {
          name: "formalGPT",
          es: "hazme que el texto que te voy a poner a continuación este escrito de manera más formal",
          en: "make the text that I am going to put next written in a more formal way",
        },
        {
          name: "cutGPT",
          es: "Acortame el texto que te voy a poner a continuación",
          en: "make the text that I am going to put next shorter",
        },
        {
          name: "longGPT",
          es: "Alargame el texto que te voy a poner a continuación",
          en: "make the text that I am going to put next longer",
        },
        {
          name: "makeFormal",
          es: "hazlo más formal",
          en: "make it more formal",
        },
        {
          name: "makeCut",
          es: "hazlo más corto",
          en: "make it shorter",
        },
        {
          name: "makeLong",
          es: "hazlo más largo",
          en: "make it longer",
        },
        {
          name: "extend",
          es: "Alargar",
          en: "Extend",
        },
        {
          name: "cut",
          es: "Cortar",
          en: "Cut",
        },
        {
          name: "formal",
          es: "Formal",
          en: "Formal",
        },
        {
          name: "editPrompt",
          es: "Editar Prompt",
          en: "Edit Prompt"
        },
        {
          name: "currentQuestion",
          es: "Pregunta Actual",
          en: "Current Question"
        },
        {
          name: "expertChat",
          es: "Chat del experto",
          en: "Expert Chat"
        },
        {
          name: "sendResponse",
          es: "Enviar respuesta al experto",
          en: "Send response to expert"
        },
        {
          name: "sent",
          es: "Enviado",
          en: "Sent"
        },
        {
          name: "sending",
          es: "Enviando",
          en: "Sending"
        },
        {
          name: "contentTooLong",
          es: "El contenido de la respuesta es demasiado largo para ser procesado por el Asistente, por favor reduzcalo a un máximo de 32000 caracteres.",
          en: "The response content is too long to be processed by the Assistant, please reduce it to a maximum of 32000 characters."
        },
        {
          name: "responseCannotBeEmpty",
          es: "Para poder enviar la respuesta a el Asistente, la respuesta no puede estar vacía.",
          en: "To send the response to the Assistant, the response cannot be empty."
        },
        {
          name: "errorSendingResponse",
          es: "Error enviando la respuesta al Asistente",
          en: "Error sending response to Assistant"
        },
        {
          name: "close",
          es: "Cerrar",
          en: "Close"
        },
        {
          name: "IA",
          es: "IA",
          en: "AI"
        },
        {
          name: "askChatGPT",
          es: "Preguntale a la IA",
          en: "Ask the AI"
        },
      ],
    };
  },
  computed: {
    showBot() {
      return this.role === MEMBER;
    },
    currentQuestionClass() {
      return this.showAllChat ? "" : "gpt-type-selected";
    },
    projectChatClass() {
      return this.showAllChat ? "gpt-type-selected" : "";
    },
    askedGptIncludesAnswer() {
      return this.askedGpt.includes(this.answerIndex);
    },
    solutionHasExpert() {
      const expertType = 1;

      return (
        this.assistants?.find(
          ({ assistant_type: type }) => type === expertType
        ) || null
      );
    },
    getModalBody() {
      return this.messages.length
        ? "expertRemovedFromProject"
        : "projectDoestNotHaveAssistant";
    },
    hasTutorsOrAnswer() {
      return this.projectHasTutors || this.answer?.answer_id;
    },
    isTutoringAllowed() {
      const SPACE_WITHOUT_COMMENT = 22680;

      return (
        this.answer?.tutoring === TUTOR &&
        !this.cdti &&
        this.spaceId !== SPACE_WITHOUT_COMMENT
      );
    },
    isTutorInProject() {
      return this.role === TUTOR;
    },
    shouldShowNotification() {
      const isTutorWithPendingCorrection =
        this.isTutorInProject &&
        this.answer.ready_correct_entrepreneur === 1 &&
        this.answer.send_correction_tutor === 1;

      const isEntrepreneurWithPendingCorrection =
        !this.isTutorInProject &&
        this.answer.ready_correct_tutor === 1 &&
        this.answer.send_correction_entrepreneur === 1;

      return (
        isTutorWithPendingCorrection || isEntrepreneurWithPendingCorrection
      );
    },
    showMemberBox () {
      return this.role === MEMBER && !this.spaceConfig.isAfterLimitDate(this.spaceId);
    }
  },
  emits: [
    "reloadChatGpt",
    "prepareAnswers",
    "reloadPercentage",
    "updateSendToCorrect",
    "updateSendToCorrect",
    "editedSolution",
    "errorFileUpload",
  ],
  methods: {
    selectButton(value) {
      if (!this.askingGpt) {
        if (value === 0) this.editPrompt();
        this.toggleChat(value === 2);
        this.showQuestionChat = value === 1;
        this.buttonSelected = value;
      }
    },
    
    checkEditPrompt() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/getProjectUserChatGpt/${this.projectId}/${this.answer.question_id}`
        )
        .then((res) => {
          if (res.data) {
            this.buttonSelected = 1;
            return true;
          }
          this.buttonSelected = 0;
          return false;
        })
        .catch((error) => {
          console.log(error);
          this.buttonSelected = 0;
          return false;
        });
    },
    getQuestionChat() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/getQuestionChat/${this.projectId}/${this.answer.question_id}`
        )
        .then((res) => {
          if (res.data) return true;
          return false;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    },
    editPrompt() {
      axios.delete(
        `${process.env.VUE_APP_API_URL}/chatgpt/removeFirstMessage/${this.projectId}/${this.answer.question_id}`
      );
      this.editedPrompt = false;
      this.showAllChat = false;
      this.askedGpt.splice(this.askedGpt.indexOf(this.answerIndex), 1);
      setTimeout(
        function () {
          this.askedGpt.push(this.answerIndex);
        }.bind(this),
        100
      );
    },
    toggleChat(value) {
      this.showAllChat = value;
    },
    initFroala() {
      const _token = $('meta[name="csrf-token"]').attr("content");
      const componentContext = this;

      FroalaEditor.DefineIcon("chatGPTsimple", {
        NAME: componentContext.langFilter("IA"),
        template: "text",
      });

      FroalaEditor.RegisterCommand("chatGPTsimple", {
        title: componentContext.langFilter("askChatGPT"),
        focus: false,
        undo: false,
        refreshAfterCallback: false,
        callback: async function () {
          try {
            const message = this.html.getSelected();
            const toDisplay = await componentContext.handleFroalaAction(
              "ask-gpt",
              message
            );
            this.html.insert(toDisplay, false);
          } catch (error) {
            console.error(error);
          }
        },
      });

      FroalaEditor.DefineIcon("chatGPTshorter", {
        NAME: componentContext.langFilter("cut"),
        template: "text",
      });

      FroalaEditor.RegisterCommand("chatGPTshorter", {
        title: componentContext.langFilter("makeCut"),
        focus: false,
        undo: false,
        refreshAfterCallback: false,
        callback: async function () {
          try {
            const message = this.selection.text();
            const toDisplay = await componentContext.handleFroalaAction(
              "ask-gpt-query",
              message,
              "cutGPT"
            );
            this.html.insert(toDisplay);
          } catch (error) {
            console.error(error);
          }
        },
      });

      FroalaEditor.DefineIcon("chatGPTlonger", {
        NAME: componentContext.langFilter("extend"),
        template: "text",
      });

      FroalaEditor.RegisterCommand("chatGPTlonger", {
        title: componentContext.langFilter("makeLong"),
        focus: false,
        undo: false,
        refreshAfterCallback: false,
        callback: async function () {
          try {
            const message = this.selection.text();
            const toDisplay = await componentContext.handleFroalaAction(
              "ask-gpt-query",
              message,
              "longGPT"
            );
            this.html.insert(toDisplay);
          } catch (error) {
            console.error(error);
          }
        },
      });

      FroalaEditor.DefineIcon("chatGPTformal", {
        NAME: componentContext.langFilter("formal"),
        template: "text",
      });

      FroalaEditor.RegisterCommand("chatGPTformal", {
        title: componentContext.langFilter("makeFormal"),
        focus: false,
        undo: false,
        refreshAfterCallback: false,
        callback: async function () {
          try {
            const message = this.selection.text();
            const toDisplay = await componentContext.handleFroalaAction(
              "ask-gpt-query",
              message,
              "formalGPT"
            );
            this.html.insert(toDisplay);
          } catch (error) {
            console.error(error);
          }
        },
      });

      FroalaEditor.DefineIcon("popupClose", { NAME: "times", SVG_KEY: "back" });
      FroalaEditor.RegisterCommand("popupClose", {
        title: componentContext.langFilter("close"),
        undo: false,
        focus: false,
        callback: function () {
          this.customPlugin.hidePopup();
        },
      });
      FroalaEditor.DefineIcon("buttonIcon", { NAME: componentContext.langFilter("IA"), template: "text" });
      FroalaEditor.RegisterCommand("IAButtons", {
        title: "",
        icon: "buttonIcon",
        undo: false,
        focus: false,
        plugin: "customPlugin",
        callback: function () {
          this.customPlugin.showPopup();
        },
      });

      FroalaEditor.POPUP_TEMPLATES["customPlugin.popup"] = "[_BUTTONS_]";
      Object.assign(FroalaEditor.DEFAULTS, {
        popupButtons: [
          "popupClose",
          "|",
          "chatGPTsimple",
          "chatGPTshorter",
          "chatGPTlonger",
          "chatGPTformal",
        ],
      });

      FroalaEditor.PLUGINS.customPlugin = function (editor) {
        function initPopup() {
          // Popup buttons.
          let popup_buttons = "";
          if (editor.opts.popupButtons.length > 1) {
            popup_buttons += '<div class="fr-buttons">';
            popup_buttons += editor.button.buildList(editor.opts.popupButtons);
            popup_buttons += "</div>";
          }

          // Load popup template.
          const template = {
            buttons: popup_buttons,
          };

          // Create popup.
          const $popup = editor.popups.create("customPlugin.popup", template);

          return $popup;
        }

        function showPopup() {
          let $popup = editor.popups.get("customPlugin.popup");

          if (!$popup) $popup = initPopup();

          editor.popups.setContainer("customPlugin.popup", editor.$tb);

          const $btn = editor.$tb.find('.fr-command[data-cmd="myButton"]');
          const left = editor.fullscreen.isActive() ? 500 : 800;
          const top = editor.fullscreen.isActive() ? 40 : 300;
          editor.popups.show(
            "customPlugin.popup",
            left,
            top,
            $btn.outerHeight()
          );
        }

        function hidePopup() {
          editor.popups.hide("customPlugin.popup");
        }
        return {
          showPopup: showPopup,
          hidePopup: hidePopup,
        };
      };

      new FroalaEditor("#froala-activated-" + this.answer.question_id, {
        placeholderText: this.langFilter("optional"),
        fontFamilyDefaultSelection: "Poppins",
        height: 174,
        attribution: false,
        trackChangesEnabled: false,
        showChangesEnabled: true,
        tableResizerOffset: 10,
        tableResizingLimit: 50,
        paragraphFormat: {
          N: "Normal",
          H1: "Heading 1",
          H2: "Heading 2",
          H3: "Heading 3",
          H4: "Heading 4",
        },
        wordDeniedTags: [
          "table",
          "form",
          "hr",
          "input",
          "select",
          "option",
          "textarea",
          "tr",
          "td",
          "th",
          "tbody",
          "thead",
          "tfoot",
          "col",
          "colgroup",
          "caption",
          "style",
          "script",
          "link",
          "meta",
          "title",
          "html",
        ],
        paragraphFormatSelection: true,
        paragraphMultipleStyles: true,
        wordPasteKeepFormatting: true,
        wordPasteModal: true,
        pastePlain: false, // Allow pasting with formatting
        pasteAllowedStyleProps: [
          "font",
          "font-size",
          "color",
          "background-color",
          "text-align",
        ], // Add allowed styles
        pasteDeniedAttrs: ["style", "class"], // Deny certain attributes if needed
        pasteDeniedTags: ["input"], // Deny certain tags if needed

        tableInsertButtons: ["tableBack"],
        tableCellMultipleStyles: true,
        tableColorsButtons: ["tableBack"],
        tableDefaultWidth: "200px",
        tableEditButtons: [
          "tableRows",
          "tableColumns",
          "tableCells",
          "tableCellVerticalAlign",
          "tableRemove",
        ],
        toolbarButtons: {
          moreText: {
            buttons: [
              "bold",
              "italic",
              "underline",
              "|",
              "fontFamily",
              "strikeThrough",
              "subscript",
              "superscript",
              "backgroundColor",
              "fontSize",
              "textColor",
              "inlineClass",
              "inlineStyle",
            ],
            buttonsVisible: 4,
          },
          moreParagraph: {
            buttons: [
              "|",
              "formatUL",
              "formatOL",
              "outdent",
              "indent",
              "paragraphFormat",
              "alignLeft",
              "alignCenter",
              "alignRight",
              "alignJustify",
              "quote",
              "lineHeight",
            ],
            buttonsVisible: 4,
          },
          moreRich: {
            buttons: [
              "|",
              "IAButtons",
              "|",
              "insertTable",
              "insertLink",
              "insertImage",
              "|",
              "fullscreen",
              "selectAll",
              "clearFormatting",
              "|",
              "undo",
              "redo",
            ],
            buttonsVisible: 13,
          },
          moreMisc: {
            buttons: ["|", "help"],
            align: "right",
            buttonsVisible: 6,
          },
        },
        inlineMode: false,
        inlineStyles: {
          Good: "font-size: 20px; font-weight: bold; color: green;",
          Neutral: "font-size: 20px; font-weight: bold; color: orange;",
          Bad: "font-size: 20px; font-weight: bold; color: red;",
        },
        fontFamily: {
          "'Arial',sans-serif": "Arial",
          "'Verdana',sans-serif": "Verdana",
          "'Helvetica',sans-serif": "Helvetica",
          "'Times New Roman',serif": "Times New Roman",
          "'Tahoma',sans-serif": "Tahoma",
          "'Trebuchet MS',sans-serif": "Trebuchet MS",
          "'Georgia',serif": "Georgia",
          "'Courier Prime',monospace": "Courier Prime",
          "'Lucida Sans Typewriter',monospace": "Lucida Sans Typewriter",
          "'Impact',sans-serif": "Impact",
          "'Poppins',sans-serif": "Poppins",
        },
        fontFamilySelection: true,
        dragInline: false,
        lineBreakerTags: ["table", "hr", "form"],
        listAdvancedTypes: false,
        pluginsEnabled: [
          "align",
          "charCounter",
          "colors",
          "draggable",
          "fontFamily",
          "fontSize",
          "inlineStyle",
          "image",
          "link",
          "lineBreaker",
          "lists",
          "paragraphFormat",
          "quickInsert",
          "save",
          "table",
          "track_changes",
          "trackChanges",
          "wordPaste",
          "fullscreen",
          "inlineClass",
          "html",
          "help",
          "quote",
          "lineHeight",
          "customPlugin",
        ],
        linkAlwaysBlank: false,
        quickInsertButtons: ["image", "table", "embedly", "ol", "ul"],
        key: process.env.VUE_APP_FROALA_KEY,
        imageUploadMethod: "POST",
        imageUploadParams: {
          _token: _token,
        },
        imageAllowedTypes: ["jpeg", "jpg", "png"],
        imageUploadURL: process.env.VUE_APP_API_URL + "/froala/upload/img",
        fileUploadMethod: "POST",
        fileUploadParams: {
          _token: _token,
        },
        fileUploadURL: process.env.VUE_APP_API_URL + "/froala/upload/files",
        videoUploadMethod: "POST",
        videoUploadParams: {
          _token: _token,
        },
        videoUploadURL: process.env.VUE_APP_API_URL + "/froala/upload/videos",

        //autosave
        saveInterval: 2000,
        saveParam: "content",
        saveURL: process.env.VUE_APP_API_URL + "/solutions/autosave",
        requestHeaders: this.getAuthorizationHeader(),
        saveMethod: "POST",
        saveParams: {
          id: this.answer.answer_id,
          solution_id: this.solutionId,
          project_id: this.projectId,
          question_id: this.answer.question_id,
          question_type: this.answer.question_type,
          role: this.role,
          indexSelected: this.indexSelected,
          _token: _token,
        },

        events: {
          // Handle paste event to ensure content can be pasted
          "paste.before": function (clipboardEvent) {
            const clipboardText = clipboardEvent.clipboardData.getData('text/plain');
            const clipboardHTML = clipboardEvent.clipboardData.getData('text/html').toString();
            

            if (!clipboardHTML.includes('body')) {
              this.html.insert(clipboardText, false);
              return false; // Prevent default paste behavior
            }

            if (clipboardHTML) {
              const parser = new DOMParser();
              const doc = parser.parseFromString(clipboardHTML, "text/html");
              const bodyContent = doc.body.innerHTML.trim();
              // Insert the URL as plain text
              this.html.insert(bodyContent, false);
              return false;
            }
            return true; // Allow other content types
          },

          //autosave
          "save.before": function () {
            this.savingMsg = this.answer.answer_id;
          }.bind(this),
          "save.after": function (request) {
            this.sendedRespGpt = false;
            const data = JSON.parse(request);
            const answerId = this.answer.answer_id;

            if (data["deleted"]) {
              this.updateDateAnswer(null);
              this.answer.answer_id = null;
            } else {
              this.updateDateAnswer(data["date"]);
              this.answer.answer_id = data["last_answer"];
              this.answer.isTutor = this.isTutorInProject;
            }

            this.$emit("prepareAnswers", [
              this.solutionId,
              this.answer.question_id,
              answerId,
              data,
              this.answerIndex2,
              this.answerIndex,
            ]);
            this.reloadData();
          }.bind(this),
          "save.error": function () {
            // console.log("error");
          },
        },
      });
    },
    reloadChatGpt() {
      this.$emit("reloadChatGpt");
      this.showChat = false;
      this.$nextTick(() => {
        this.showChat = true;
      });
    },
    openFileInput(event) {
      event.preventDefault();
      this.$refs.fileInput.click();
    },
    saveGptPromt(index, question_id) {
      if (this.modifiedPrompt !== "") {
        this.previousUserMessages[index] = this.modifiedPrompt;
        axios
          .post(`${process.env.VUE_APP_API_URL}/chatgpt/changeGptPrompt`, {
            question_id: question_id,
            gpt_prompt: this.modifiedPrompt,
          })
          .then(() => {
            this.searchingGpt = true;

            const type = this.preButtonSelected
              ? this.preButtonSelected
              : this.buttonSelected > 0
                ? this.buttonSelected
                : 1;
            this.askingGpt = true;
            axios
              .post(
                `${process.env.VUE_APP_API_URL}/projects/${this.projectId}/${type}`,
                {
                  project_id: this.projectId,
                  previousUserMessages: this.previousUserMessages,
                  previousBotMessages: this.previousBotMessages,
                }
              )
              .then((res) => {
                const response = res.data.generated_response;
                this.previousBotMessages[index] = response;
                this.searchingGpt = false;
              })
              .catch(() => {
                this.gptResponsesError[i] = true;
              })
              .finally(() => {
                this.askingGpt = false;
              });
            this.modifiedPrompt = "";
            this.editingGptPrompt = -1;
          })
          .catch((error) => {
            alert("Error modificando el prompt del Asistente");
            console.error(error);
          });
      }
    },
    setEditingGptPrompt(index, prompt) {
      this.editingGptPrompt = index;
      this.modifiedPrompt = prompt;
    },
    useResponse(response) {
      this.answer.answer = response;
      this.hided = this.answer.question_id;
      setTimeout(
        function () {
          this.hided = null;
          axios
            .post(`${process.env.VUE_APP_API_URL}/solution/autosave`, {
              id: this.answer.answer_id,
              solution_id: this.solutionId,
              project_id: this.projectId,
              question_id: this.answer.question_id,
              question_type: this.answer.question_type,
              role: this.role,
              content: response,
            })
            .then((response) => {
              if (response.data.deleted) {
                this.updateDateAnswer(null);
                this.answer.answer_id = null;
                this.$parent.answers[this.answerIndex].answer_id = null;
              } else {
                this.updateDateAnswer(response.data.date);
                this.answer.answer_id = response.data.last_answer;
                this.$parent.answers[this.answerIndex].answer_id =
                  response.data.last_answer;
              }
              this.savingMsg = false;
              this.$emit("reloadPercentage");
              this.$emit("updateSendToCorrect");
              this.$nextTick(() => {
                  this.initFroala();
                }
              )
            });
        }.bind(this),
        100
      );
    },
    stripHtml(e) {
      e.preventDefault();
      const text = window.getSelection().toString();
      e.clipboardData.setData("text/plain", text);
    },
    getDataTime(type, datatime) {
      if (datatime !== null) {
        datatime = datatime.split(" ");
        if (type == 1) {
          const time = datatime[1].split(":");
          datatime[1] = `${time[0]}:${time[1]}`;
        } else {
          const time = datatime[0].split("-");
          datatime[0] = `${time[2]}/${time[1]}/${time[0]}`;
        }
        return datatime[type];
      }
    },
    checkOption(answer, option) {
      try {
        const data = answer.split("[;separator;]");
        for (let i = 0; i < data.length; i++) {
          //remove spaces from data[i] and option
          data[i] = data[i].replace(/\s/g, "");
          option = option.replace(/\s/g, "");

          if (data[i] == option) {
            return true;
          }
        }
        return false;
      } catch (error) {
        return false;
      }
    },

    continueChattingGpt(e, i) {
      e.preventDefault();
      const prompt = this.continueChattingInput[i];
      if (prompt == undefined || prompt == "")
        this.continueChatting.splice(this.continueChatting.indexOf(i), 1);
      else this.continueAskChatGpt(i, prompt);
    },

    continueAskChatGpt(i, prompt) {
      this.gptResponses.splice(this.gptResponses.indexOf(i), 1);
      this.continueChatting.splice(this.continueChatting.indexOf(i), 1);
      this.continueChattingInput[i] = "";
      this.previousUserMessages.push(prompt);
      this.searchingGpt = true;
      let formData = new FormData();
      formData.append("project_id", this.projectId);

      this.filesUploaded.forEach((file, index) => {
        formData.append("files[" + index + "]", file.file);
      });

      axios
        .post(`${process.env.VUE_APP_API_URL}/uploadChatGptFiles`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.filesUploaded = [];

          const type = this.preButtonSelected
            ? this.preButtonSelected
            : this.buttonSelected > 0
              ? this.buttonSelected
              : 1;
          this.askingGpt = true;
          axios
            .post(
              `${process.env.VUE_APP_API_URL}/projects/${this.projectId}/${type}`,
              {
                project_id: this.projectId,
                previousUserMessages: this.previousUserMessages,
                previousBotMessages: this.previousBotMessages,
              }
            )
            .then((res) => {
              const response = res.data.generated_response;
              this.gptResponsesError[i] = false;
              this.gptResponses[i] = response;
              this.previousBotMessages.push(response);
              this.searchingGpt = false;
            })
            .catch(() => {
              this.gptResponsesError[i] = true;
            })
            .finally(() => {
              this.askingGpt = false;
            });
        });
    },

    restartGpt(i, prompt, tryAgain) {
      this.previousUserMessages = [];
      this.previousBotMessages = [];
      this.gptResponses[i] = undefined;
      this.askChatGpt(i, prompt, tryAgain);
    },

    setOldStateGptChat() {
      this.previousUserMessages = this.prevStateUser;
      this.previousBotMessages = this.prevStateBot;
    },
    askChatGpt(i, prompt, tryAgain) {
      if (this.askedGpt.indexOf(i) == -1 || tryAgain) {
        if (!tryAgain) this.askedGpt.push(i);
        this.gptResponsesError[i] = false;
        if (this.gptResponses[i] === undefined) {
          this.previousUserMessages.push(prompt);
          this.searchingGpt = true;

          const type = this.preButtonSelected
            ? this.preButtonSelected
            : this.buttonSelected > 0
              ? this.buttonSelected
              : 1;
          this.askingGpt = true;

          axios
            .post(
              `${process.env.VUE_APP_API_URL}/projects/${this.projectId}/${type}`,
              {
                project_id: this.projectId,
                previousUserMessages: this.previousUserMessages,
                previousBotMessages: this.previousBotMessages,
              }
            )
            .then((res) => {
              const response = res.data.generated_response;
              this.gptResponsesError[i] = false;
              this.gptResponses[i] = response;
              this.previousBotMessages.push(response);
              this.searchingGpt = false;
            })
            .catch(() => {
              this.gptResponsesError[i] = true;
            })
            .finally(() => {
              this.askingGpt = false;
            });
        }
      } else {
        //remove from array
        this.askedGpt.splice(this.askedGpt.indexOf(i), 1);
      }
    },

    uncheckSameOption(event, answer, option, type, index) {
      this.checkboxDisabled = this.answer.answer_id;
      setTimeout(() => {
        if (type == 5) {
          if (!this.changeTriggered) {
            let url = "solution/updateCheckboxAnswer";
            if (this.cdti) url = "cdti/insertInvertibility";
            axios
              .post(`${process.env.VUE_APP_API_URL}/${url}`, {
                answer_id: answer.answer_id,
                option: "",
                solution_id: this.solutionId,
                project_id: this.projectId,
                role: this.role,
                type: type,
                question_id: answer.question_id,
                indexSelected: this.indexSelected,
                time: false,
              })
              .then((response) => {
                this.$parent.answers[this.answerIndex].answer = null;
                this.updateDateAnswer(null);
                this.checkboxDisabled = 0;
              });
          } else {
            this.changeTriggered = false;
          }
        }
      }, 200);
    },

    saveChattingInput(e, i) {
      this.continueChattingInput[i] = e.target.value;
    },

    selectOption(event, answer, option, type, index) {
      try {
        this.changeTriggered = true;
        this.checkboxDisabled = this.answer.answer_id;
        index = index.toString();
        if (!this.isTutorInProject) {
          let solutionAnswer = option;
          //order
          if (type !== 5) {
            if (this.oldQuestion != answer.question_id) {
              this.indexSelected = index;
              this.oldQuestion = answer.question_id;
            } else {
              if (!event.target.checked) {
                // delete the index from the array
                const order = this.indexSelected.split("[;separator;]");
                // remove the index from the array only if it's in the array
                if (order.indexOf(index) != -1) {
                  order.splice(order.indexOf(index), 1);
                }
                this.indexSelected = order.join("[;separator;]");
              } else {
                // split separator in a valiable
                const order = this.indexSelected.split("[;separator;]");
                // check if the index is already selected
                if (order.indexOf(index) != -1) {
                  // remove the index from the array
                  order.splice(order.indexOf(index), 1);
                  // add the index to the end of the array
                  order.push(index);
                  // join the array with the separator
                  this.indexSelected = order.join("[;separator;]");
                } else {
                  // add the index to the end of the array
                  if (this.indexSelected == "") {
                    this.indexSelected = index;
                  } else {
                    this.indexSelected =
                      this.indexSelected + "[;separator;]" + index;
                  }
                }
              }
              // delete the [;separator;] if is blank
              if (this.indexSelected == "[;separator;]") {
                this.indexSelected = "";
              }
            }
          }

          if (
            type !== 5 &&
            this.$parent.answers[this.answerIndex].answer !== null
          ) {
            solutionAnswer = "";
            const data =
              this.$parent.answers[this.answerIndex].answer.split(
                "[;separator;]"
              );
            if (!event.target.checked) {
              data.splice(data.indexOf(option), 1);
              data.forEach((el, i) => {
                solutionAnswer += el;
                if (i != data.length - 1) {
                  solutionAnswer += "[;separator;]";
                }
              });
            } else {
              solutionAnswer = this.$parent.answers[this.answerIndex].answer;
              if (solutionAnswer !== "") {
                solutionAnswer += "[;separator;]";
              }
              solutionAnswer += option;
            }
          } else {
            this.indexSelected = index;
          }
          let url = "solution/updateCheckboxAnswer";
          if (this.cdti) url = "cdti/insertInvertibility";
          axios
            .post(`${process.env.VUE_APP_API_URL}/${url}`, {
              answer_id: answer.answer_id,
              option: solutionAnswer,
              solution_id: this.solutionId,
              project_id: this.projectId,
              role: this.role,
              type: type,
              question_id: answer.question_id,
              indexSelected: this.indexSelected,
              time: true,
            })
            .then((response) => {
              if (response.data.deleted) {
                this.$parent.answers[this.answerIndex].answer = null;
                this.updateDateAnswer(null);
              } else {
                this.$parent.answers[this.answerIndex].answer = solutionAnswer;
                this.$parent.answers[this.answerIndex].answer_id =
                  response.data.last_answer;
                this.updateDateAnswer(response.data.date);
              }
              if (!this.cdti) {
                this.$emit("reloadPercentage");
                this.$emit("updateSendToCorrect");
              }
              setTimeout(() => {
                this.checkboxDisabled = 0;
              }, 300);
            });
        }
      } catch (e) {
        console.log(e);
      }
    },
    updateDateAnswer(request) {
      const dataField = this.isTutorInProject
        ? "feedback_answer_date"
        : "answer_date";
      
      this.answer[dataField] = request;
      this.$emit("editedSolution", request !== null);
      this.answer.interaction_entrepreneur = 0;
      this.answer.interaction_tutor = 0;
    },
    getAnswerFilename(answer) {
      try {
        let filename = answer.split("_m8y0_");
        filename = filename[1].split('"');
        return filename[0];
      } catch (e) {
        return this.langFilter("noFile");
      }
    },
    openFile(answer) {
      const url = answer.split('"');
      window.open(url[1], "_blank");
    },
    uploadSolutionFile(el, question_id, index) {
      const file = el.target.files[0];
      if (file.size > 5000000) {
        this.$emit("errorFileUpload");
      } else {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("project", this.projectId);
        formData.append("solution", this.solutionId);
        formData.append("question", question_id);
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/solution/answerUpload`,
            formData
          )
          .then((response) => {
            this.$parent.answers[index].answer = response.data[0];
            this.answer.answer = response.data[0];
            this.updateDateAnswer(response.data[1]);
            this.$emit("reloadPercentage");
            this.reloadData();
          });
      }
    },
    writeSolution(event, answer_id) {
      if (!this.isTutorInProject) {
        if (this.savingMsg == answer_id) {
          clearTimeout(this.saving);
        }
        this.savingMsg = answer_id;
        this.saving = setTimeout(
          function () {
            axios
              .post(`${process.env.VUE_APP_API_URL}/solution/autosave`, {
                id: this.answer.answer_id,
                solution_id: this.solutionId,
                project_id: this.projectId,
                question_id: this.answer.question_id,
                question_type: this.answer.question_type,
                role: this.role,
                content: event.target.value,
              })
              .then((response) => {
                if (response.data.deleted) {
                  this.updateDateAnswer(null);
                  this.answer.answer_id = null;
                  this.$parent.answers[this.answerIndex].answer_id = null;
                } else {
                  this.updateDateAnswer(response.data.date);
                  this.answer.answer_id = response.data.last_answer;
                  this.$parent.answers[this.answerIndex].answer_id =
                    response.data.last_answer;
                }
                this.savingMsg = false;
                this.$emit("reloadPercentage");
                this.$emit("updateSendToCorrect");
              });
          }.bind(this),
          2500
        );
      }
    },
    compareDates(answerDate) {
      answerDate = this.getDataTime(0, answerDate);
      if (answerDate != this.currentDate) {
        return this.langFilter("on") + " " + answerDate;
      }
    },
    reloadData() {
      this.savingMsg = false;
      this.$emit("reloadPercentage");
      this.$emit("updateSendToCorrect");
    },
    handleChatBoxAction() {
      if (!this.solutionHasExpert) {
        this.handleAssistantModal();
        return;
      }

      this.askedGptIncludesAnswer
        ? this.askedGpt.splice(this.askedGpt.indexOf(this.answerIndex), 1)
        : this.askedGpt.push(this.answerIndex);
    },
    handleAssistantModal() {
      this.isVisibleAssistantModal = !this.isVisibleAssistantModal;
    },
    goToConfig() {
      this.$router.replace({
        name: "ConfigProject",
        params: { project_id: this.projectId },
      });
    },
    async getProjectExpert() {
      try {
        const expertType = 1;
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/projects/${this.projectId}/${expertType}`
        );

        this.messages = res.data.messages;
      } catch (error) {
        console.error("Error getting data", error);
        alert("Error obteniendo el chat del Asistente");
      }
    },
    async handleFroalaAction(action, message, query) {
      if (!message) {
        this.showAlert({
          title: "error",
          text: "selectChatGPT",
        });
        return;
      }

      try {
        this.loadingData = true;

        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/froala/${action}`,
          {
            message,
            query: query && this.langFilter(query),
          }
        );

        return response.data.response;
      } catch (error) {
        this.showAlert({
          title: "error",
          text: "errorChatGPT",
        });

        throw new Error("Error executing froala action:", { cause: error });
      } finally {
        this.loadingData = false;
      }
    },
  },
  created() {
    const date = new Date();
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    this.currentDate = dd + "/" + mm + "/" + yyyy;
    this.indexSelected = this.answer.answer_order;
    //this.checkEditPrompt();
  },
  async mounted() {
    setTimeout(
      function () {
        this.initFroala();
      }.bind(this),
      1000
    );
    await this.getProjectExpert();
  },
  mixins: [translationMixin, generalMethodsMixin],
};
</script>

<style lang="scss">
@import "../../../node_modules/froala-editor/css/froala_editor.pkgd.min.css";
@import "../../../node_modules/froala-editor/css/plugins/char_counter.min.css";
@import "../../../node_modules/froala-editor/css/plugins/colors.min.css";
@import "../../../node_modules/froala-editor/css/plugins/draggable.min.css";
// @import "../../../node_modules/froala-editor/css/plugins/font_family.min.css";
// @import "../../../node_modules/froala-editor/css/plugins/font_size.min.css";
// @import "../../../node_modules/froala-editor/css/plugins/inline_style.min.css";
@import "../../../node_modules/froala-editor/css/plugins/line_breaker.min.css";
// @import "../../../node_modules/froala-editor/css/plugins/lists.min.css";
// @import "../../../node_modules/froala-editor/css/plugins/paragraph_format.min.css";
@import "../../../node_modules/froala-editor/css/plugins/quick_insert.min.css";
// @import "../../../node_modules/froala-editor/css/plugins/save.min.css";
@import "../../../node_modules/froala-editor/css/plugins/table.min.css";
// @import "../../../node_modules/froala-editor/css/plugins/track_changes.min.css";
// @import "../../../node_modules/froala-editor/css/plugins/word_paste.min.css";
</style>
