<template>
  <div class="relative">
    <header-general
      :title="langFilter('title_program_screen')"
      :src="require('@/assets/img/sidebar/hovers/icon-programs.svg')"
    />
    <div id="programs-main-container" class="myProjects-MainContainer">
      <div class="myProjects-SecondTitleContainer">
        <div class="myProjects-list-programs-dropdown">
          <div
            id="myPrograms-filter"
            class="myProjects-FilterButtonIcon"
            :class="{
              active: filtered
            }"
            @click="openPopUp"
          >
            <img
              v-show="!filtered"
              class="myProjects-FilterIcon"
              :src="require('@/assets/img/projects/filter.svg')"
              alt="icon"
            />
            <img
                v-show="filtered"
                class="myProjects-FilterIcon"
                :src="require('@/assets/img/projects/filter_clicked.svg')"
                alt="icon"
              />
          </div>

          <div
            id="container-of-years-filter"
            class="myProjects-list-programs-dropdown-content"
          >
            <input
              v-model="searchProgram"
              name="search-programs"
              class="myProjects-list-programs-title"
              :placeholder="langFilter('Search_year')"
            />
            <ul>
              <li
                v-for="(program, programsIndex2) in filteredProgramsList"
                :key="programsIndex2"
              >
                <label
                  ><span>{{ program.name }}</span
                  ><input
                    v-model="selectedProgramsList"
                    :value="program"
                    type="checkbox"
                    name="programChecked"
                /></label>
              </li>
            </ul>
          </div>
          <div
            class="myProjects-list-programs-dropdown-content-container"
            @click="clickOutsideDiv($event)"
          ></div>
        </div>
        <div
          class="myProjects-NewProjectButtonIcon"
          :class="{'myProjects-NewProjectButtonIcon-disabled': isDisabled()}"
          data-bs-toggle="modal"
          data-bs-target="#newProjectModal"
          :title="isDisabled() ? langFilter('createProgramWithoutPermission') : langFilter('createProgram')"
          @click="hideBackdrop()"
        >
          <img
            class="myProjects-NewProjectIcon"
            :src="require('@/assets/img/projects/new_project.svg')"
            alt="new program"
          />
        </div>
        <div id="searchbar-templates" class="tool-search-templates" :class="searchBarClass">
          <img
            class="tool-image-search-templates"
            :src="require('@/assets/img/projects/search-btn.svg')"
            alt="search"
            @click="focusSearchBar"
          />
          <input
            id="template-search"
            ref="templateSearch"
            v-model="filters.name"
            class="tool-search-templates-input"
            :placeholder="langFilter('search-program')"
            name="searchbar-templates"
            type="text"
            @focus="search_bar(true)"
            @blur="search_bar(false)"
            @input="filterProgramsSearchBar"
          />
        </div>
      </div>
      <div
        v-if="paginatedSelected.length !== 0"
        id="container-cards-programs"
        class="myProjects-ProjectCardsContainer"
      >
        <div
          v-for="(program, programsIndex) in filteredPaginatedPrograms"
          :id="'years-card-' + programsIndex"
          :key="programsIndex"
          :style="
            selectedProgramsListValidation.length == programsIndex + 1
              ? { 'border-bottom': '0px' }
              : { '': '' }
          "
        >
          <div
            v-if="checkProjectByYear(program.name)"
            class="myProjects-ProgramContainer"
          >
            <div
              :id="'year-name-' + programsIndex"
              class="myProjects-ProgramLabel"
            >
              <p>{{ program.name }}</p>
            </div>
            <div
              v-for="(myProject, myProjectsIndex) in filterPrograms"
              :key="myProjectsIndex"
            >
              <router-link :to="`/my-projects?project=${myProject.id}`">
                <div
                  v-if="program.name == myProject.year"
                  :id="'program-card-' + myProjectsIndex"
                  class="myProjects-ProjectCard"
                  @click="changeView(myProject.id)"
                >
                  <img
                    class="myProjects-ProjectCardLogo"
                    :src="require('@/assets/img/flow/Flow_roadmap_Icon-20.png')"
                    alt="project"
                  />

                  <p
                    :id="'program-title-' + myProjectsIndex"
                    class="myProjects-ProjectCardTitle"
                  >
                    {{ myProject.name }}
                  </p>

                  <div class="myProjects-ProjectCardSeparator"></div>

                  <ul
                    :id="'program-info-' + myProjectsIndex"
                    class="myProjects-ProjectCardInfo"
                  >
                    <li>
                      {{ langFilter("proyectos") }}:
                      <span>
                        {{ getProjectCount(myProject.id) }}
                      </span>
                    </li>
                    <li v-if="getProjectCount(myProject.id) != 0">
                      {{ langFilter("usuarios") }}:
                      {{ usersCount[myProject.id] }}
                    </li>
                    <li>
                      {{ langFilter("duration") }}:
                      {{ monthDiff(myProject.final, myProject.inicio) }}
                      <span
                        v-if="monthDiff(myProject.final, myProject.inicio) == 1"
                        >{{ langFilter("mes") }}</span
                      >
                      <span v-else>{{ langFilter("meses") }}</span>
                    </li>
                  </ul>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!loading" class="no-programs-container">
        {{ langFilter("noProg") }}.
      </div>
      <div v-else>
        <img
          class="loading-rocket"
          :src="require('@/assets/img/general/loading.gif')"
          alt="loading"
        />
      </div>
      <div class="hide">
        <pagination
          :countLength="selectedProgramsListValidation.length"
          @update:option="paginated"
        />
      </div>
    </div>
    <newprogram
      v-if="create == 1"
      :lang="lang"
      :programs="myProjects"
      @open-alert="openAlert"
    />

    <alertMessage ref="alerts" :lang="lang"></alertMessage>
  </div>
</template>
<script>
/* eslint-disable */
import translationMixin from "../../mixins/translationMixin.js";
import pagination from "../PaginationComponent.vue";
import $ from "jquery";
import { Modal } from "bootstrap";
import axios from "axios";
import { PAGINATE_LIMIT } from "../projects/shared/constants.js";

export default {
  components: { pagination },
  inject: ["showAlertToast"],

  props: ["create", "lang", "space"],

  data() {
    return {
      newProgramModal: false,
      myProjects: [],
      programsList: [],
      selectedProgramsList: [],
      projectsCount: [],
      usersCount: [],
      searchProgram: "",
      paginatedNum: 0,
      paginatedSelected: [],
      myProjectsPagination: [],
      loading: true,
      errors: [],
      succesful: false,
      filtered: false,
      filters: {
        name: "",
        programs: [],
        users: [],
      },
      searchBarExpanded: false,
      // traduccion
      traducciones: [
        {
          name: "title_program_screen",
          es: "Programas",
          en: "Programs",
        },
        {
          name: "My projects",
          es: "Mis proyectos",
          en: "My projects",
        },
        {
          name: "Filter",
          es: "Filtro",
          en: "Filter",
        },
        {
          name: "Search program",
          es: "Buscar programa...",
          en: "Search program...",
        },
        {
          name: "Search_year",
          es: "Buscar año...",
          en: "Search year...",
        },
        {
          name: "New project",
          es: "Nuevo proyecto",
          en: "New project",
        },
        {
          name: "newProg",
          es: "Nuevo programa",
          en: "New program",
        },
        {
          name: "proyectos",
          es: "Proyectos",
          en: "Projects",
        },
        {
          name: "usuarios",
          es: "Usuarios",
          en: "Users",
        },
        {
          name: "duration",
          es: "Duración",
          en: "Duration",
        },
        {
          name: "Pending modifications",
          es: "Pendientes de modificación",
          en: "Pending modifications",
        },
        {
          name: "Completed",
          es: "Completadas",
          en: "Completed",
        },
        {
          name: "Resources",
          es: "Recursos",
          en: "Resources",
        },
        {
          name: "meses",
          es: "Meses",
          en: "Months",
        },
        {
          name: "mes",
          es: "Mes",
          en: "Month",
        },
        {
          name: "noProg",
          es: "No tienes programas asociados",
          en: "You have no associated programs",
        },
        {
          name: "noProj",
          es: "No tienes proyectos en este programa",
          en: "You have no projects in this program",
        },
        {
          name: "search-program",
          es: "Busca un programa",
          en: "Search a program",
        },
        {
          name: "createProgramWithoutPermission",
          es: "No tienes permisos para crear un programa.",
          en: "You do not have permissions to create a program.",
        },
        {
          name: "createProgram",
          es: "Crear un programa.",
          en: "Create a program.",
        },
      ],
    };
  },
  created() {
    this.getAllPrograms();
    this.paginatedSelected = this.myProjects;
  },
  computed: {
    selectedProgramsListValidation() {
      if (this.selectedProgramsList.length > 0) {
        this.paginatedSelected = this.selectedProgramsList.slice(0, PAGINATE_LIMIT);
        this.myProjectsPagination = this.paginatedSelected;
        return this.selectedProgramsList;
      } else {
        this.paginatedSelected = this.programsList.slice(0, PAGINATE_LIMIT);
        this.myProjectsPagination = this.paginatedSelected;
        return this.programsList;
      }
    },
    filteredProgramsList() {
      return this.programsList.filter((post) => {
        var validation = this.myProjects.find((obj) => {
          if (obj.program_id == post.id) {
            return true;
          }
        });
        var validation2 = post.name
          .toString()
          .includes(this.searchProgram.toString());
        if (validation && validation2) {
          return true;
        }
      });
    },
    filterPrograms() {
      return this.myProjects.filter(project =>
        project.name.toLowerCase().includes(this.filters.name.toLowerCase())
      );
    },
    filteredPaginatedPrograms() {
      return this.paginatedSelected.filter(program =>
        this.filterPrograms.some(myProject => myProject.year === program.name)
      );
    },
    searchBarClass() {
      return {
        'search-big-templates': this.searchBarExpanded,
      };
    },
  },
  methods: {
    search_bar(show) {
      this.searchBarExpanded = show;
    },
    focusSearchBar() {
      this.$refs.templateSearch.focus();
    },
    hideBackdrop() {
      if ($(".modal-backdrop").length > 1) {
        $(".modal-backdrop").not(":first").remove();
      }
    },
    openModal() {
      this.newProgramModal = true;
      $("html").css("overflow", "hidden");
    },
    changeView(project) {
      this.$emit("view", 2);
      this.$emit("project", project);
    },
    checkProjectByYear(year) {
      const validation = this.myProjects.find((obj) => {
        if (obj.year == year) {
          return true;
        }
      });
      if (validation) {
        return true;
      } else {
        return false;
      }
    },
    openPopUp(el) {
      if ($(el.currentTarget).next().is(":visible")) {
        $(el.currentTarget).next().hide();
        $(el.currentTarget).next().next().hide();
        this.filtered = false;
      } else {
        $(el.currentTarget).next().next().show();
        $(el.currentTarget).next().show();
        this.filtered = true;
      }
    },
    clickOutsideDiv(el) {
      $(el.currentTarget).hide();
      $(el.currentTarget).prev().hide();
      this.filtered = false;
    },
    goToItinerary(project) {
      window.location.href = "itinerary/" + project;
    },
    monthDiff(d1, d2) {
      var months;
      d1 = d1.split("-");
      d2 = d2.split("-");
      months = (parseInt(d1[0]) - d2[0]) * 12;
      months -= parseInt(d2[1]);
      months += parseInt(d1[1]);
      return months <= 0 ? 0 : months;
    },
    getAllPrograms() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/programs/getPrograms`, {
          id: this.user,
        })
        .then((response) => {
          this.myProjects = response.data;
          this.paginatedSelected = this.myProjects;
          let projects = this.myProjects.map((a) => a.id);
          if (response.data.length > 0) {
            axios
              .post(
                `${process.env.VUE_APP_API_URL}/programs/getProjectsByPrograms`,
                {
                  id: this.user,
                  projects: projects,
                }
              )
              .then((response) => {
                this.projectsCount = response.data.programs;
                this.usersCount = response.data.users;
                this.loading = false;
              });
          } else {
            this.loading = false;
          }
        });

      axios
        .post(`${process.env.VUE_APP_API_URL}/programs/getYears`, {
          id: this.user,
        })
        .then((response) => {
          this.programsList = response.data;
        });
    },

    getProjectCount(id) {
      let projects = this.projectsCount.find((x) => x.program_id === id);
      if (projects) {
        return projects.projects;
      } else {
        return 0;
      }
    },

    paginated: function (value) {
      this.paginatedNum = value;
      if (value == 0) {
        this.paginatedSelected = this.myProjectsPagination.slice(0, PAGINATE_LIMIT);
      } else {
        this.paginatedSelected = this.myProjectsPagination.slice(
          value * PAGINATE_LIMIT,
          (value + 1) * PAGINATE_LIMIT
        );
      }
    },
    openAlert() {
      this.$refs.alerts.succesful = true;
      this.$refs.alerts.title = "";
      this.$refs.alerts.text = "programCreated";
      if (this.errors.length == 0) {
        this.errors.push("errors");
        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
          this.$refs.alerts.succesful = false;
        }, 12000);
      }
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);

      this.errors = [];
    },
    filterProgramsSearchBar() {
      this.paginatedSelected = this.filterPrograms.slice(0, PAGINATE_LIMIT);
    },
    isDisabled() {
      return this.space?.is_trial && this.space?.is_trial_active;
    }
  },
  mixins: [translationMixin],
};
</script>

<style lang="css" scoped>
  .myProjects-NewProjectButtonIcon-disabled {
    opacity: 0.5;
  }
  .myProjects-NewProjectButtonIcon-disabled:active {
    pointer-events: none;
  }
  .myProjects-NewProjectButtonIcon-disabled:hover {
    cursor: not-allowed;
  }
</style>