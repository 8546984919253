<template>
  <div>
    <tongue :lang="lang" :space="space" @reload-home="reloadHome" />
    <div class="sidebar" :style="closed ? 'width:73px' : 'width:240px'">
      <div class="top-of-sidebar">
        <router-link to="/home">
          <img
            v-show="!closed"
            :src="require('@/assets/img/sidebar/acc_logo.png')"
            class="acceleralia-sidebar-logo pointer"
            alt="logo"
          />
          <img
            v-show="closed"
            :src="require('@/assets/img/sidebar/logo_acceleralia_mini.svg')"
            class="acceleralia-sidebar-logo-mini pointer"
            alt="logo mini"
          />
        </router-link>
        <div class="collapse-sidebar-button" @click="closeSidebar()">
          <img
            :src="require('@/assets/img/sidebar/sidebar_collapse_arrow.svg')"
            :style="closed ? 'rotate: 0deg;' : 'rotate: 180deg;'"
            alt="collapse"
          />
        </div>
      </div>
      <sidebar-left
        :spaces="space"
        :spaceSelecteds="spaceSelected"
        :page="page"
        :lang="lang"
        class="left-of-sidebar"
        @space="changeSpace($event)"
      />
      <sidebar-right
        class="right-of-sidebar"
        :space="spaceSelected"
        :lang="lang"
        :page="page"
        :role="role"
      />
    </div>
    <div class="project-name-container">
      <p id="project-name" class="project-name"></p>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import { ROLES_IN_SPACE } from "@/constants/index.js";
import router from "@/router";

const { ADMIN } = ROLES_IN_SPACE;
export default {
  inject: ["spaceConfig", "showAlertToast"],
  props: {
    space: {
      type: Array,
      required: true,
    },
    role: {
      type: Number,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  emits: ["reloadHomeNotifications", "changeSpace"],
  data() {
    return {
      spaceSelected: [],
      closed: false,
      page: 0,
      options: [
        {
          name: "Home",
          img: "icon-home",
          url: "home",
          section_number: "0",
        },
        {
          name: "Programs",
          img: "icon-programs",
          url: "programs",
          section_number: "1",
        },
        {
          name: "Projects",
          img: "icon–projects",
          url: "my-projects",
          section_number: "2",
        },
        {
          name: "Itinerary",
          img: "icon-itinerary",
          url: "tracks",
          section_number: "3",
        },
        {
          name: "Forum",
          img: "icon-forum",
          url: "forum",
          section_number: "4",
        },
      ],
      optionsAdmin: [
        {
          name: "Admin Panel",
          img: "icon-gear",
          url: "adminpanel",
          section_number: 5,
        },
        {
          name: "Assistants",
          img: "icon-robot-wink",
          url: "assistants",
          section_number: 7,
        },
      ],
    };
  },
  watch: {
    $route(to) {
      this.checkRoute(to.name);
    },
  },
  created() {
    this.checkRoute(this.$route.name);
    this.spaceSelected = this.space[0];
    const sidebar_closed = localStorage.getItem("sidebar_closed");
    if (sidebar_closed == "true") {
      this.closed = true;
    }

    this.checkTrialPeriodExpired();

    if (!this.spaceConfig.SPACE_CURRENT.is_trial) {
      this.checkAccessToManageSpaces();
    }
  },
  mounted() {
    if (this.closed) {
      $(".div-general-menu-lateral").addClass("container-close-sidebar");
    }
  },
  updated() {
    this.checkTrialPeriodExpired();
  },
  methods: {
    checkRoute(route) {
      const routeMap = {
        Home: 0,
        Programs: 1,
        MyProjects: 2,
        NewProject: 2,
        Templates: 3,
        NewTemplate: 3,
        Forum: 4,
        AdminPanel: 5,
        ManageSpace: 6,
        Assistants: 7,
        NewAssistant: 7,
        EditAssistant: 7,
        AssistantDetails: 7,
        Faqs: 50,
      };
      this.page = routeMap[route] !== undefined ? routeMap[route] : 100;
    },
    reloadHome() {
      this.$emit("reloadHomeNotifications");
    },
    checkTrialPeriodExpired() {
      if(this.$route.name == "Versions") {
        router.push("/version");
      } else if (this.spaceConfig.isTrialFinished) {
        router.push("/trial-period");
      }
    },
    async checkAccessToManageSpaces() {
     const url = `${process.env.VUE_APP_API_URL}/manageSpaces/access`;

      if (this.role === ADMIN) {
        try {
          const res = await axios.get(url);
          if (res.data.access) {
            this.optionsAdmin.splice(1, 0, {
              name: "spaces",
              img: "icon-gear",
              url: "manageSpaces",
              section_number: 6,
            });
          }
        } catch (error) {
          console.error("Error fetching access to manage spaces:", error);
          this.showAlertToast({
            title: "",
            message: "Error fetching access to manage spaces",
            variant: "error",
          });
        }
      }
    },
    closeSidebar() {
      this.closed = !this.closed;
      localStorage.setItem("sidebar_closed", this.closed);
      if (this.closed) {
        $(".div-general-menu-lateral").addClass("container-close-sidebar");
      } else {
        $(".div-general-menu-lateral").removeClass("container-close-sidebar");
      }
    },
    changeSpace() {
      this.page = 0;
      this.$emit("changeSpace", this.space);
      this.spaceConfig.setSpaceCurrent(this.spaceSelected);
    },
  },
};
</script>
