// generar componente toast con vuesjs option api
<template>
    <div v-if="show" class="alerts-toast-container">
        <div class="alert-close" @click="closeAlert()">×</div>
        <div class="alert-margin" :class="variant + '-container'">
            <p v-if="title.length > 0" class="alert-title">{{ title }}</p>
            <p v-if="message.length > 0">{{ message }}</p>
            <div :class="variant +'-timebar'" :style="alertTime"></div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
        show: false,
        title: "",
        message: "",
        variant: 'error' | 'success',
        autoHideDelay: 12000,
        alertTime: {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        },
    };
  },
  methods: {
    openAlert({ title, message, variant }) {
        this.title = title;
        this.message = message;
        this.variant = variant;
        this.show = true;

        setTimeout(() => {
            this.onHidden();
        }, this.autoHideDelay);
    },
    onHidden() {
      this.show = false;
    },
    closeAlert() {
        this.onHidden();
    }
  },
};
</script>
<style scoped>
    .alerts-toast-container {
        position: fixed;
        right: 7px;
        top: 120px;
        z-index: 9999;
    }

    .alert-close {
        position: absolute;
        color: white;
        top: 2px;
        right: 6px;
        width: 13px;
        cursor: pointer;
        z-index: 99999;
    }

    .alert-title {
        font-size: 14px;
        font-weight: bold;
    }

    .error-container {
        position: fixed;
        width: 276px;
        min-height: 74px;
        right: 7px;
        top: 120px;
        background: #e77988;
        border: 2px solid #f3bcc3;
        border-radius: 10px;
        color: #f8e3e7;
        font-size: 12px;
        padding: 13px 25px 13px 21px;
        overflow: hidden;
        z-index: 10;
    }

    .success-container {
        position: fixed;
        width: 276px;
        min-height: 74px;
        right: 7px;
        top: 120px;
        background-color: #6ab99d;
        border: 2px solid #ccf2e0;
        border-radius: 10px;
        color: #f8e3e7;
        font-size: 12px;
        padding: 13px 25px 13px 21px;
        overflow: hidden;
        z-index: 10;
    }

    .error-timebar {
        background: #b84a59;
        height: 4px;
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: 0px;
        transform-origin: 0 100%;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }

    .success-timebar {
        background: #79e788;
        height: 4px;
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: 0px;
        transform-origin: 0 100%;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }
</style>
