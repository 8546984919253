<template>
  <div>
    <div class="sidebar-corporation-name-container active-up">
      <h4
        v-if="space != undefined"
        id="sidebar-ap-arrow"
        class="sidebar-corporation-name"
      >
        {{ space.name }}
      </h4>
    </div>

    <!-- Menú Collaborative Projects -->
    <div v-if="isCollaborativeSpace">
      <div 
        v-for="(menuItem, index) in getCollaborativeMenu" 
        :key="index" 
        :index="index"
        :menuItem="menuItem"
        :optionSelected="getOptionSelected(index)"
        :class="[
          {
            'sidebar-background-selected': getOptionSelected(index),
          },
          'sidebar-container-options',
        ]"
      >
        <div
          class="sidebar-option-content" 
          @mouseover="showSubmenu(index)" 
          @mouseleave="hideSubmenu()"
        >
          <img
            v-show="getOptionSelected(index)"
            :src="require(`@/assets/img/sidebar/hovers/${menuItem.img}.svg`)"
            class="sidebar-option-image"
          />
          <img
            v-show="!getOptionSelected(index)"
            :src="require(`@/assets/img/sidebar/logos/${menuItem.img}.svg`)"
            class="sidebar-option-image"
          />
          <template v-if="getOptionSelected(index)">
            <a class="sidebar-option-text sidebar-option-selected">{{ langFilter(menuItem.title) }}</a>
          </template>
          <template v-else>
            <p class="sidebar-option-text">{{ langFilter(menuItem.title) }}</p>
          </template>
          <div v-show="activeSubmenu === index" class="submenu">
            <a v-for="(item, itemIndex) in menuItem.items" 
              :key="itemIndex"
              :href="item.url"
              :target="item.url?.startsWith('http') ? '_blank' : ''"
              class="submenu-item"
            >
            {{ langFilter(item.name) }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Menú Original -->
    <div v-else>
      <sidebar-option
        v-for="(option, optionIndex) in getFilterOptions"
        :key="optionIndex"
        :option="option"
        :optionIndex="optionIndex"
        :lang="lang"
        :optionSelected="getOptionSelected(optionIndex)"
        :class="[
          {
            'sidebar-background-selected': getOptionSelected(optionIndex),
          },
          'sidebar-container-options',
        ]"
      />

      <!-- Admin Panel Options (Index (section_number) start in 5) -->
      <div v-if="role == ADMIN">
        <sidebar-option
          v-for="option in $parent.optionsAdmin"
          :key="option.section_number"
          :option="option"
          :optionIndex="option.section_number"
          :lang="lang"
          :optionSelected="getOptionSelected(option.section_number)"
          :class="[
            {
              'sidebar-background-selected': getOptionSelected(
                option.section_number
              ),
            },
            'sidebar-container-options',
          ]"
        />
      </div>
    </div>

    <div v-show="!$parent.closed" class="sidebar-footer">
      <div class="sidebar-background-container">
        <div
          :class="{
            'sidebar-background-selected': 50 == page,
          }"
          class="sidebar-container-options"
          @mouseover="sidebarHover(50)"
          @mouseleave="leaveSidebarHover(50)"
        >
          <div v-show="50 == page" class="sidebar-option-content">
            <router-link to="/faqs">
              <img
                :src="require('@/assets/img/sidebar/hovers/icon-help.svg')"
                class="sidebar-option-image"
                alt="help"
              />
              <p class="sidebar-option-text sidebar-option-selected">
                {{ langFilter("help") }}
              </p>
            </router-link>
          </div>
          <div v-show="50 !== page" class="sidebar-option-content">
            <router-link
              to="/faqs"
              :class="{'sidebar-option-content-disabled': isDisabled()}"
              :title="isDisabled() ? langFilter('OptionDisabled') : langFilter('help')"
            >
              <img
                :src="require('@/assets/img/sidebar/logos/icon-help.svg')"
                class="sidebar-option-image"
                alt="help"
              />
              <p class="sidebar-option-text">
                {{ langFilter("help") }}
              </p>
            </router-link>
            <div
              id="sidebar-mouse-over-5"
              style="right: -5px"
              class="sidebar-mouse-over"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import { ROLES_IN_SPACE } from "@/constants/index.js";

import $ from "jquery";
export default {
  mixins: [translationMixin],
  inject: ['featuresPlugin', 'spaceConfig'],
  props: {
    space: {
      type: Object,
      required: true,
    },
    role: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    old: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      activeSubmenu: null,
      showAdminPanel: false,
      collaborativeMenu: {
        menu: [
          {
            "title": "Home",
            "img": "icon-home",
            "items": [
              {
                "name": "Community Hub",
                "url": "/forum"
              }
            ]
          },
          {
            "title": "Find",
            "img": "icon-find",
            "items": [
              {
                "name": "Complete Search",
                "url": "https://docs.google.com/document/d/1PfxZCTLWerwvy-6mP4miZI9RNwEry5QVLEuMNK2hZtM/edit?tab=t.0#heading=h.3bhdpzu13p6q"
              },
              {
                "name": "Grants Weekly",
                "url": "https://drive.google.com/open?id=12PaTEMOCbd_u2gpHxEkCrhaGtby_H8WR&usp=drive_copy"
              }
            ]
          },
          {
            "title": "Create",
            "img": "icon–projects",
            "items": [
              {
                "name": "Idea Generator",
                "url": "https://chatgpt.com/share/e/67c04d5b-0c20-8012-a2ef-f7d3f7f52be1"
              },
              {
                "name": "Smart Suggestions",
                "url": "https://chatgpt.com/share/e/67c04d5b-0c20-8012-a2ef-f7d3f7f52be1"
              },
              {
                "name": "Team Builder",
                "url": "https://chatgpt.com/share/e/67bef358-523c-8012-936b-f5d50bacfa22"
              },
              {
                "name": "Full Draft",
                "url": "https://chatgpt.com/share/e/67c04d5b-0c20-8012-a2ef-f7d3f7f52be1"
              },
              {
                "name": "Project Launch",
                "url": null
              }
            ]
          },
          {
            "title": "Match",
            "img": "icon-itinerary",
            "items": [
              {
                "name": "Partner Finder - Online event",
                "url": "https://eu2.make.com/363619/scenarios/4024993/edit"
              },
              {
                "name": "Partner Finder - Online networking",
                "url": null
              },
              {
                "name": "Contact Finder",
                "url": null
              },
              {
                "name": "Smart Emails",
                "url": null
              },
              {
                "name": "Project Sales",
                "url": "https://app.lemlist.com/sequence/cam_QhPsiRBXq5wHo324C/seq_uwe67YxzvnpBsdQRz"
              },
              {
                "name": "Meeting Manager",
                "url": null
              }
            ]
          },
          {
            "title": "Write",
            "img": "icon-write",
            "items": [
              {
                "name": "Grant Programs",
                "url": "/programs"
              },
              {
                "name": "Smart Templates",
                "url": "/templates"
              },
              {
                "name": "Project Writing",
                "url": "/my-projects"
              },
              {
                "name": "Admin Panel",
                "url": "/adminpanel"
              },
              {
                "name": "AI Writers",
                "url": "/assistants"
              },
              {
                "name": "Community Hub",
                "url": "/forum"
              },
              {
                "name": "Task Manager",
                "url": "https://app.clickup.com/3099002/v/li/901200948232"
              }
            ]
          },
          {
            "title": "Evaluate",
            "img": "icon-robot-wink",
            "items": [
              {
                "name": "Grant Review",
                "url": null
              },
              {
                "name": "AI Evaluators",
                "url": "/assistants"
              },
              {
                "name": "Optimize Submission",
                "url": null
              },
              {
                "name": "Iterate & Improve",
                "url": null
              },
              {
                "name": "AI Application Agent",
                "url": null
              }
            ]
          },
          {
            "title": "Manage",
            "img": "icon-manage",
            "items": [
              {
                "name": "Funding Dashboard",
                "url": "https://docs.google.com/spreadsheets/d/1k-VployXp-yPglj1UGMkram_sCwVw_AtK0USP9a6UUU/edit?gid=1601420962#gid=1601420962"
              },
              {
                "name": "Grant Analytics",
                "url": "https://drive.google.com/open?id=14o2mHYUHLn1RQgHIpzsvWriYXlJWioxbHLLzwrmnLIE&usp=drive_copy"
              },
              {
                "name": "Predictive funding planning",
                "url": null
              },
              {
                "name": "Help Center",
                "url": "/faqs"
              }
            ]
          }
        ]
      },
      traducciones: [
        {
          name: "help",
          es: "¿Ayuda?",
          en: "Need help?",
        },
        {
          name: "OptionDisabled",
          es: "Deshabilitado",
          en: "Disabled",
        },
        {
          name: "Find",
          es: "Buscar",
          en: "Find"
        },
        {
          name: "Create",
          es: "Crear",
          en: "Create"
        },
        {
          name: "Match",
          es: "Emparejar",
          en: "Match"
        },
        {
          name: "Write",
          es: "Escribir",
          en: "Write"
        },
        {
          name: "Evaluate",
          es: "Evaluar",
          en: "Evaluate"
        },
        {
          name: "Manage",
          es: "Gestionar",
          en: "Manage"
        },
        {
          name: "Community Hub",
          es: "Comunidad",
          en: "Community Hub"
        },
        {
          name: "Complete Search",
          es: "Búsqueda completa",
          en: "Complete Search"
        },
        {
          name: "Grants Weekly",
          es: "Subvenciones Semanales",
          en: "Grants Weekly"
        },
        {
          name: "Idea Generator",
          es: "Generador de Ideas",
          en: "Idea Generator"
        },
        {
          name: "Smart Suggestions",
          es: "Sugerencias Inteligentes",
          en: "Smart Suggestions"
        },
        {
          name: "Team Builder",
          es: "Constructor de Equipos",
          en: "Team Builder"
        },
        {
          name: "Full Draft",
          es: "Borrador Completo",
          en: "Full Draft"
        },
        {
          name: "Project Launch",
          es: "Lanzamiento de Proyecto",
          en: "Project Launch"
        },
        {
          name: "Partner Finder - Online event",
          es: "Buscador de Socios - Eventos Online",
          en: "Partner Finder - Online event"
        },
        {
          name: "Partner Finder - Online networking",
          es: "Buscador de Socios - Networking Online",
          en: "Partner Finder - Online networking"
        },
        {
          name: "Contact Finder",
          es: "Buscador de Contactos",
          en: "Contact Finder"
        },
        {
          name: "Smart Emails",
          es: "Emails Inteligentes",
          en: "Smart Emails"
        },
        {
          name: "Project Sales",
          es: "Venta de Proyectos",
          en: "Project Sales"
        },
        {
          name: "Meeting Manager",
          es: "Gestor de Reuniones",
          en: "Meeting Manager"
        },
        {
          name: "Grant Programs",
          es: "Programas",
          en: "Grant Programs"
        },
        {
          name: "Smart Templates",
          es: "Plantillas Inteligentes",
          en: "Smart Templates"
        },
        {
          name: "Project Writing",
          es: "Redacción de Proyecto",
          en: "Project Writing"
        },
        {
          name: "Admin Panel",
          es: "Panel de Administración",
          en: "Admin Panel"
        },
        {
          name: "AI Writers",
          es: "Asistentes redactores",
          en: "AI Writers"
        },
        {
          name: "Task Manager",
          es: "Gestor de Tareas",
          en: "Task Manager"
        },
        {
          name: "Grant Review",
          es: "Revisión de Subvenciones",
          en: "Grant Review"
        },
        {
          name: "AI Evaluators",
          es: "Evaluadores AI",
          en: "AI Evaluators"
        },
        {
          name: "Optimize Submission",
          es: "Optimización de Presentaciones",
          en: "Optimize Submission"
        },
        {
          name: "Iterate & Improve",
          es: "Iterar y Mejorar",
          en: "Iterate & Improve"
        },
        {
          name: "AI Application Agent",
          es: "Agente AI para Aplicaciones",
          en: "AI Application Agent"
        },
        {
          name: "Funding Dashboard",
          es: "Panel de Subvenciones",
          en: "Funding Dashboard"
        },
        {
          name: "Grant Analytics",
          es: "Análisis de Subvenciones",
          en: "Grant Analytics"
        },
        {
          name: "Predictive funding planning",
          es: "Planificación predictiva de ayudas",
          en: "Predictive funding planning"
        },
        {
          name: "Help Center",
          es: "Centro de Ayuda",
          en: "Help Center"
        },
      ],
      ADMIN: ROLES_IN_SPACE.ADMIN,
    };
  },
  computed: {
    isCollaborativeSpace() {
      return this.space?.name === "Collaborative Projects";
    },
    getCollaborativeMenu() {
      return this.collaborativeMenu?.menu;
    },
    getFilterOptions() {
      const features = this.featuresPlugin.features;

      const options = !features.forum_enabled
        ? this.$parent.options.filter(opt => opt.section_number !== "4")
        : this.$parent.options;

      return options;
    },
  },
  methods: {
    sidebarHover(id) {
      $("#sidebar-mouse-over-" + id).css("background-color", "#4bedb1");
    },
    leaveSidebarHover(id) {
      $("#sidebar-mouse-over-" + id).css("background-color", "");
    },
    getOptionSelected(optionIndex) {
      if (
        optionIndex == 2 &&
        (this.page == 102 || this.page == 101 || this.page == 105)
      ) {
        return true;
      }
      return optionIndex == this.page;
    },
    showSubmenu(index) {
      this.activeSubmenu = index;
    },
    hideSubmenu() {
      this.activeSubmenu = null;
    },
    isDisabled() {
      return this.spaceConfig.isTrialFinished;
    },
  },
};
</script>

<style lang="css" scoped>
  .sidebar-option-content-disabled {
    opacity: 0.5;
  }

  .sidebar-option-content-disabled:active {
    pointer-events: none;
  }

  .sidebar-option-content-disabled:hover {
    cursor: not-allowed;
  }
</style>