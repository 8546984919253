import { reactive, computed } from "vue";
import axios from "axios";

// Create the space config state and methods
const createSpaceConfig = () => {
  const state = reactive({
    SPACE_CURRENT: undefined,
    RESTRICTIONS: [],
    PERMISSIONS: [],
    ROLES: {
      EXPERT: 1,
      EVALUATOR: 2,
    },
  });

  const setSpaceCurrent = (space) => {
    if (space) {
      state.SPACE_CURRENT = space;
    }
  };

  const getSpaceCurrent = () => {
    return state.SPACE_CURRENT;
  };

  const RESTRICTIONS_IDS = computed(() =>
    state.RESTRICTIONS.map((restriction) => restriction.space_id)
  );

  const getRestrictions = () => {
    const str = process.env.VUE_APP_SPACE_RESTRICTIONS;
    if (!str) {
      throw new Error("Space restrictions configuration is not defined");
    }
    return JSON.parse(str.replace(/\s/g, "").replace(/"(\w+)":/g, '"$1":'));
  };

  const getPermissions = async () => {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/space/permissions`
      );

      state.PERMISSIONS = response.data?.original;
    } catch (error) {
      console.error("Error fetching features:", error);
      throw new Error("Error fetching permissions");
    }
  };

  const hasRestrictions = (id) => {
    return RESTRICTIONS_IDS.value.some((spaceId) => spaceId == id);
  };

  const hasPermission = (url) => {
    if (state.SPACE_CURRENT?.is_trial && state.SPACE_CURRENT?.is_trial_active) {
      return true;
    }

    return state.PERMISSIONS["allow_trial"].some((permission) => {
      const regex = new RegExp(permission.regex);
      return regex.test(url);
    });
  };

  const getOptionsBySpaceId = (spaceId) => {
    if (!spaceId) return null;

    getPermissions();

    const options = state.RESTRICTIONS.map((restriction) => {
      let object = {
        space_id: restriction.space_id,
        limit_date: restriction.limit_date,
        member: {
          tabs: [0],
        },
        tutor: {
          tabs: [0],
        },
      };

      Object.keys(state.ROLES).forEach((role) => {
        if (restriction.member_assistants.includes(role)) {
          object.member.tabs.push(state.ROLES[role]);
        }
        if (restriction.tutor_assistants.includes(role)) {
          object.tutor.tabs.push(state.ROLES[role]);
        }
      });

      return object;
    });

    return options.find((option) => option.space_id == spaceId);
  };

  const isAfterLimitDate = (spaceId) => {
    if (!hasRestrictions(spaceId)) return false;

    const space = getOptionsBySpaceId(spaceId);
    if (!space?.limit_date) return false;

    const limitDate = new Date(space.limit_date);
    if (isNaN(limitDate.getTime())) {
      throw new Error(`Invalid date format for space ${spaceId}`);
    }

    const currentDate = new Date();
    return currentDate > limitDate;
  };

  const isTrialFinished = computed(() => {
    return state.SPACE_CURRENT?.is_trial && !state.SPACE_CURRENT?.is_trial_active;
  });

  const isTrialEnabled = computed(() => {
    return state.SPACE_CURRENT?.is_trial && state.SPACE_CURRENT?.is_trial_active;
  });

  // Initialize restrictions
  state.RESTRICTIONS = getRestrictions();

  return {
    RESTRICTIONS: state.RESTRICTIONS,
    get SPACE_CURRENT() {
      return state.SPACE_CURRENT;
    },
    get PERMISSIONS() {
      return state.PERMISSIONS;
    },
    get isTrialFinished() {
      return isTrialFinished.value;
    },
    get isTrialEnabled() {
        return isTrialEnabled.value;
    },
    ROLES: state.ROLES,
    RESTRICTIONS_IDS,
    getOptionsBySpaceId,
    getSpaceCurrent,
    hasPermission,
    hasRestrictions,
    isAfterLimitDate,
    setSpaceCurrent,
    getPermissions,
  };
};

// Create plugin
export default {
  install: (app) => {
    const spaceConfig = createSpaceConfig();
    app.provide("spaceConfig", spaceConfig);
    app.config.globalProperties.$spaceConfig = spaceConfig;
  },
};