<template>
  <div class="container">
    <template v-if="formSent">
      <MessageSentComponent
        :lang="lang"
        type="feedback"
        @return-to-form="handleReturnToForm"
      />
    </template>
    <template v-else>
      <p>
        {{ langFilter("textFeedback") }}
        <br />
        {{ langFilter("textFeedback2") }}
        <br />
      </p>
      <p>
        {{ langFilter("textFeedback3") }}
        <a href="mailto:feedback@acceleralia.com">feedback@acceleralia.com</a>
      </p>
      <p>
        {{ langFilter("formOption") }}
      </p>
      <FormSupport
        :lang="lang"
        :email="email"
        :name="name"
        @submit="handleFormSubmit"
      />
    </template>
  </div>
</template>

<script>
import FormSupport from "./FormSupportComponent.vue";
import MessageSentComponent from "./MessageSentComponent.vue";
import translationMixin from "@/mixins/translationMixin";
import axios from "axios";

export default {
  components: {
    FormSupport,
    MessageSentComponent,
  },
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formSent: false,
      traducciones: [
        {
          name: "textFeedback",
          es: "¡Tu opinión cuenta mucho para nosotros!",
          en: "Your opinion is very important to us!",
        },
        {
          name: "textFeedback2",
          es: "Si tienes sugerencias, ideas de mejora o funcionalidades que te gustaría ver, estaremos encantados de escucharte.",
          en: "If you have suggestions, ideas for improvement or features you would like to see, we will be happy to hear from you.",
        },
        {
          name: "textFeedback3",
          es: "Puedes enviarnos tus comentarios directamente a:",
          en: "You can send us your feedback directly to:",
        },
        {
          name: "formOption",
          es: "También puedes optar por completar nuestro formulario de contacto. ¡Te esperamos!",
          en: "You can also opt to fill out our contact form. ¡We look forward to hearing from you!",
        },
      ],
    };
  },
  methods: {
    handleFormSubmit({ message }) {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/customer-support-mail/send-email`,
          {
            message: message,
            email: this.email,
            name: this.name,
            type: "feedback",
          }
        )
        .then(() => {
          this.formSent = true;
        })
        .catch(() => {});
    },
    handleReturnToForm() {
      this.formSent = false;
    },
  },
};
</script>
