<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <div class="container-new-template">
    <img
      v-if="loadingSave"
      class="carga2"
      :src="require('@/assets/img/general/carga.gif')"
      alt="Loading animation"
    />
    <div v-show="!loadingSave" class="row">
      <div class="col-2" />
      <div class="col-2"></div>
      <div class="col-4 new-template-input-div">
        <input
          class="new-template-input"
          maxlength="58"
          minlength="1"
          type="text"
          :value="templateName"
          @input="updateTitle($event)"
        />
      </div>
      <div class="col-4 newtemplate-container-btn-div">
        <div>
          <hover :content="langFilter('resources')" :bs="true">
            <button
              class="new-template-btn new-template-btn-enabled save-icon"
              @click="openResourcesModal"
            />
          </hover>
        </div>
        <div>
          <hover :content="langFilter('saveDraft')" :bs="true">
            <button
              v-if="notShowEdit || true"
              class="new-template-btn-enabled new-template-btn draft-icon"
              @click="handleSaveDraft"
            />
          </hover>
        </div>
        <div>
          <hover :content="langFilter('save')" :bs="true">
            <button
              :style="`background-image: url('/img/track/${
                !loadingSave ? 'save.svg' : 'save-disabled.svg'
              }')`"
              :class="[
                { 'new-template-btn-disabled': loadingSave },
                { 'new-template-btn-enabled': !loadingSave },
                { 'new-template-btn': true },
              ]"
              @click="handleSaveTemplate"
            />
          </hover>
        </div>
      </div>
    </div>
    <div v-show="!loadingSave">
      <draggable
        v-model="themes"
        draggable=".chapter"
        handle=".dragg-me-chapter"
        animation="500"
        ghostClass="ghost"
        chosenClass="chosen"
        :options="{
          animation: 500,
          handle: '.dragg-me-chapter',
          ghostClass: 'ghost',
          chosenClass: 'chosen',
          group: 'chapter',
          itemKey: 'id',
        }"
      >
        <new-template-creation-theme
          v-for="(theme, themeIndex) in themes"
          :key="themeIndex"
          :theme="theme"
          :themeIndex="themeIndex"
          :lang="lang"
          :checkedError="checkedError"
          @openModalConfirmation="openModalConfirmation($event)"
          @updateTheme="updateTheme($event)"
          @updateApart="updateApart($event)"
          @addApart="addApart($event)"
          @addQuestion="addQuestion($event)"
          @updateQuestion="updateQuestion($event)"
          @addTheme="addTheme($event)"
          @checkError="checkError($event)"
        />
      </draggable>
      <hr class="new-template-separator" />
      <confirmation ref="modalConfirmation" :lang="lang" />
      <new-template-edit-question
        ref="modalEditQuestion"
        :myChoices="[]"
        :lang="lang"
        @editQuestion="editQuestion($event)"
        @openEditQuestionModal="openEditQuestionModal()"
      />
      <resources-modal
        v-if="resourcesModal"
        ref="modalResources"
        :lang="lang"
        :themes="themes"
        :edit="true"
        :savedResources="savedResources"
        @updateResources="updateResources($event)"
        @closeResources="openResourcesModal"
        @deleteResources="deleteResources($event)"
        @saveResources="savedResources = $event"
        @clear-resources="clearResources($event)"
      />
    </div>
    <ModalConfirm
      v-model="showModal"
      :title="modalConfig.title"
      :message="modalConfig.message"
      :variant="modalConfig.variant"
      @cancel="confirmTrackCancel"
      @confirm="confirmTrackContinue"
    />
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { Modal } from "bootstrap";
import translationMixin from "../../../mixins/translationMixin.js";
import { VueDraggableNext } from "vue-draggable-next";
import { ref, reactive, nextTick } from "vue";
import ModalConfirm from "@/components/popup/ModalConfirm.vue";

export default {
  props: [
    "lang",
    "title",
    "image",
    "space",
    "categories",
    "draft",
    "relations",
    "assistant_id",
    "assistant_evaluator_id",
  ],
  components: {
    draggable: VueDraggableNext,
    ModalConfirm,
  },
  setup() {
    const showModal = ref(false);

    const modalConfig = reactive({
      variant: "accept",
      title: "",
      message: "",
    });

    function confirmTrackCancel() {
      showModal.value = false;
    }

    function confirmTrackContinue() {
      showModal.value = false;
    }

    return {
      showModal,
      modalConfig,
      confirmTrackCancel,
      confirmTrackContinue,
    };
  },

  data() {
    return {
      notShowEdit: false,
      savedResources: [],
      toDeleteResources: [],
      // We start with one blank theme by default
      themes: [this.createNewTheme()],
      confirmationType: "",
      confirmationIndex: "",
      editQuestionIndex: "",
      editQuestionModal: false,
      resourcesModal: false,
      allCompleted: false,
      draftCompleted: false,
      templateName: this.title,
      templateAssistantId: this.assistant_id,
      templateAssistantEvaluator: this.assistant_evaluator_id,
      editing: false,
      template_id: this.$route.params.template_id,
      saving: false,
      questionsDeleted: [],
      themesDeleted: [],
      apartsDeleted: [],
      loadingDraft: false,
      loadingSave: false,
      checkedError: false,
      traducciones: [
        {
          name: "addTheme",
          es: "Añadir Tema",
          en: "Add Theme",
        },
        {
          name: "themeName",
          es: "Escribir nombre del Tema...",
          en: "Write the name of the Theme...",
        },
        {
          name: "sectionName",
          es: "Escribir nombre del Apartado...",
          en: "Write the name of the Section...",
        },
        {
          name: "questionName",
          es: "Escribir la pregunta...",
          en: "Write the question...",
        },
        {
          name: "desription",
          es: "Descripción...",
          en: "Description...",
        },
        {
          name: "resources",
          es: "Recursos",
          en: "Resources",
        },
        {
          name: "saveDraft",
          es: "Guardar borrador",
          en: "Save draft",
        },
        {
          name: "save",
          es: "Guardar",
          en: "Save",
        },
        {
          name: "completeAllFields",
          es: "Por favor, completa todos los campos",
          en: "Please, complete all the fields",
        },
        {
          name: "upload",
          es: "Importar",
          en: "Import",
        },
        {
          name: "errorSavingTemplate",
          es: "Hubo un error guardando su plantilla. Por favor, vuelva a intentar más tarde",
          en: "There was an error saving the template. Please try again later.",
        },
        {
          name: "sureDeletePart",
          es: "¿Estás seguro de que deseas eliminar este apartado?",
          en: "Are you sure you want to delete this section?",
        },
        {
          name: "sureDeleteTheme",
          es: "¿Estás seguro de que deseas eliminar este tema?",
          en: "Are you sure you want to delete this theme?",
        },
        {
          name: "sureDeleteQuestion",
          es: "¿Estás seguro de que deseas eliminar esta pregunta?",
          en: "Are you sure you want to delete this question?",
        },
        {
          name: "deletePart",
          es: "Eliminar Apartado",
          en: "Delete Part",
        },
        {
          name: "deleteQuestion",
          es: "Eliminar Pregunta",
          en: "Delete Question",
        },
        {
          name: "deleteTheme",
          es: "Eliminar Tema",
          en: "Delete Theme",
        },
      ],
    };
  },
  created() {
    this.notShowEdit = this.$route.params.template_id === "";

    // Build the resource URL
    let url = window.location.href;
    let id = 0;
    if (url.includes("new-template")) {
      const newUrl = url.split("/");
      id = newUrl[newUrl.length - 1];
      url = `/getResourcesByTemplate/${id}`;
    } else if (url.includes("itinerary")) {
      const newUrl = url.split("/");
      id = newUrl[newUrl.length - 1];
      url = `/getResourcesByProject/${id}`;
    } else {
      const newUrl = url.split("/");
      id = newUrl[newUrl.length - 1];
      url = `/getResourcesByTrack/${id}`;
    }

    axios.get(process.env.VUE_APP_API_URL + url).then((response) => {
      const newResources = [];

      // Merge response data sets while avoiding duplicates
      response.data.forEach((dataArray) => {
        dataArray.forEach((el) => {
          if (
            !newResources.find(
              (item) =>
                item.resource_id === el.resource_id &&
                item.solution_id === el.solution_id
            )
          ) {
            newResources.push({
              resource_id: el.resource_id,
              name: el.resource,
              link: el.link,
              solution_id: el.solution_id,
              track_id: id,
              station_id: el.station_id,
            });
          }
        });
      });

      this.savedResources = newResources;
    });
  },
  methods: {
    // -------------------------------------------
    // 1) REUSABLE OBJECT CREATORS (REDUCES DUPLICATION)
    // -------------------------------------------
    createNewQuestion() {
      return {
        title: "",
        description: "",
        type: 1,
        choices: [{ name: "", selected: true }],
        position: 0,
        prompt: "",
        preId: this.generateRandomString(),
      };
    },
    createNewApart() {
      return {
        title: "",
        resources: [],
        position: 0,
        preId: this.generateRandomString(),
        questions: [this.createNewQuestion()],
      };
    },
    createNewTheme() {
      return {
        title: "",
        description: "",
        resources: [],
        position: 0,
        preId: this.generateRandomString(),
        aparts: [this.createNewApart()],
      };
    },
    // -------------------------------------------
    // 2) UNIFYING SAVE LOGIC
    // -------------------------------------------
    handleSaveTemplate() {
      // Save fully
      this.handleSave(false /* draft */, false /* exit */);
    },
    handleSaveDraft() {
      // Save as draft
      this.handleSave(true /* draft */, false /* exit */);
    },
    handleSave(draft, exit) {
      // This consolidates logic from saveTemplate() and saveDraft()
      this.checkIfAllCompleted();

      // If we are saving as draft, only require `draftCompleted`
      // If we are saving as final, require `allCompleted`
      const canProceed =
        (!draft && this.allCompleted) ||
        (draft && (this.draftCompleted || this.allCompleted));

      if (!this.loadingSave && !this.loadingDraft) {
        if (canProceed) {
          this.saving = true;
          this.draftCompleted = false;
          let img = this.image.split("/track_headers/")[1];
          if (img == null) img = "predefined.png";

          let url = "createTemplate";
          if (this.editing) url = "updateTemplateDraft";

          // Remove blank questions
          this.themes.forEach((theme) => {
            theme.aparts.forEach((apart) => {
              apart.questions = apart.questions.filter(
                (q) => q.title.trim() !== ""
              );
            });
          });

          if (!this.template_id) this.template_id = null;

          // Convert draft to 0 or 1
          const draftValue = draft ? 1 : 0;

          this.loadingSave = true;
          axios
            .post(`${process.env.VUE_APP_API_URL}/template/${url}`, {
              name: this.templateName,
              image: img,
              themes: this.themes,
              space_id: this.space.id,
              categories: this.categories,
              template_id: this.template_id,
              lang: this.lang,
              draft: draftValue,
              questionsDeleted: this.questionsDeleted,
              themesDeleted: this.themesDeleted,
              apartsDeleted: this.apartsDeleted,
              toDeleteResources: this.toDeleteResources,
              savedResources: this.savedResources,
              assistant_id: this.assistant_id,
              evaluator_id: this.assistant_evaluator_id,
            })
            .then((response) => {
              if (!exit) {
                this.$emit("setLoading", true);
                if (!draftValue) {
                  // Final save -> redirect to templates
                  window.location.href = `/templates?saved=true`;
                } else {
                  // Draft save -> stay on this route or reload
                  window.location.href = `/new-template/${response.data.template.id}`;
                }
              }
            })
            .catch((error) => {
              this.checkedError = true;
              this.$emit("openAlert", {
                text: this.langFilter("errorSavingTemplate"),
                successful: false,
              });
              console.error(error);
            })
            .finally(() => {
              this.loadingSave = false;
              this.loadingDraft = false;
              setTimeout(() => {
                this.saving = false;
              }, 3000);
            });
        } else {
          this.checkedError = true;
          this.$emit("openAlert", {
            text: this.langFilter("completeAllFields"),
            successful: false,
          });
          this.scrollToError();
        }
      }
    },
    async uploadGpt(event) {
      const file = event.target.files[0];
      const cleanProject = "on";

      const formData = new FormData();
      formData.append("doc", file);
      formData.append("clean_project", cleanProject);
      formData.append("project_id", null);
      formData.append("space_id", this.space.id);

      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}/newProject/fromFile`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        this.$emit("showConfirmationModalInfo", "Archivo subido correctamente");
      } catch (error) {
        console.error(error);
        this.$emit(
          "showConfirmationModalInfo",
          "Ha habido un error al subir el archivo. Por favor, inténtalo de nuevo."
        );
      }
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    deleteResources(resources) {
      this.toDeleteResources.push(...resources);
    },
    checkError(err) {
      this.checkedError = err;
    },
    async scrollToError() {
      await nextTick();

      let element = document.querySelector(".new-templeate-error-border");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      } else {
        element = document.querySelector(".new-template-error-input");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
    checkIfAllCompleted() {
      this.allCompleted =
        this.templateName.trim() !== "" &&
        this.themes.every((theme) => {
          return (
            theme.title &&
            theme.aparts.every((apart) => {
              return (
                apart.title &&
                apart.questions.every((question) => question.title)
              );
            })
          );
        });

      this.draftCompleted =
        this.templateName.trim() !== "" &&
        this.themes.every((theme) => {
          return theme.title && theme.aparts.every((apart) => apart.title);
        });
    },
    updateResources(data) {
      this.themes = data;
      this.openResourcesModal();
    },
    openResourcesModal() {
      this.resourcesModal = !this.resourcesModal;
    },
    clearResources(data) {
      this.savedResources = this.savedResources.filter(
        ({ resource_id: id }) => !data.includes(id)
      );
    },
    openEditQuestionModal() {
      this.$refs.modalConfirmation.confirmationMessage =
        "No saved changes will be lost";
      this.$refs.modalConfirmation.confirmationMessage2 = "";
      this.$refs.modalConfirmation.confirmationButtonText = "Cancelar";
      this.$refs.modalConfirmation.confirmationButtonText2 = "yes, continue";
      this.editQuestionModal = true;
    },
    addTheme(index) {
      const newTheme = this.createNewTheme();
      this.themes.splice(index + 1, 0, newTheme);
      this.allCompleted = false;
      this.draftCompleted = false;
    },
    addApart(indexArray) {
      const [themeIndex, apartIndex] = indexArray;
      const newApart = this.createNewApart();
      this.themes[themeIndex].aparts.splice(apartIndex + 1, 0, newApart);
      this.allCompleted = false;
      this.draftCompleted = false;
    },
    addQuestion(indexArray) {
      const [themeIndex, apartIndex, questionIndex] = indexArray;
      const newQuestion = this.createNewQuestion();
      this.themes[themeIndex].aparts[apartIndex].questions.splice(
        questionIndex + 1,
        0,
        newQuestion
      );
      this.allCompleted = false;
    },
    generateRandomString() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < 8; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    updateTitle(event) {
      this.templateName = event.target.value;
      this.$emit("updateTitle", this.templateName);
      this.checkIfAllCompleted();
    },

    updateTheme(data) {
      const UPDATE_TITLE = 1;
      const UPDATE_DESCRIPTION = 2;
      const [text, themeIndex, updateType] = data;

      if (updateType === UPDATE_TITLE) {
        this.themes[themeIndex].title = text;
      } else if (updateType === UPDATE_DESCRIPTION) {
        this.themes[themeIndex].description = text;
      }

      this.checkIfAllCompleted();
    },
    updateApart(data) {
      // data = [text, apartIndex, themeIndex]
      this.themes[data[2]].aparts[data[1]].title = data[0];
      this.checkIfAllCompleted();
    },
    updateQuestion(data) {
      // data = [title, type, qIndex, apartIndex, themeIndex]
      this.themes[data[4]].aparts[data[3]].questions[data[2]].title = data[0];
      this.themes[data[4]].aparts[data[3]].questions[data[2]].type = data[1];
      this.checkIfAllCompleted();
    },
    editQuestion(data) {
      // data is the updated question object
      const index = this.editQuestionIndex;
      data.id = this.themes[index[0]].aparts[index[1]].questions[index[2]].id;
      this.themes[index[0]].aparts[index[1]].questions[index[2]] = data;
      this.editQuestionIndex = "";
      this.checkIfAllCompleted();
    },

    openModalConfirmation(data) {
      const DELETE_THEME = 1;
      const DELETE_PART = 2;
      const DELETE_QUESTION = 3;

      const action = data[0];
      this.confirmationType = action;
      this.confirmationIndex = [data[3], data[2], data[4], data[3]];

      let modalTitle = "";
      let modalMessage = "";

      if (this.confirmationType === DELETE_QUESTION) {
        modalTitle = this.langFilter("deleteQuestion");
        modalMessage = this.langFilter("sureDeleteQuestion");
      } else if (this.confirmationType === DELETE_PART) {
        modalTitle = this.langFilter("deletePart");
        modalMessage = this.langFilter("sureDeletePart");
      } else if (this.confirmationType === DELETE_THEME) {
        modalTitle = this.langFilter("deleteTheme");
        modalMessage = this.langFilter("sureDeleteTheme");
      }

      this.modalConfig.type = "question";
      this.modalConfig.variant = "yesNo";
      this.modalConfig.title = modalTitle;
      this.modalConfig.message = modalMessage;
      this.modalConfig.onConfirm = this.confirmTrackContinue;
      this.modalConfig.onCancel = this.confirmTrackCancel;

      this.showModal = true;

      this.$root.$emit("show-modal", {
        type: "question",
        variant: "yesNo",
        title: modalTitle,
        message: modalMessage,
        onConfirm: this.confirmTrackContinue,
        onCancel: this.confirmTrackCancel,
      });
    },
    confirmTrackContinue() {
      if (this.editQuestionModal) {
        this.editQuestionModal = false;
        Modal.getOrCreateInstance(
          document.getElementById("modalEditQuestion")
        ).hide();
        this.$refs.modalEditQuestion.reset();
      } else {
        // Delete logic
        if (this.confirmationType === 3) {
          // Delete question
          const i = this.confirmationIndex[1][0];
          const o = this.confirmationIndex[1][1];
          const q = this.confirmationIndex[1][2];
          const id = this.themes[i].aparts[o].questions[q].id;
          if (id) this.questionsDeleted.push(id);
          this.themes[i].aparts[o].questions.splice(q, 1);
        } else if (this.confirmationType === 2) {
          // Delete apart
          const i = this.confirmationIndex[3];
          const themeIndex = this.confirmationIndex[2];
          const numAparts = this.themes[themeIndex].aparts.length;
          if (numAparts > 1) {
            const id = this.themes[themeIndex].aparts[i].id;
            if (id) this.apartsDeleted.push(id);
            this.themes[themeIndex].aparts.splice(i, 1);
          } else {
            this.$emit("openAlert", { text: "deletePart", successful: false });
          }
        } else {
          // Delete theme
          if (this.themes.length > 1) {
            const i = this.confirmationIndex[0];
            const id = this.themes[i].id;
            if (id) this.themesDeleted.push(id);
            this.themes.splice(i, 1);
          } else {
            this.$emit("openAlert", { text: "deleteTheme", successful: false });
          }
        }
      }
      this.showModal = false;
    },
    confirmTrackCancel() {
      this.confirmationType = "";
      this.confirmationIndex = "";
      this.showModal = false;
    },
    getTemplateDaft(relations) {
      this.editing = true;

      // Group by station_id
      const byStation = relations[0].reduce((acc, obj) => {
        if (!acc[obj.station_id]) {
          acc[obj.station_id] = [];
        }
        acc[obj.station_id].push(obj);
        return acc;
      }, {});

      // Group questions by solution_id
      const questions = relations[1].reduce((acc, obj) => {
        if (!acc[obj.solution_id]) {
          acc[obj.solution_id] = [];
        }
        acc[obj.solution_id].push(obj);
        return acc;
      }, {});

      this.themes = [];
      let stationIndex = 0;

      for (const station_id in byStation) {
        const stationGroup = byStation[station_id];
        const station = stationGroup[0];
        const stationResources = relations[2].filter(
          (resource) =>
            resource.solution_id === null &&
            resource.station_id === station.station_id
        );

        let themePosc = station.station_position ?? stationIndex;
        this.themes.push({
          id: station.station_id,
          title: station.station_name,
          description: station.station_description,
          resources: stationResources,
          aparts: [],
          position: themePosc,
        });

        // Reorder solutions
        stationGroup.sort((a, b) => a.solution_position - b.solution_position);
        for (const solution of stationGroup) {
          let myQuestions = [];
          if (questions[solution.solution_id]) {
            myQuestions = questions[solution.solution_id].map(
              (question, questionIndex) => {
                let choices = [];
                if (question.options) {
                  choices = question.options
                    .split("[;separator;]")
                    .map((option, i) => ({
                      name: option,
                      selected: i === 0,
                    }));
                }
                return {
                  id: question.id,
                  title: question.name,
                  description: question.description,
                  prompt: question.prompt,
                  type: question.type,
                  choices: choices,
                  order: question.order,
                  position: question.position ?? questionIndex,
                };
              }
            );
            myQuestions.sort((a, b) => a.position - b.position);
          }

          const solutionResources = relations[2].filter(
            (r) =>
              r.station_id === station.station_id &&
              r.solution_id === solution.solution_id
          );

          this.themes[stationIndex].aparts.push({
            id: solution.solution_id,
            title: solution.solution_name,
            resources: solutionResources,
            position: solution.solution_position ?? stationIndex,
            questions: myQuestions,
          });
        }

        this.themes[stationIndex].aparts.sort(
          (a, b) => a.position - b.position
        );
        stationIndex++;
      }

      // Reorder themes
      this.themes.sort((a, b) => a.position - b.position);

      this.templateName = this.title;
      this.templateAssistantId = this.template_assistant_id;
      this.templateAssistantName = this.template_assistant_name;
      this.checkIfAllCompleted();
      this.$emit("setLoading", false);
    },
  },
  watch: {
    title(val) {
      this.templateName = val;
    },
    relations(relations) {
      this.getTemplateDaft(relations);
    },
    template_assistant_id(val) {
      this.templateAssistantId = val;
    },
    template_assistant_name(val) {
      this.templateAssistantName = val;
    },
  },
  mixins: [translationMixin],
};
</script>
