import { reactive } from "vue";
import axios from "axios";

const createFeatures = () => {
  const state = reactive({
    featuresData: [],
  });

  const getFeatures = async () => {
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/features`);
      state.featuresData = response.data;
    } catch (error) {
      console.error("Error fetching features:", error);
      return;
    }
  };

  return {
    getFeatures,
    get features() {
      return state.featuresData;
    },
  };
};

export default {
  install: (app) => {
    const featuresPlugin = createFeatures();
    app.provide("featuresPlugin", featuresPlugin);
    app.config.globalProperties.$featuresPlugin = featuresPlugin;
  }
};
