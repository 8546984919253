<template>
  <div v-if="openModal">  
    <generic-modal @close-confirmation="handleToggleModal">
      <template #body>
        <div class="confirmation-modal-chat">
          <h4 class="trial-modal-title">{{ langFilter("endTrialPeriodTitle") }}</h4>
          <p class="generic-modal-content trial-modal-content">
            {{ langFilter("endTrialPeriodMessage") }}
          </p>
          <div>
            <button @click="handleToggleModal">
              {{ langFilter("buttonAccept") }}
            </button>
          </div>
        </div>
      </template>
    </generic-modal>
  </div>
  <Error
    title="end-trial-period-title"
    body=""
    link="link-redirect"
    image='<img class="cohete" id="cohete" src="/img/errors/cohete.svg">'
    vector="/img/errors/vector_158.svg"
    vamos="end-trial-period-button"
    :noButton="true"
    class="trial-body"
  />
</template>

<script>
import translationMixin from "@/mixins/translationMixin.js";

export default {
  name: "EndTrialPeriodView",
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      openModal: true,
      traducciones: [
        {
          name: "endTrialPeriodTitle",
          es: "Periodo de prueba",
          en: "Probation",
        },
        {
          name: "endTrialPeriodMessage",
          es: "Su período de prueba ha finalizado",
          en: "Your probationary period has ended",
        },
        {
          name: "buttonAccept",
          es: "Aceptar",
          en: "Accept",
        },
      ]
    }
  },
  methods: {
    handleToggleModal() {
      this.openModal = !this.openModal;
    },
  },
};
</script>

<style scoped>
.trial-modal-content {
  font-size: 20px;
  color: #2a3b5a;
}

.trial-modal-title {
  font-weight: bold;
  color: #2a3b5a;
}

.trial-body {
  margin-left: 2rem;
  margin-right: 2rem;
}
</style>
