<script setup>
import { RouterView } from "vue-router";
import axios from "axios";
import Sidebar from "./components/sidebar/SidebarComponent.vue";
import Cookies from "js-cookie";
</script>

<template>
  <div v-if="!showSidebar || (lang != '' && spaces != [] && role != '')">
    <Sidebar
      v-if="showSidebar"
      class="sidebar-main-container"
      :space="spaces"
      :role="role"
      :lang="lang"
      @change-space="changeSpace($event)"
      @reload-home-notifications="reloadHomeNotifications()"
    />
    <popup-modal-claude />
    <div :class="showSidebar ? 'div-general-menu-lateral' : ''">
      <RouterView
        v-if="showData"
        :homeNotifications="homeNotifications"
        :spaces="spaces"
        :role="role"
        :lang="lang"
        @open-alert="openAlert($event)"
      />
      <alertMessage2 ref="alerts2" :lang="lang" />
      <alertToast ref="alertToast" />
    </div>
    <chat-bot
      v-if="showGPT && spaces.length > 0"
      :space="spaces[0]"
      :spaceId="spaces[0].id"
      :lang="lang"
    />
  </div>
</template>

<script>
export default {
  inject: ["featuresPlugin", "spaceConfig"],
  provide() {
    return {
      showAlert: this.openAlert,
      showAlertToast: this.alertToast,
    };
  },
  data() {
    return {
      showSidebar: false,
      spaces: [],
      role: "",
      lang: "",
      showData: true,
      homeNotifications: 0,
      showGPT: false,
    };
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
  },
  watch: {
    $route(to) {
      document.title = to.name + " - Acceleralia" || "Acceleralia";
    },
  },
  created() {
    setTimeout(() => {
      this.showGPT =
        !this.$route.path.includes("login") &&
        !this.$route.path.includes("register");
    }, 100);
    if (Cookies.get("token")) {
      this.getInitialData();
    } else {
      if (
        window.location.pathname.includes(
          "/autologin/" + process.env.VUE_APP_AUTOLOGIN_KEY
        )
      ) {
        const url = window.location.pathname.split("/");
        axios
          .post(`${process.env.VUE_APP_API_URL}/autologin`, {
            token: url[2],
            id: url[3],
          })
          .then((response) => {
            Cookies.set("token", response.data.token, { expires: 7 });
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + Cookies.get("token");
          })
          .finally(() => {
            window.location.href = "/home";
          });
      } else {
        if (
          !window.location.pathname.includes("/privacy-policy") &&
          !window.location.pathname.includes("/terms-conditions") &&
          !window.location.pathname.includes("/signup/") &&
          !window.location.pathname.includes("/login") &&
          !window.location.pathname.includes("/recover-password")
        ) {
          window.location.href = "/login";
        }
      }
    }
  },
  mounted() {
    window.addEventListener("popstate", this.onBackButtonPressed);
  },
  beforeUnmount() {
    window.removeEventListener("popstate", this.onBackButtonPressed);
  },
  methods: {
    checkGptAccess() {
      if (this.spaces.length > 0)
        return this.spaces[0].id === 19930 || this.spaces[0].id === 36921;
      return false;
    },
    checkIfShowChat() {
      if (this.spaces.length > 0) {
        if (this.spaces[0].id === 19930 || this.spaces[0].id === 36921) {
          return true;
        }
      }
      return false;
    },
    changeSpace(space) {
      this.spaces = space;
      this.showData = false;
      this.$nextTick(() => {
        this.showData = true;
      });
    },
    reloadHomeNotifications() {
      this.homeNotifications++;
    },
    getInitialData() {
      this.featuresPlugin.getFeatures();
      this.spaceConfig.getPermissions();

      this.showSidebar = true;
      if (this.spaces.length == 0) {
        axios
          .get(`${process.env.VUE_APP_API_URL}/getInitialData`)
          .then((response) => {
            this.spaces = response.data.space;
            this.role = response.data.role;
            this.lang = response.data.lang;

            this.spaceConfig.setSpaceCurrent(this.spaces[0]);
          })
          .catch((error) => {
            if (error.response?.status == 401) {
              Cookies.remove("token");
              window.location.href = "/login";
            }
          });
      }
    },
    openAlert({ title, text, successful, purple }) {
      this.$refs.alerts2.openErrorsAlert({ title, text, successful, purple });
    },
    alertToast({ title, message, variant }) {
      return this.$refs.alertToast.openAlert({ title, message, variant });
    },
  },
};
</script>

<style>
@import "../src/assets/css/sidebar/sidebar.css";
@import "~mdb-ui-kit/css/mdb.min.css";
@import "../src/assets/css/general.css";
@import "../src/assets/css/modals/modal.css";
@import "../src/assets/css/errors.css";
@import "../src/assets/css/projects/modalResources.css";
@import "../src/assets/css/fonts.css";
@import "../src/assets/css/chatgpt.css";
@import "../src/assets/css/sidebar/right-sidebar.css";
</style>
