<script setup>
import MyProjects from "../components/projects/MyProjectsComponent.vue";
</script>

<template>
  <MyProjects v-if="!spaceConfig.isTrialFinished" :role="role" :space="spaces[0]" :lang="lang" />
</template>

<script>
export default {
  inject: ["spaceConfig"],
  props: {
    role: {
      type: String,
      required: true,
    },
    spaces: {
      type: Array,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    view: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
@import "../../src/assets/css/home/home.css";
</style>
