<template>
  <div v-if="isVisibleExtensionModal" class="invalid-extension-modal">
    <generic-modal @close-confirmation="handleExtensionModal">
      <template #body>
        <div class="confirmation-modal-chat">
          <h4>{{ langFilter('unsupportedExtension') }}</h4>
          <p class="generic-modal-content">
            {{ langFilter("extensionInvalid") }}
          </p>
          <div>
            <button @click="handleExtensionModal">
              {{ langFilter("buttonAccept") }}
            </button>
          </div>
        </div>
      </template>
    </generic-modal>
  </div>
  <div class="gpt-project-chat-container">
    <div v-if="loadingData" class="loading-data-container">
      <img :src="require('@/assets/img/general/carga.gif')" />
    </div>
    <div v-if="showConfirmationModal" class="confirmation-modal-chat-fade" @click="showConfirmationModal = false" />
    <div v-if="showConfirmationModal" class="confirmation-modal-chat">
      <h4>{{ langFilter("ConfirmDeleteChat") }}</h4>
      <h5>{{ langFilter("ActionNotUndo") }}</h5>
      <div>
        <button @click="showConfirmationModal = false">{{ langFilter("buttonCancel") }}</button>
        <button @click="confirmRemoveChat()">{{ langFilter("buttonConfirm") }}</button>
      </div>
    </div>
    <div v-if="status !== 'not-assigned'" :class="[
      isDeletedStatus ? 'gpt-project-chat-disabled' : 'gpt-project-chat',
    ]">
      <div ref="scrollContainerChat" class="gpt-help-div-content">
        <img v-if="!isCurrentQuestionTab" class="gpt-reload-logo delete-icon"
          :class="isChatDeleteDisabled ? 'chat-btn-disabled' : ''"
          :title="isChatDeleteDisabled ? langFilter('DeleteChatDisabled') : langFilter('DeleteChat')"
          :src="require('@/assets/img/projects/delete.svg')" alt="ReloadGpt" @click="openConfirmModal" />

        <chat-bot-project-chat-messages v-if="hasMessagesDisabled && !isCurrentQuestionTab" :messages="messagesDisabled"
          :disabled="true" :question="question" :show-project-chat="showProjectChat" :already-asked="alreadyAsked"
          :show-question-chat="showQuestionChat" :assistant-project="assistantProject" :loading="loading"
          :status="status" :lang="lang" @reload-action="openConfirmModal" />
        <chat-bot-project-chat-messages v-if="!isDeletedStatus" :messages="messagesEnabled" :disabled="false"
          :question="question" :show-project-chat="showProjectChat" :already-asked="alreadyAsked"
          :show-question-chat="showQuestionChat" :assistant-project="assistantProject" :loading="loading"
          :lang="lang" />

        <div v-if="isLoadingOrAsking" class="bot-message-div row">
          <div class="loading-gpt">
            <img :src="require('@/assets/img/general/carga.gif')" alt="loading" />
          </div>
        </div>
      </div>
      <div v-if="!isDeletedStatus">
        <input ref="fileInput" style="display: none" type="file" multiple :accept="getFileExtensions()"
          @change="uploadFile($event)" />
        <form class="gpt-project-input row" @submit.prevent="">
          <chat-bot-project-chat-attachment :lang="lang" :files="files" :can-change="true"
            @handle-convert-flag="handleConvertFlagInFile($event)" @remove-file="removeFile($event)" />
          <div class="row py-10">
            <div class="col-1 open-file-button" :class="{ disabled: isLoadingOrAsking }">
              <img alt="file" :src="FileIcon" :title="langFilter('File')" @click="openFile" />
            </div>
            <div class="col-10">
              <textarea v-model="prompt" :disabled="isLoadingOrAsking" type="text" placeholder="Escribe tu mensaje..."
                maxlength="4096" @keydown="handleKeyDown" />
            </div>
            <div class="col-1">
              <img v-if="loading" :src="Stop" alt="stop" :title="langFilter('stop')" @click="stopGpt($event)" />
              <img v-else class="send-gpt-button" :class="{ disabled: askingGpt }" :src="Send" alt="send"
                :title="langFilter('send')" @click="sendGpt($event)" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <alertMessage ref="alerts" :lang="lang" />
</template>

<script>
import axios from "axios";
import DOMPurify from "dompurify";
import translationMixin from "../../mixins/translationMixin.js";
import Reload from "../solution/images/reload.png";
import Send from "./images/send.png";
import Copy from "./images/copy.png";
import Stop from "./images/stop.webp";
import FileGptIcon from "./images/file-gpt-icon.png";
import FileIcon from "./images/file.svg";
import { fileType } from "./model/fileType.js";

export default {
  mixins: [translationMixin],
  inject: ["scrollToBottomProject", "showAlertToast"],
  props: {
    lang: {
      type: String,
      required: true,
    },
    showQuestionChat: {
      type: Boolean,
      required: true,
    },
    showProjectChat: {
      type: Boolean,
      required: true,
    },
    assistantProject: {
      type: Object,
      required: true,
    },
    assistantMessages: {
      type: Array,
      required: true,
    },
    selectedTab: {
      type: String,
      required: true,
    },
    preButtonSelected: {
      type: Boolean,
      required: true,
    },
    projectId: {
      type: [String, Number],
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    askingGpt: {
      type: Boolean,
      required: true,
    },
    questionId: {
      type: [String, Number],
      required: true,
    },
  },
  emits: ["onShowProjectChat", "onGetProjectGptChat", "onGetQuestionGptChat"],
  data() {
    return {
      showConfirmationModal: false,
      Reload: Reload,
      Send: Send,
      FileGptIcon: FileGptIcon,
      FileIcon: FileIcon,
      Copy: Copy,
      Stop: Stop,
      files: [],
      question: null,
      expPrompt: "",
      expFiles: [],
      evalPrompt: "",
      evalFiles: [],
      loading: false,
      alreadyAsked: false,
      buttonSelected: 2,
      sendingMessage: "",
      messagesEnabled: null,
      messagesDisabled: null,
      hasMessagesDisabled: null,
      isVisibleExtensionModal: false,
      loadingData: false,
      prompt: "",
      errors: [],
      traducciones: [
        {
          name: "extensionInvalid",
          es: "Tipo de archivo invalido. Solo se permite las extensiones .c, .cs, .cpp, .csv, .doc, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .jpeg, .jpg, .js, .gif, .png, .sh, .ts",
          en: "Invalid file type. Only the extensions .c, .cs, .cpp, .csv, .doc, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, are allowed. .tex, .txt, .css, .jpeg, .jpg, .js, .gif, .png, .sh, .ts",
        },
        {
          name: "buttonAccept",
          es: "Aceptar",
          en: "Accept",
        },
        {
          name: "buttonCancel",
          es: "Cancelar",
          en: "Cancel",
        },
        {
          name: "buttonConfirm",
          es: "Confirmar",
          en: "Confirm",
        },
        {
          name: "send",
          es: "Enviar",
          en: "Send",
        },
        {
          name: "stop",
          es: "Detener",
          en: "Stop",
        },
        {
          name: "File",
          es: "Adjuntar archivo",
          en: "Attach file",
        },
        {
          name: "DeleteChat",
          es: "Eliminar el chat con el asistente no afectará a la información de tu proyecto, pero puedes eliminarlo para limpiar el historial con él",
          en: "Deleting the chat with the assistant will not affect your project information, but you can delete it to clear your history with it",
        },
        {
          name: "SuccessDeleteChat",
          es: "Chat eliminado correctamente",
          en: "Chat deleted successfully",
        },
        {
          name: "ErrorDeleteChat",
          es: "Error eliminando el chat del asistente",
          en: "Error deleting assistant's chat",
        },
        {
          name: "DeleteChatDisabled",
          es: "No es posible eliminar el chat porque no tiene historial de mensajes",
          en: "It is not possible to delete the chat because it has no message history",
        },
        {
          name: "MaxFilesUpload",
          es: "Solamente puedes subir un maximo de [maxFiles] archivos.",
          en: "You can only upload a maximum of [maxFiles] files.",
        },
        {
          name: "MaxFileSize",
          es: "El tamaño total del archivo no tendría que sobrepasar [maxSizeop]  MB.",
          en: "Total file size should not exceed [maxSizeop] MB.",
        },
        {
          name: "PowerPointNotAllowed",
          es: "Archivos de PowerPoint no estan permitidos",
          en: "PowerPoint files are not allowed",
        },
        {
          name: "ErrorSendingMessage",
          es: "Error enviando mensaje al asistente",
          en: "Error sending message to the assistant",
        },
        {
          name: "ConfirmDeleteChat",
          es: "¿Estás seguro que quieres eliminar el contenido del chat?",
          en: "Are you sure you want to delete the content of the chat?",
        },
        {
          name: "ActionNotUndo",
          es: "Esta acción no se puede deshacer",
          en: "This action cannot be undone",
        },
        {
          name: "unsupportedExtension",
          es: "Extensión no suportada",
          en: "Unsupported extension",
        }
      ],
    };
  },
  computed: {
    processedResponse() {
      return (response) => {
        if (!response) return "";
        const sanitizedContent = DOMPurify.sanitize(response);
        const regex = /<\/?(h1|h2|h3|h4|h5)>/gi;
        return sanitizedContent.replace(regex, (match) => {
          return match.replace(/h1|h2|h3|h4|h5/, "h6");
        });
      };
    },
    isDeletedStatus() {
      return this.status === "deleted";
    },
    isLoadingOrAsking() {
      return this.loading || this.askingGpt;
    },
    isCurrentQuestionTab() {
      return this.preButtonSelected === 1;
    },
    isChatDeleteDisabled() {
      return this.assistantMessages.length === 0;
    },
  },
  watch: {
    selectedTab() {
      this.messagesDisabled =
        this.assistantMessages?.filter(
          (message) => message.id !== this.assistantProject?.id
        ) ?? this.assistantMessages;
      this.hasMessagesDisabled = this.messagesDisabled.length > 0;
      this.messagesEnabled =
        this.assistantMessages?.filter(
          (message) => message.id === this.assistantProject?.id
        ) ?? [];
      if (this.selectedTab == 2) {
        this.expFiles = this.files;
        this.files = this.evalFiles;

        this.expPrompt = this.prompt ?? "";
        this.prompt = this.evalPrompt;
      } else {
        this.evalFiles = this.files;
        this.files = this.expFiles;

        this.evalPrompt = this.prompt ?? "";
        this.prompt = this.expPrompt;
      }
    },
    assistantMessages() {
      this.$nextTick(() => {
        this.messagesDisabled =
          this.assistantMessages?.filter(
            (message) => message.id !== this.assistantProject?.id
          ) ?? this.assistantMessages;
        this.hasMessagesDisabled = this.messagesDisabled.length > 0;
        this.messagesEnabled =
          this.assistantMessages?.filter(
            (message) => message.id === this.assistantProject?.id
          ) ?? [];
      });

      this.scrollToBottom();
    },
  },
  mounted() {
    if (this.preButtonSelected === 1) {
      this.$emit("onGetProjectGptChat");
    }
    this.$nextTick(() => {
      this.messagesDisabled =
        this.assistantMessages?.filter(
          (message) => message.id !== this.assistantProject?.id
        ) ?? this.assistantMessages;
      this.hasMessagesDisabled = this.messagesDisabled?.length > 0;
      this.messagesEnabled =
        this.assistantMessages?.filter(
          (message) => message.id === this.assistantProject?.id
        ) ?? [];
    });

    this.scrollToBottom();
  },
  methods: {
    openAlert(title, text, isSuccesful) {
      this.$refs.alerts.succesful = isSuccesful;
      this.$refs.alerts.title = title;
      this.$refs.alerts.text = text;

      if (this.errors.length == 0) {
        this.errors.push("errors");
        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.$refs.alerts.purple = !isSuccesful;
          this.errors = [];
        }, 12000);
      }
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);

      this.errors = [];
    },
    handleExtensionModal() {
      this.isVisibleExtensionModal = false;
    },
    openConfirmModal() {
      this.showConfirmationModal = true;
    },
    confirmRemoveChat() {
      this.showConfirmationModal = false;
      this.removeChat();
    },
    removeChat() {
      let type = this.preButtonSelected
        ? this.preButtonSelected
        : (() => {
            let tabType = 1;
            if (this.selectedTab > 0) {
              tabType = this.selectedTab;
            }
            return tabType;
          })();

      // si el usuario esta en la pestaña de preguntas el tipo es 1 siempre
      if (this.questionId) {
        type = 1;
      }

      this.loading = true;

      axios
        .delete(
          `${process.env.VUE_APP_API_URL}/removeProjectChatGpt/${this.projectId}/${type}`
        )
        .then(() => {
          this.messagesEnabled = [];
          this.messagesDisabled = [];

          this.showAlertToast({
            title: "",
            message: this.langFilter("SuccessDeleteChat"),
            variant: "success",
          });
        })
        .catch((error) => {
          console.error(error);

          this.showAlertToast({
            title: "",
            message: this.langFilter("ErrorDeleteChat"),
            variant: "error",
          });
        }).finally(() => {
          this.loading = false;
          this.$emit("onGetProjectGptChat");
        });
    },
    getFilePath(file_name, file_path) {
      return `${process.env.VUE_APP_API_STORAGE}/storage/${file_path}`;
    },
    openFile() {
      this.$refs.fileInput.click();
    },
    sendGpt(e) {
      e.preventDefault();

      if (!this.validateFiles()) return;

      this.loading = true;
      const prompt = this.preparePrompt();
      const formData = this.prepareFormData(prompt);

      if (this.files.length > 0) {
        this.uploadFiles(formData, prompt);
      } else {
        this.sendMessage(prompt);
      }
      this.files = [];
    },

    validateFiles() {
      const maxFiles = 5;
      const maxSize = 8388608;
      const totalSize = this.files.reduce((sum, file) => sum + file.file.size, 0);
      const maxSizeop = maxSize / (1024 * 1024);

      if (this.files.length > maxFiles) {
        this.showAlertToast({
          title: "Files",
          message: this.langFilter("MaxFilesUpload").replace("[maxFiles]", maxFiles),
          variant: "error",
        });
        return false;
      }

      if (totalSize > maxSize) {
        this.showAlertToast({
          title: "Files",
          message: this.langFilter("MaxFileSize").replace("[maxSizeop]", maxSizeop),
          variant: "error",
        });
        return false;
      }

      if (this.files.length > 0) {
        const forbiddenTypes = [
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        ];

        if (this.files.some((file) => forbiddenTypes.includes(file.type))) {
          this.showAlertToast({
            title: "Ups... Something went wrong!",
            message: this.langFilter("PowerPointNotAllowed"),
            variant: "error",
          });
          return false;
        }
      }

      if (this.files.length === 0 && !this.prompt.trim()) return false;

      return true;
    },

    preparePrompt() {
      const prompt = this.prompt;
      this.sendingMessage = prompt;
      this.prompt = "";
      this.scrollToBottom();
      return prompt;
    },

    prepareFormData(prompt) {
      const formData = new FormData();
      formData.append("project_id", this.projectId ?? null);
      this.files.forEach((file, index) => {
        formData.append(`files[${index}]`, file.file);
        formData.append(`filesToConvert[${index}]`, !!file.convert);
      });

      const type = this.getAssistantType();
      formData.append("assistant_type", type);
      formData.append("message", prompt);
      formData.append("question_id", this.questionId ?? null);
      formData.append("message_id", this.message_id || null);

      return formData;
    },

    getAssistantType() {
      if (this.questionId) return 1;
      return this.preButtonSelected || (this.selectedTab > 0 ? this.selectedTab : 1);
    },

    uploadFiles(formData, prompt) {
      axios
        .post(`${process.env.VUE_APP_API_URL}/assistant/uploadFile`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          this.$refs.fileInput.value = "";
          if (this.question) {
            this.previousUserMessages.push(prompt);
            this.previousBotMessages.push(res.data.message);
          } else {
            this.$emit("onGetProjectGptChat");
          }
          this.scrollToBottom();
        })
        .catch((error) => {
          this.showAlertToast({
            title: "Ups... Something went wrong!",
            message: this.langFilter("ErrorSendingMessage"),
            variant: "error",
          });
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    sendMessage(prompt) {
      const type = this.getAssistantType();
      const question_id = this.questionId && type !== 2 ? this.questionId : null;

      axios
        .post(`${process.env.VUE_APP_API_URL}/projects/${this.projectId}/${type}`, {
          project_id: this.projectId,
          message: prompt,
          question_id,
          assistant_type: type,
        })
        .then(() => {
          this.$emit("onGetProjectGptChat");
          this.scrollToBottom();
        })
        .catch((error) => {
          this.showAlertToast({
            title: "Ups... Something went wrong!",
            message: this.langFilter("ErrorSendingMessage"),
            variant: "error",
          });
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    stopGpt(e) {
      e.preventDefault();
      this.loading = false;
      this.sendingMessage = "";
      this.$emit("onShowProjectChat", true);
      this.files = [];
      this.prompt = "";
      this.scrollToBottom();
    },
    handleKeyDown(event) {
      if (event.key === "Enter") {
        if (event.shiftKey) {
          // Increase the size of the textarea
          event.target.style.height = `${event.target.scrollHeight + 20}px`;
        } else {
          // Prevent the default action (inserting a newline)
          event.preventDefault();
          // Call your function to send the form
          event.target.style.height = "25px";
          this.sendGpt(event);
        }
      }
    },
    updateFiles(newFiles = []) {
      const existingFilesNames = new Set(
        this.files.map(({ file_name: name }) => name)
      );

      const updatedFiles = newFiles.filter(
        ({ file_name: name }) => !existingFilesNames.has(name)
      );

      this.files = [...this.files, ...updatedFiles];
    },
    handleConvertFlagInFile({ index, checked }) {
      this.files[index].convert = checked;
    },
    uploadFile(e) {
      const files = Array.from(e.target.files);

      const hasInvalidExtension = files.some(
        ({ name }) => !fileType.isValidExtension(fileType.getExtension(name))
      );

      if (hasInvalidExtension) {
        this.isVisibleExtensionModal = true;
        return;
      }

      const newFiles = files.map((file) => ({
        file_name: file.name,
        file_path: undefined,
        file_type: file.type,
        file,
        deleted: 0,
        convert: fileType.isExtensionToConvert(file.name),
      }));

      this.updateFiles(newFiles);
      this.$refs.fileInput.value = "";
    },
    removeFile(index) {
      this.files = this.files.filter((_, i) => i !== index);
    },
    scrollToBottom() {
      this.loadingData = true;
      if (!this.$refs.scrollContainerChat) {
        return;
      }

      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.scrollContainerChat.scrollTop =
            this.$refs.scrollContainerChat?.scrollHeight;

          this.loadingData = false;
        }, 500);
      });
    },
    getFileExtensions() {
      return fileType.extensions;
    },
  },
};
</script>

<style scoped>
.open-file-button.disabled {
  cursor: default;
  pointer-events: none;
}

.send-gpt-button.disabled {
  cursor: default;
  pointer-events: none;
}
</style>
