<template>
  <div class="container my-5">
    <h1 class="mb-4">Demo de Unified Modal</h1>

    <div class="row g-3">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h5 class="mb-0">Modales por tipo</h5>
          </div>
          <div class="card-body">
            <MDBBtn color="success" @click="showInfoModal">Informativo</MDBBtn>
            <MDBBtn color="danger" class="ms-2" @click="showErrorModal"
              >Error</MDBBtn
            >
            <MDBBtn color="warning" class="ms-2" @click="showWarningModal"
              >Advertencia</MDBBtn
            >
            <MDBBtn color="primary" class="ms-2" @click="showQuestionModal"
              >Pregunta</MDBBtn
            >
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h5 class="mb-0">Modales por variante</h5>
          </div>
          <div class="card-body">
            <MDBBtn color="info" @click="showAcceptModal">Aceptar</MDBBtn>
            <MDBBtn color="info" class="ms-2" @click="showAcceptCancelModal"
              >Aceptar/Cancelar</MDBBtn
            >
            <MDBBtn color="info" class="ms-2" @click="showYesNoModal"
              >Sí/No</MDBBtn
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Panel de log de eventos -->
    <div class="card mt-4">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <h5 class="mb-0">Log de Eventos</h5>
        <MDBBtn color="light" size="sm" @click="clearLog">Limpiar</MDBBtn>
      </div>
      <div class="card-body">
        <div class="event-log p-3 border rounded bg-light">
          <div v-if="eventLog.length === 0" class="text-muted">
            No hay eventos registrados.
          </div>
          <div v-for="(event, index) in eventLog" :key="index" class="mb-1">
            <span class="badge" :class="getEventBadgeClass(event.type)">{{
              event.type
            }}</span>
            <span class="ms-2">{{ event.message }}</span>
            <small class="text-muted ms-2">{{ event.time }}</small>
          </div>
        </div>
      </div>
    </div>

    <!-- Componentes de Modal -->
    <UnifiedModal
      v-model="showModal"
      :type="modalConfig.type"
      :variant="modalConfig.variant"
      :title="modalConfig.title"
      :message="modalConfig.message"
      @confirm="handleConfirm"
      @cancel="handleCancel"
      @close="handleClose"
    />
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import { MDBBtn } from "mdb-vue-ui-kit";

export default {
  name: "App",
  components: {
    MDBBtn,
  },
  setup() {
    const showModal = ref(false);
    const modalConfig = reactive({
      type: "info",
      variant: "accept",
      title: "",
      message: "",
    });

    const eventLog = ref([]);

    // Función para mostrar modal con configuración personalizada
    const showModalWithConfig = (config) => {
      Object.assign(modalConfig, config);
      showModal.value = true;
    };

    // Funciones para mostrar diferentes tipos de modales
    const showInfoModal = () => {
      showModalWithConfig({
        type: "info",
        variant: "accept",
        title: "Información",
        message: "La operación se ha completado correctamente.",
      });
    };

    const showErrorModal = () => {
      showModalWithConfig({
        type: "error",
        variant: "accept",
        title: "Error",
        message: "Ha ocurrido un error al procesar la solicitud.",
      });
    };

    const showWarningModal = () => {
      showModalWithConfig({
        type: "warning",
        variant: "acceptCancel",
        title: "Advertencia",
        message: "Esta acción podría tener consecuencias. ¿Desea continuar?",
      });
    };

    const showQuestionModal = () => {
      showModalWithConfig({
        type: "question",
        variant: "yesNo",
        title: "Confirmar acción",
        message: "¿Está seguro de que desea realizar esta acción?",
      });
    };

    // Funciones para mostrar diferentes variantes de modales
    const showAcceptModal = () => {
      showModalWithConfig({
        type: "info",
        variant: "accept",
        title: "Variante: Aceptar",
        message: "Modal con un solo botón de acción.",
      });
    };

    const showAcceptCancelModal = () => {
      showModalWithConfig({
        type: "info",
        variant: "acceptCancel",
        title: "Variante: Aceptar/Cancelar",
        message: "Modal con botones de Aceptar y Cancelar.",
      });
    };

    const showYesNoModal = () => {
      showModalWithConfig({
        type: "info",
        variant: "yesNo",
        title: "Variante: Sí/No",
        message: "Modal con botones de Sí y No.",
      });
    };

    // Manejadores de eventos
    const logEvent = (type, message) => {
      const now = new Date();
      const time = now.toLocaleTimeString();
      eventLog.value.unshift({ type, message, time });
    };

    const handleConfirm = () => {
      logEvent("confirm", `"${modalConfig.title}" confirmado`);
    };

    const handleCancel = () => {
      logEvent("cancel", `"${modalConfig.title}" cancelado`);
    };

    const handleClose = () => {
      logEvent("close", `"${modalConfig.title}" cerrado con X`);
    };

    const clearLog = () => {
      eventLog.value = [];
    };

    const getEventBadgeClass = (eventType) => {
      switch (eventType) {
        case "confirm":
          return "bg-success";
        case "cancel":
          return "bg-secondary";
        case "close":
          return "bg-danger";
        default:
          return "bg-primary";
      }
    };

    return {
      showModal,
      modalConfig,
      eventLog,
      showInfoModal,
      showErrorModal,
      showWarningModal,
      showQuestionModal,
      showAcceptModal,
      showAcceptCancelModal,
      showYesNoModal,
      handleConfirm,
      handleCancel,
      handleClose,
      clearLog,
      getEventBadgeClass,
    };
  },
};
</script>

<style>
.event-log {
  max-height: 200px;
  overflow-y: auto;
}
</style>
