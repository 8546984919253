<template>
  <div>
    <div
      id="contenido"
      :class="{
        'div-princ': true,
        'margin-404': true,
        'header-error': header,
      }"
    >
      <div v-if="image" :innerHTML="sanitizedImage"></div>
      <div id="error">
        <div>
          <h1 class="h1-error" v-html="langFilter(title)" />
          <p class="p-error" v-html="langFilter(body)" />
        </div>
        <div v-if="!noButton">
          <img id="vector" class="vector" :src="vector" />
        </div>
        <div v-if="!noButton">
          <div class="button-noautorizado">
            <button class="button-error" @click="btnclick()">
              {{ langFilter(vamos) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../mixins/translationMixin.js";
import DOMPurify from "dompurify";
import $ from "jquery";
import axios from "axios";

export default {
  mixins: [translationMixin],
  props: {
    image: {
      type: String,
      required: true,
    },
    vector: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
    vamos: {
      type: String,
      required: true,
    },
    header: {
      type: Boolean,
      required: true,
    },
    noButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      lang: "",
      traducciones: [
        {
          name: "404-title",
          es: "Error 404",
          en: "Error 404",
        },
        {
          name: "404-body",
          es: "Ha habido un error, clica aquí<br>para volver a la página principal.",
          en: "There has been an error, click here<br>to return to the main page.",
        },
        {
          name: "500-title",
          es: "Error 500",
          en: "Error 500",
        },
        {
          name: "500-body",
          es: "Ha habido un error, clica aquí<br>para volver a la página principal.",
          en: "There has been an error, click here<br>to return to the main page.",
        },
        {
          name: "link-redirect",
          es: process.env.VUE_APP_FRONTEND_URL,
          en: process.env.VUE_APP_FRONTEND_URL,
        },
        {
          name: "404-vamos",
          es: "¡Vamos!",
          en: "¡Let's go!",
        },
        {
          name: "permiso-title",
          es: "Whoops!",
          en: "Whoops",
        },
        {
          name: "permiso-body",
          es: "Parece que no estás autorizado<br>para acceder a la siguiente página...",
          en: "It seems you are not authorised<br>to access this page...",
        },

        {
          name: "registered-body",
          es: "Parece que el usuario ya esta registardo,<br> por favor inicia sesión para acceder",
          en: "It seems the user is already registered,<br> please login to access",
        },
        {
          name: "noSpaces-body",
          es: "Parece que no tienes ningún espacio asociado, <br> por favor contacta con tu administrador.",
          en: "It seems you have no spaces associated, <br> please contact your administrator.",
        },
        {
          name: "permiso-volver",
          es: "Volver",
          en: "Back",
        },
        {
          name: "session-title",
          es: "Tu sesión ha<br> expirado",
          en: "Your session has<br> expired",
        },
        {
          name: "session-body",
          es: "Por favor, inicia sesión nuevamente<br> para continuar.",
          en: "<br>Please log in again to continue.",
        },
        {
          name: "session-volver",
          es: "Iniciar sesión",
          en: "Log in",
        },
        {
          name: "no-programs",
          es: "Parece que no tienes proyectos asociados en este programa.",
          en: "It seems you don't have any projects associated with this program.",
        },
        {
          name: "logout",
          es: `${process.env.VUE_APP_FRONTEND_URL}/logout`,
          en: `${process.env.VUE_APP_FRONTEND_URL}/logout`,
        },
        {
          name: "maintenance-title",
          es: "Mantenimiento",
          en: "Maintenance",
        },
        {
          name: "maintenance-body",
          es: "Estamos realizando tareas de mantenimiento,<br> por favor, vuelve más tarde.",
          en: "We are performing maintenance tasks,<br> please come back later.",
        },
        {
          name: "end-trial-period-title",
          es: "Su período de prueba ha finalizado",
          en: "Your probationary period has ended",
        },
        {
          name: "end-trial-period-button",
          es: "Referencias",
          en: "References",
        },
      ],
    };
  },
  computed: {
    sanitizedImage() {
      return DOMPurify.sanitize(this.image);
    },
  },
  created() {
    $(".myProjects-SecondHeaderContainer").hide();
    $(".carga").hide();
    $(".myProjects-MainContainer").show();

    const lang = localStorage.getItem("lang");
    if (lang == null) {
      axios.get(`${process.env.VUE_APP_API_URL}/getLang`).then((response) => {
        this.lang = response.data;
        localStorage.setItem("lang", this.lang);
      });
    } else {
      this.lang = lang;
    }
  },
  methods: {
    btnclick() {
      if (this.header) {
        window.history.go(-1);
      } else {
        window.location.href = this.langFilter(this.link);
      }
    },
  },
};
</script>
