<template>
  <div class="container">
    <div class="row">
      <div class="col-6">
        <h5>{{ langFilter("assignAssistants") }}</h5>
      </div>
      <div class="col-6">
        <h5>{{ langFilter("assistantsAssigned") }}</h5>
      </div>
      <br /><br />
      <div class="col-6">
        <input
          type="text"
          placeholder="Search Assistants"
          @input="searchAssistants($event)"
        />
      </div>
      <div class="col-6"></div>
      <br /><br />
      <div class="col-6 space-div-height space-user-template-container">
        <table class="space-user-container-table">
          <tr>
            <th>{{ langFilter("name") }}</th>
            <th></th>
          </tr>
          <tr v-for="assistant in assistants" :key="assistant.id">
            <td>{{ assistant.name }}</td>
            <td>
              <button class="btn btn-success" @click="addAssistant(assistant)">
                +
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div class="col-6 space-div-height space-user-template-container">
        <table v-if="assistantsAssigned.length > 0" class="space-user-container-table">
          <tr>
            <th>Name</th>
            <th></th>
          </tr>
          <tr v-for="assistant in assistantsAssigned" :key="assistant.id">
            <td>{{ assistant.name }}</td>
            <td>
              <button
                class="btn btn-danger"
                @click="removeAssistant(assistant.id)"
              >
                -
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <br /><br />
  </div>
</template>

<script>
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";

export default {
  mixins: [translationMixin],
  inject: ["showAlert"],
  props: {
    spaceId: {
      type: [Number, null],
      required: true,
    },
  },
  emits: ["setAssistant", "removeAssistant", "addAssistant"],
  data() {
    return {
      allAssistants: [],
      assistantsAssigned: [],
      search: "",
      traducciones: [
        {
          name: "assignAssistants",
          es: "Asignar Asistentes",
          en: "Assign Assistants",
        },
        {
          name: "assistantsAssigned",
          es: "Asistentes Asignados",
          en: "Assistants Assigned",
        },
        {
          name: "name",
          es: "Nombre",
          en: "Name",
        },
        {
          name: "error",
          es: "Error",
          en: "Error",
        },
        {
          name: "errorLoadingAssistantsForSpace",
          es: "Error al cargar los asistentes para este espacio",
          en: "Error loading assistants for this space",
        },
        {
          name: "errorLoadingAvailableAssistants",
          es: "Error al cargar los asistentes disponibles",
          en: "Error loading available assistants",
        },
        {
          name: "unknownError",
          es: "Error desconocido",
          en: "Unknown error",
        },
      ],
    };
  },
  computed: {
    assistants() {
      return this.allAssistants.filter((assistant) => {
        return (
          assistant.name?.toLowerCase().includes(this.search.toLowerCase()) &&
          !this.assistantsAssigned.some(
            (assigned) => assigned.id === assistant.id
          )
        );
      });
    },
  },
  created() {
    this.getAllAssistants();
  },
  methods: {
    async getAllAssistants() {
      if (this.allAssistants.length === 0) {
        try {
          const response = await axios.get(
            `${process.env.VUE_APP_API_URL}/assistant/getAllAssistants`
          );

          this.allAssistants = response.data;

          if (this.spaceId !== null) {
            try {
              const spaceResponse = await axios.get(
                `${process.env.VUE_APP_API_URL}/manageSpaces-acc/getAssistantsBySpace/${this.spaceId}`
              );

              this.assistantsAssigned = spaceResponse.data.assistants || [];
              this.$emit("addAssistant", this.assistantsAssigned);
            } catch (spaceError) {
              console.error(
                `${this.langFilter("errorLoadingAssistantsForSpace")}: `,
                spaceError
              );
              this.showAlert({
                title: this.langFilter("error"),
                text: `${this.langFilter("errorLoadingAssistantsForSpace")}`,
                successful: false,
              });
            }
          }
        } catch (error) {
          console.error(
            `${this.langFilter("errorLoadingAvailableAssistants")}: `,
            error
          );
          this.showAlert({
            title: this.langFilter("error"),
            text: `${this.langFilter("errorLoadingAvailableAssistants")}: ${error.message || this.langFilter("unknownError")}`,
            successful: false,
          });
        }
      }
    },

    searchAssistants(event) {
      this.search = event.target.value.toLowerCase();
    },

    addAssistant(assistant) {
      this.assistantsAssigned.push(assistant);
      this.allAssistants = this.allAssistants.filter(
        (a) => a.id !== assistant.id
      );
      this.$emit("addAssistant", this.assistantsAssigned);
    },

    removeAssistant(assistantId) {
      const removed = this.assistantsAssigned.find((a) => a.id === assistantId);
      if (removed) {
        this.allAssistants.push(removed);
        this.assistantsAssigned = this.assistantsAssigned.filter(
          (a) => a.id !== assistantId
        );
        this.$emit("removeAssistant", assistantId);
      }
    },

    changeSelectedRole(event, index, selected) {
      // Optional role assignment example
      if (!selected) {
        // Since assistants is now computed, we need a different approach
        const assistantId = this.assistants[index].id;
        const assistantIndex = this.allAssistants.findIndex(
          (a) => a.id === assistantId
        );
        if (assistantIndex !== -1) {
          this.allAssistants[assistantIndex].role = event.target.value;
        }
      } else {
        this.assistantsAssigned[index].role = event.target.value;
      }
    },
  },
};
</script>
