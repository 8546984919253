<template>
  <div>
    <header-general src="/img/icons/admin.svg" title="Admin panel" />

    <!-- <div>
            <p class="admin-panel-nav">
            <span class="admin-panel-nav-option" :class="[{'panel-selected' : screen_id==1}]" @click="screen_id=1">Users</span>
            <span class="admin-panel-nav-option" :class="[{'panel-selected' : screen_id==2}]" @click="screen_id=2">Permissions</span>
            </p>
        </div> -->

    <div class="admin_panel-margin" v-if="!spaceConfig.isTrialFinished">
      <admin-screens :lang="lang" />
      <admin-users v-if="screen_id === 1" :space="space" :lang="lang" />
      <admin-permissions
        v-else-if="screen_id === 2"
        :space="space"
        :lang="lang"
      />
    </div>
  </div>
</template>

<script>
export default {
  inject: ["spaceConfig"],
  props: {
    space: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      screen_id: 1,
    };
  },
};
</script>
