<template>
  <div class="container">
    <div class="row">
      <div class="col-6">
        <h5>{{ langFilter("assignTemplates") }}</h5>
      </div>
      <div class="col-6">
        <h5>{{ langFilter("assignTemplates") }}</h5>
      </div>
      <br /><br />
      <div class="col-6">
        <input
          type="text"
          placeholder="Search Templates"
          @input="searchTemplates($event)"
        />
      </div>
      <div class="col-6" ></div>
      <br /><br />
      <div class="col-6 space-div-height space-user-template-container">
        <table class="space-user-container-table">
          <tr>
            <th>{{ langFilter("name") }}</th>
            <th></th>
          </tr>
          <tr v-for="template in templates" :key="template.id">
            <td>{{ template.name }}</td>
            <td>
              <button class="btn btn-success" @click="addTemplate(template)">
                +
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div class="col-6 space-div-height space-user-template-container">
        <table v-if="templatesAssigned.length > 0" class="space-user-container-table">
          <tr>
            <th>{{ langFilter("name") }}</th>
            <th></th>
          </tr>
          <tr v-for="template in templatesAssigned" :key="template.id">
            <td>{{ template.name }}</td>
            <td>
              <button
                class="btn btn-danger"
                @click="removeTemplate(template.id)"
              >
                -
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <br /><br />
  </div>
</template>

<script>
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  props: {
    spaceId: {
      type: [Number, null],
      required: true,
    },
  },
  emits: ["addTemplate", "removeTemplate"],
  data() {
    return {
      templates: [],
      templatesAssigned: [],
      search: "",
      allTemplates: [],
      traducciones: [
        {
          name: "assignTemplates",
          es: "Asignar Plantillas",
          en: "Assign Templates",
        },
      ],
    };
  },
  created() {
    this.getAllTemplates();
  },
  methods: {
    getAllTemplates() {
      this.addingTemplates = !this.addingTemplates;
      if (this.templates.length === 0) {
        this.fetchAllTemplates()
          .then(() => {
            if (this.spaceId !== null) {
              return this.fetchTemplatesAssignedToSpace();
            }
          })
          .catch((error) => {
            console.error("Error fetching templates:", error);
          });
      }
    },

    async fetchAllTemplates() {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/template/getAllTemplates`
      );
      this.templates = response.data;
      this.allTemplates = response.data;
      return response;
    },

    async fetchTemplatesAssignedToSpace() {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/manageSpaces-acc/getTemplatesBySpace/${this.spaceId}`
      );
      this.templatesAssigned = response.data.templates;
      this.filterOutAssignedTemplates();
      this.$emit("addTemplate", this.templatesAssigned);
      return response;
    },

    filterOutAssignedTemplates() {
      this.templates = this.templates.filter((template) => {
        return !this.templatesAssigned.some(
          (templateAssigned) => template.id === templateAssigned.id
        );
      });
    },

    searchTemplates(event) {
      const search = event.target.value;
      this.search = search.toLowerCase();

      //filter templates by search and ensure they are not in templatesAssigned
      this.templates = this.allTemplates.filter((template) => {
        if (template.name != null) {
          return (
            template.name.toLowerCase().includes(this.search) &&
            !this.templatesAssigned.some(
              (assignedTemplate) => assignedTemplate.name === template.name
            )
          );
        }
      });
    },

    addTemplate(template) {
      this.templatesAssigned.push(template);
      this.templates = this.templates.filter((t) => t.id != template.id);
      this.allTemplates = this.allTemplates.filter((t) => t.id != template.id);
      this.$emit("addTemplate", this.templatesAssigned);
    },

    removeTemplate(template_id) {
      this.templates.push(
        this.templatesAssigned.find((t) => t.id == template_id)
      );
      this.allTemplates.push(
        this.templatesAssigned.find((t) => t.id == template_id)
      );
      this.templatesAssigned = this.templatesAssigned.filter(
        (t) => t.id != template_id
      );
      this.$emit("removeTemplate", template_id);
    },

    changeSelectedRole(event, index, selected) {
      if (!selected) {
        this.templates[index].role = event.target.value;
      } else {
        this.templatesAssigned[index].role = event.target.value;
      }
    },
  },
};
</script>
