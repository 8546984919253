<!-- ModalConfirm.vue -->
<template>
  <MDBModal
    ref="modalRef"
    v-model="internalValue"
    :header="title"
    :staticBackdrop="true"
    @keyup.esc="emitCancel"
    @keyup.enter="emitConfirm"
  >
    <MDBModalHeader :class="`modal-header-${type}`">
      <MDBModalTitle :id="modalTitle">{{ title }}</MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody class="acceleralia__modal-body">{{ message }}</MDBModalBody>
    <MDBModalFooter class="acceleralia__modal-footer">
      <MDBBtn color="secondary" @click="emitCancel">
        {{ variant === "yesNo" ? "No" : langFilter("cancel") }}
      </MDBBtn>
      <MDBBtn color="primary" @click="emitConfirm">
        {{ variant === "yesNo" ? langFilter("yes") : langFilter("accept") }}
      </MDBBtn>
    </MDBModalFooter>
  </MDBModal>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import { ref, watch, nextTick } from "vue";
import {
  MDBModal,
  MDBModalTitle,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
} from "mdb-vue-ui-kit";

export default {
  name: "ModalConfirm",
  components: {
    MDBModal,
    MDBModalTitle,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn,
  },
  mixins: [translationMixin],
  // This tells Vue you expect v-model usage with "modelValue"
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "accept",
    },
  },
  emits: ["update:modelValue", "cancel", "confirm"],
  setup(props, { emit }) {
    const modalRef = ref(null);
    // internalValue is what we actually bind to the <MDBModal v-model="...">
    const internalValue = ref(props.modelValue);

    // Whenever the parent updates "modelValue", reflect that change
    watch(
      () => props.modelValue,
      async (newVal) => {
        internalValue.value = newVal;
        if (newVal && modalRef.value) {
          await nextTick();
          if (modalRef.value.$el) {
            modalRef.value.$el.focus();
          }
        }
      }
    );

    // Whenever the child's internalValue changes (e.g. user closes modal),
    // emit it back up to the parent so the parent can stay in sync.
    watch(internalValue, (newVal) => {
      emit("update:modelValue", newVal);
    });

    function emitCancel() {
      emit("cancel");
      internalValue.value = false;
    }

    function emitConfirm() {
      emit("confirm");
      internalValue.value = false;
    }

    return {
      modalRef,
      internalValue,
      emitCancel,
      emitConfirm,
    };
  },
  data() {
    return {
      traducciones: [
        {
          name: "yes",
          es: "Sí",
          en: "Yes",
        },
        {
          name: "accept",
          es: "Aceptar",
          en: "Accept",
        },
        {
          name: "cancel",
          es: "Cancelar",
          en: "Cancel",
        },
      ],
    };
  },
};
</script>
