<template>
  <Templated
    v-if="!spaceConfig.isTrialFinished"
    :lang="lang"
    :role="role"
    :space="spaces[0]"
    @open-alert="openAlert($event)"
  />
</template>

<script setup>
import Templated from "../components/track/TemplateComponent.vue";
</script>

<script>
export default {
  inject: ["spaceConfig"],
  props: {
    spaces: {
      type: Array,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  emits: ["open-alert"],
  methods: {
    openAlert(message) {
      this.$emit("open-alert", message);
    },
  },
};
</script>

<style>
@import "../../src/assets/css/track/track.css";
@import "../../src/assets/css/track/new_track.css";
@import "../../src/assets/css/confirmation.css";
@import "../../src/assets/css/track/templates.css";
</style>
