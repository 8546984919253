<template>
  <div>
    <div v-if="option === 1">
      <p>{{ langFilter("searchSpace") }}</p>
      <div class="manage-space-container-select-container select-all-space">
        <select @change="changeSpace($event)">
          <option value="" disabled selected>
            {{ langFilter("selectSpace") }}
          </option>
          <option
            v-for="(space, spaceIndex) in spaces"
            :key="spaceIndex"
            :value="spaceIndex"
          >
            {{ space.name }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="option === 0 || (option === 1 && spaceSelected)">
      <p>{{ langFilter("spaceName") }}</p>
      <input
        v-model="spaceName"
        :placeholder="langFilter('spaceName')"
        type="text"
      />
      <br /><br />
      <p>{{ langFilter("img") }}</p>
      <div class="upload-space-file">
        <img
          v-if="previewUrl || spaceSelected"
          :src="
            previewUrl ? previewUrl : spaceSelected ? spaceSelected.img : ''
          "
          alt="Space Preview"
        />
        <div v-if="fileName">{{ fileName }}</div>
        <label class="upload-button-space">
          {{ langFilter(buttonText) }}
          <input
            :key="fileInputKey"
            style="display: none"
            accept="image/x-png,image/gif,image/jpeg"
            multiple
            type="file"
            @change="handleFileChange"
          />
        </label>
      </div>
      <br /><br />
      <div v-if="addingUsers">
        <user-table-space
          :spaceId="spaceSelected ? spaceSelected.id : null"
          @add-user="addUser($event)"
          @remove-user="removeUser($event)"
        />
      </div>
      <button class="btn btn-secondary" @click="addingUsers = !addingUsers">
        {{ langFilter(getUserButtonText()) }}
      </button>
      <br /><br />
      <div v-if="addingTemplates">
        <template-table-space
          :spaceId="spaceSelected ? spaceSelected.id : null"
          @add-template="addTemplate($event)"
          @remove-template="removeTemplate($event)"
        />
      </div>
      <button class="btn btn-secondary" @click="setAddingTemplates">
        {{ langFilter(getTemplateButtonText()) }}
      </button>
      <br /><br />
      <div v-if="addingAssistants">
        <assistant-table-space
          :spaceId="spaceSelected ? spaceSelected.id : null"
          @set-assistant="setAssistant"
          @remove-assistant="removeAssistant"
        />
      </div>
      <button
        class="btn btn-secondary"
        @click="addingAssistants = !addingAssistants"
      >
        {{ langFilter(getAssistantButtonText()) }}
      </button>
      <br /><br />
      <button class="btn btn-primary" @click="createUpdateSpace">
        {{ langFilter(option === 0 ? "create" : "update") }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  inject: ["showAlert"],
  props: {
    option: {
      type: Number,
      required: true,
    },
  },
  emits: ["msg"],
  data() {
    return {
      spaceName: "",
      spaces: [],
      spaceSelected: null,
      file: null,
      files: [],
      users: [],
      templates: [],
      assistants: [],
      fileInputKey: Date.now(),
      addingUsers: false,
      addingTemplates: false,
      addingAssistants: false,
      fileName: "",
      buttonText: "uploadSpaceImage",
      traducciones: [
        {
          name: "create",
          es: "Crear",
          en: "Create",
        },
        {
          name: "update",
          es: "Modificar",
          en: "Update",
        },
        {
          name: "uploadSpaceImage",
          es: "Subir Imagen del Espacio",
          en: "Upload Space Image",
        },
        {
          name: "addUsers",
          es: "Añadir Usuarios",
          en: "Add Users",
        },
        {
          name: "updateUsers",
          es: "Actualizar Usuarios",
          en: "Update Users",
        },
        {
          name: "cancelUsers",
          es: "Cancelar Modificación de Usuarios",
          en: "Cancel User Modification",
        },
        {
          name: "addTemplates",
          es: "Añadir Plantillas",
          en: "Add Templates",
        },
        {
          name: "updateTemplates",
          es: "Actualizar Plantillas",
          en: "Update Templates",
        },
        {
          name: "cancelTemplates",
          es: "Cancelar Modificación de Plantillas",
          en: "Cancel Template Modification",
        },
        {
          name: "addAssistants",
          es: "Añadir Asistentes",
          en: "Add Assistants",
        },
        {
          name: "updateAssistants",
          es: "Actualizar Asistentes",
          en: "Update Assistants",
        },
        {
          name: "cancelAssistants",
          es: "Cancelar Modificación de Asistentes",
          en: "Cancel Assistant Modification",
        },
        {
          name: "uploadSpaceImage",
          es: "Subir Imagen del Espacio",
          en: "Upload Space Image",
        },
        {
          name: "spaceName",
          es: "Nombre del Espacio",
          en: "Space Name",
        },
        {
          name: "img",
          es: "Imagen",
          en: "Image",
        },
        {
          name: "searchSpace",
          es: "Buscar Espacio",
          en: "Search Space",
        },
        {
          name: "selectSpace",
          es: "Seleccionar Espacio",
          en: "Select Space",
        },
        {
          name: "changeImage",
          es: "Cambiar Imagen",
          en: "Change Image",
        },
        {
          name: "spaceCreated",
          es: "Espacio Creado",
          en: "Space Created",
        },
        {
          name: "spaceUpdated",
          es: "Espacio Actualizado",
          en: "Space Updated",
        },
        {
          name: "pleaseSelectSpaceImage",
          es: "Por favor seleccione una imagen para el espacio",
          en: "Please select an image for the space",
        },
        {
          name: "pleaseEnterSpaceName",
          es: "Por favor ingrese un nombre para el espacio",
          en: "Please enter a name for the space",
        },
        {
          name: "errorCreatingSpace",
          es: "Error al crear espacio",
          en: "Error creating space",
        },
        {
          name: "errorUpdatingSpace",
          es: "Error al actualizar el espacio",
          en: "Error updating the space",
        },
      ],
    };
  },
  computed: {
    previewUrl() {
      if (this.file && this.file instanceof File) {
        return URL.createObjectURL(this.file);
      } else if (this.spaceSelected && this.spaceSelected.img) {
        return this.spaceSelected.img;
      }
      return "";
    },
  },
  watch: {
    option(newValue) {
      this.resetData();
      if (newValue === 1) {
        this.getAllSpaces();
      }
    },
  },
  methods: {
    resetData() {
      this.spaceName = "";
      this.spaces = [];
      this.spaceSelected = null;
      this.file = null;
      this.files = [];
      this.users = [];
      this.templates = [];
      this.fileInputKey = Date.now();
      this.addingUsers = false;
      this.addingTemplates = false;
      this.fileName = "";
      this.buttonText = "uploadSpaceImage";
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.file = file;
        this.fileName = file.name;
        this.buttonText = "ChangeImage";
      }
    },
    async getAllSpaces() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/manageSpaces-acc/getSpaces`
        );
        this.spaces = response.data;
        return response.data;
      } catch (error) {
        console.error("Error fetching spaces:", error);
        this.showAlert({
          title: this.langFilter("error"),
          text: `${this.langFilter("failedToLoadSpaces")}: ${error.message || this.langFilter("unknownError")}`,
          successful: false,
        });
        return [];
      }
    },
    getFile(event) {
      this.file = event.target.files[0];
    },
    createUpdateSpace() {
      if (this.spaceName === "") {
        this.showAlert({
          title: this.langFilter("error"),
          text: this.langFilter("pleaseEnterSpaceName"),
          successful: false,
        });
        return;
      }
      if (this.option === 0 && this.file == null) {
        this.showAlert({
          title: this.langFilter("error"),
          text: this.langFilter("pleaseSelectSpaceImage"),
          successful: false,
        });
        return;
      }
      const formData = new FormData();
      formData.append("space_name", this.spaceName);
      if (this.option === 0 || (this.option === 1 && this.file)) {
        formData.append("file", this.file);
      }
      formData.append("users", JSON.stringify(this.users));
      formData.append(
        "templates",
        JSON.stringify(this.templates.map((template) => template.id))
      );
      formData.append("assistants", JSON.stringify(this.assistants));
      if (this.option === 1) {
        formData.append("space_id", this.spaceSelected.id);
      }
      const url =
        this.option === 0
          ? `${process.env.VUE_APP_API_URL}/manageSpaces-acc/createSpace`
          : `${process.env.VUE_APP_API_URL}/manageSpaces-acc/updateSpace`;
      axios
        .post(url, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.status === 200) {
            const msg = this.option === 0 ? "spaceCreated" : "spaceUpdated";
            this.showAlert({
              title: this.langFilter(msg),
              text: "",
              successful: true,
            });
            if (this.option === 0) {
              this.resetData();
            } else {
              const currentSpaceId = this.spaceSelected.id;
              this.getAllSpaces().then(() => {
                const updatedSpace = this.spaces.find(
                  (s) => s.id === currentSpaceId
                );
                if (updatedSpace) {
                  this.spaceSelected = updatedSpace;
                  this.spaceName = updatedSpace.name;
                }
                this.file = null;
                this.fileName = "";
                this.buttonText = "Upload Space Image";
                this.addingUsers = false;
                this.users = [];
                this.addingTemplates = false;
                this.templates = [];
                this.fileInputKey = Date.now();
              });
            }
          } else {
            this.showAlert({
              title: this.langFilter(
                this.option === 0 ? "errorCreatingSpace" : "errorUpdatingSpace"
              ),
              successful: false,
            });
          }
        })
        .catch(() => {
          this.showAlert({
            title: this.langFilter(
              this.option === 0 ? "errorCreatingSpace" : "errorUpdatingSpace"
            ),
            successful: false,
          });
        });
    },
    getUserButtonText() {
      if (this.addingUsers) {
        return "cancelUsers";
      }
      return this.option === 0 ? "addUsers" : "updateUsers";
    },
    getTemplateButtonText() {
      if (this.addingTemplates) {
        return "Cancel Templates";
      }
      return this.option === 0 ? "addTemplates" : "updateTemplates";
    },
    getAssistantButtonText() {
      if (this.addingAssistants) {
        return "CancelAssistants";
      } else {
        return this.option === 0 ? "addAssistants" : "updateAssistants";
      }
    },
    setAssistant(newAssistants) {
      this.assistants = newAssistants;
    },
    removeAssistant(assistantId) {
      this.assistants = this.assistants.filter((a) => a.id !== assistantId);
    },
    addUser(newUsers) {
      this.users = newUsers;
    },
    addTemplate(templateEmit) {
      const newTemplates = templateEmit.map((template) => ({
        id: template.id,
        name: template.name,
      }));
      this.templates = newTemplates;
    },
    removeTemplate(template_id) {
      this.templates = this.templates.filter((t) => t.id !== template_id);
    },
    setAddingTemplates() {
      this.addingTemplates = !this.addingTemplates;
    },
    removeUser(user) {
      this.users = this.users.filter((u) => u.id !== user);
    },
    changeSpace(event) {
      this.spaceSelected = this.spaces[event.target.value];
      this.spaceName = this.spaceSelected.name;
      this.file = null;
      this.addingUsers = false;
      this.addingTemplates = false;
      this.fileInputKey = Date.now();
    },
  },
};
</script>
