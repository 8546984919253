<template>
  <header-general
    :title="langFilter('assistants')"
    src="/img/icons/icon-robot-wink.svg"
  />
  <div class="templates-MainContainer">
    <div class="templates-tools-container">
      <div class="viewSelector">
        <div
          class="viewSelector-templates"
          :class="{ active: page == 0 }"
          @click="page = 0"
        >
          {{ langFilter("expert") }}
        </div>
        <img
          class="viewSelector-separator"
          :src="require('@/assets/img/projects/Selector-separator.svg')"
        />
        <div
          class="viewSelector-drafts"
          :class="{ active: page == 1 }"
          @click="page = 1"
        >
          {{ langFilter("evaluator") }}
        </div>
      
        <div
          class="myProjects-NewProjectButtonIcon"
          :class="{'myProjects-NewProjectButtonIcon-disabled': isDisabled()}"
          :title="isDisabled() ? langFilter('create_new_assistant_permission') : langFilter('create_new_assistant')"
          @click="goToNewAssistant"
        >
          <img
            class="myProjects-NewProjectIcon"
            :src="require('@/assets/img/projects/new_project.svg')"
          />
        </div>
        <div id="searchbar-templates" class="tool-search-templates" :class="searchBarClass">
          <img
            class="tool-image-search-templates"
            :src="require('@/assets/img/projects/search-btn.svg')"
            @click="focusSearchBar"
          />

          <input
            id="template-search"
            v-model="filters.name"
            class="tool-search-templates-input"
            :placeholder="langFilter('search-assistant')"
            name="searchbar-templates"
            type="text"
            @focus="search_bar(true)"
            @blur="search_bar(false)"
            ref="templateSearch"
          />
        </div>
      </div>
    </div>

    <div v-if="loading">
      <img
        class="loading-rocket"
        :src="require('@/assets/img/general/loading.gif')"
      />
    </div>
    <div v-else-if="assistantTypes.length === 0" class="col-12">
      <p>{{ langFilter("assistant_not_created") }}</p>
    </div>
    <div v-for="type in filteredAssistantTypes" v-else :key="type.id" class="mb-4">
      <div
        class="templates-categoryContainer"
      >
        <div class="templates-categoryContainer-title">
          {{ langFilter(type.name) }}
        </div>
      </div>
      <AssistantsList
        :lang="lang"
        :assistList="filteredAssistants[type.id]"
        :onFetchAssistants="fetchAssistants"
      />
    </div>
  </div>
</template>

<script>
import AssistantsList from "@/components/assistants/AssistantsList.vue";
import axios from "axios";
import translationMixin from "@/mixins/translationMixin";
import translations from "../components/assistants/translate/AssistantTranslate.json";

export default {
  components: {
    AssistantsList,
  },
  mixins: [translationMixin],
  props: {
    spaces: {
      type: Array,
      required: true,
    },
    role: {
      type: Number,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    homeNotifications: {
      type: Number,
      required: true,
    },
  },
  emits: ["openAlert"],
  data() {
    return {
      page: 0,
      loading: false,
      assistList: [],
      traducciones: translations,
      filters: {
        name: "",
      },
      searchBarExpanded: false,
    };
  },
  computed: {
    assistantTypes() {
      const types = {};
      this.assistList.forEach((assistant) => {
        const { id, name } = assistant.assistant_type;
        if (!types[id]) {
          types[id] = { id, name };
        }
      });
      return Object.values(types);
    },
    filteredAssistants() {
      const result = {};
      this.assistList.forEach((assistant) => {
        const typeId = assistant.assistant_type.id;
        if (!result[typeId]) {
          result[typeId] = [];
        }
        if (assistant.name.toLowerCase().includes(this.filters.name.toLowerCase())) {
          result[typeId].push(assistant);
        }
      });
      return result;
    },
    filteredAssistantTypes() {
      return this.assistantTypes.filter(t => (this.page === 0 && t.name === 'expert') || (this.page === 1 && t.name === 'evaluator'));
    },
    searchBarClass() {
      return {
        'search-big-templates': this.searchBarExpanded,
      };
    },
  },
  mounted() {
    this.fetchAssistants();
  },
  methods: {
    search_bar(show) {
      this.searchBarExpanded = show;
    },
    focusSearchBar() {
      this.$ref.templateSearch.focus();
    },
    fetchAssistants() {
      this.loading = true;
      axios
        .get(`${process.env.VUE_APP_API_URL}/assistants`)
        .then((response) => {
          this.assistList = response.data;
        })
        .catch((error) => {
          console.error("Error trayendo asistentes:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goToNewAssistant() {
      this.$router.push("/new-assistant");
    },
    isDisabled() {
      if (this.spaces.length > 0) {
        return this.spaces[0]?.is_trial && this.spaces[0]?.is_trial_active;
      }

      return false;
    }
  },
};
</script>

<style lang="css" scoped>
  .myProjects-NewProjectButtonIcon-disabled {
    opacity: 0.5;
  }
  .myProjects-NewProjectButtonIcon-disabled:active {
    pointer-events: none;
  }
  .myProjects-NewProjectButtonIcon-disabled:hover {
    cursor: not-allowed;
  }
</style>