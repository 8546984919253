<template>
  <div>
    <p>{{ langFilter("searchSpace") }}</p>
    <div class="manage-space-container-select-container select-all-space">
      <select @change="changeSpace($event)">
        <option
          v-for="(space, spaceIndex) in spaces"
          :key="spaceIndex"
          :value="space.id"
        >
          {{ space.name }}
        </option>
      </select>
    </div>
    <br /><br />
    <div v-if="sure">
      <p>{{ langFilter("sureDeleteSpace") }}</p>
      <div class="delete-space-div">
        <button type="submit" class="btn btn-info" @click="deleteSpace">
          {{ langFilter("yes") }}
        </button>
        <button type="button" class="btn btn-info" @click="sure = false">
          {{ langFilter("no") }}
        </button>
      </div>
      <br /><br />
    </div>
    <button class="btn btn-danger" @click="sure = true">Delete</button>
  </div>
</template>

<script>
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  inject: ["showAlert"],
  emits: ["msg"],
  data() {
    return {
      spaces: [],
      spaceSelected_id: "",
      sure: false,
      traducciones: [
        {
          name: "create",
          es: "Crear",
          en: "Create",
        },
        {
          name: "delete",
          es: "Eliminar",
          en: "Delete",
        },
        {
          name: "sureDeleteSpace",
          es: "¿Estás seguro de que quieres eliminar este espacio?",
          en: "Are you sure you want to delete this space?",
        },
        {
          name: "yes",
          es: "Si",
          en: "Yes",
        },
        {
          name: "no",
          es: "No",
          en: "No",
        },
        {
          name: "searchSpace",
          es: "Buscar espacio",
          en: "Search space",
        },
        {
          name: "spaceDeleted",
          es: "Espacio Eliminado",
          en: "Space Deleted",
        },
        {
          name: "errorDeletingSpace",
          es: "Error al eliminar espacio",
          en: "Error deleting space",
        },
        {
          name: "errorCreatingSpace",
          es: "Error al crear espacio",
          en: "Error creating space",
        },
      ],
    };
  },
  created() {
    axios
      .get(`${process.env.VUE_APP_API_URL}/manageSpaces-acc/getSpaces`)
      .then((response) => {
        this.spaces = response.data;
        if (this.spaces.length > 0) {
          this.spaceSelected_id = this.spaces[0].id;
        }
      })
      .catch((error) => {
        console.error("Error fetching spaces:", error);
        this.$emit("msg", "Error loading spaces");
      });
  },
  methods: {
    changeSpace(event) {
      this.spaceSelected_id = event.target.value;
    },
    deleteSpace() {
      axios
        .delete(`${process.env.VUE_APP_API_URL}/manageSpaces-acc/deleteSpace`, {
          params: {
            id: this.spaceSelected_id,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.$emit("msg", this.langFilter("spaceDeleted"));
            this.showAlert({
              title: this.langFilter("spaceDeleted"),
              successful: true,
            });
            this.sure = false;
            //delete space from spaces array
            this.spaces = this.spaces.filter(
              (space) => space.id != this.spaceSelected_id
            );
            //set spaceSelected_id to first space in array
            this.spaceSelected_id = this.spaces[0].id;
          } else {
            this.showAlert({
              title: this.langFilter("errorDeletingSpace"),
              successful: false,
            });
          }
        })
        .catch(function () {
          this.showAlert({
            title: this.langFilter("errorDeletingSpace"),
            successful: false,
          });
        });
      this.sure = false;
    },
  },
};
</script>
