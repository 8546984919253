<template>
  <div
    id="tongue_modal"
    class="tongue-modal"
    style="z-index: 1000"
  >
    <router-link :to="goToEditProfile()" >
      <div
        class="div-notifications-modal"
        :class="{'tongue-modal-disabled': isDisabled()}"
        style="z-index: 10002"
        :title="isDisabled() ? langFilter('option_disabled') : langFilter('my_profile')"
        @click="$emit('closeTongue')"
        >
        <img
          :src="require('@/assets/img/tongue/perfil.svg')"
          alt="my profile"
        />
        <span class="notification-txt-modal">{{
          langFilter("my_profile")
        }}</span>
      </div>
    </router-link>
    <div class="div-notifications-modal" @click="logout">
      <img :src="require('@/assets/img/tongue/logout.svg')" alt="log out" />
      <span class="notification-txt-modal">{{ langFilter("log_out") }}</span>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import Cookies from "js-cookie";
export default {
  mixins: [translationMixin],
  inject: ["spaceConfig"],
  props: {
    lang: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ["closeTongue"],
  data() {
    return {
      traducciones: [
        {
          name: "my_profile",
          es: "Mi perfil",
          en: "My profile",
        },
        {
          name: "log_out",
          es: "Cerrar sesión",
          en: "Log out",
        },
        {
          name: "option_disabled",
          es: "Deshabilitado",
          en: "Disabled",
        },
      ],
    };
  },
  methods: {
    goToEditProfile() {
      return "/edit-profile";
    },
    logout() {
      Cookies.remove("token");
      window.location.href = "/";
    },
    isDisabled() {
      return this.spaceConfig.isTrialFinished;
    },
  },
};
</script>

<style lang="css" scoped>
  .tongue-modal-disabled {
    opacity: 0.5;
  }

  .tongue-modal-disabled:active {
    pointer-events: none;
  }

  .tongue-modal-disabled:hover {
    cursor: not-allowed;
  }
</style>