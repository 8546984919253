<script setup>
import Programs from "../components/program/ProgramComponent.vue";
</script>

<script>
export default {
  inject: ["spaceConfig"],
  props: {
    spaces: {
      type: Array,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // Traducción
      traducciones: [
        {
          name: "Program",
          es: "Programa",
          en: "Program",
        },
        {
          name: "Project",
          es: "Proyecto",
          en: "Project",
        },
        {
          name: "Entrepreneur",
          es: "Emprendedor",
          en: "Entrepreneur",
        },
        {
          name: "Tutor",
          es: "Tutor",
          en: "Tutor",
        },
        {
          name: "Mentor",
          es: "Mentor",
          en: "Mentor",
        },
        {
          name: "Quote",
          es: "Citar",
          en: "Quote",
        },
      ],
    };
  },
  methods: {},
};
</script>

<template>
  <Programs v-if="!spaceConfig.isTrialFinished" ref="programs" :space="spaces[0]" :lang="lang" :create="role" />
</template>

<style>
@import "../../src/assets/css/projects/myProjects.css";
@import "../../src/assets/css/program/program.css";
</style>
