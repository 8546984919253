import axios from "axios";
import { Modal } from "bootstrap";
import Cookies from "js-cookie";

export default {
  inject: ["spaceConfig"],
  methods: {
    getValidUrl(url) {
      let newUrl = window.decodeURIComponent(url);
      newUrl = newUrl.trim().replace(/\s/g, "");

      if (/^(:\/\/)/.test(newUrl)) {
        return `http${newUrl}`;
      }
      if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `http://${newUrl}`;
      }
      return newUrl;
    },
    //Tracks
    getAllTracksCategories() {
      const url = `${process.env.VUE_APP_API_URL}/tracks/getAllTracksCategory`;

      if (!this.spaceConfig.hasPermission(url)) {
        return;
      }

      axios
        .post(url, {
          lang: this.lang,
        })
        .then((response) => {
          this.categories = response.data;
        });
    },
    getAllTracks() {
      const url = `${process.env.VUE_APP_API_URL}/tracks/getAllTracks`;

      if (!this.spaceConfig.hasPermission(url)) {
        return;
      }

      axios
        .post(url, {
          lang: this.lang,
          space_id: this.spaces[0].id,
        })
        .then((response) => {
          this.bestTracks = response.data
            .sort((a, b) => b.rating - a.rating)
            .slice(0, 10);
          this.allTracks = response.data.sort((a, b) => a.id - b.id);
        });
    },
    closeModal({ elementId }) {
      const myModal = Modal.getOrCreateInstance(
        document.getElementById(elementId)
      );
      myModal.hide();
    },
    getAuthorizationHeader() {
      const requestHeaders = {
        Authorization: "Bearer " + Cookies.get("token"),
      };

      return requestHeaders;
    },
  },
};
