<template>
  <!-- Button trigger modal -->
  <button
    type="button"
    class="btn btn-primary"
    data-mdb-ripple-init
    data-mdb-modal-init
    data-mdb-target="#exampleModal"
  >
    Launch demo modal
  </button>

  <!-- Modal -->
  <div
    id="exampleModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLabel" class="modal-title">Modal title</h5>
          <button
            type="button"
            class="btn-close"
            data-mdb-ripple-init
            data-mdb-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-mdb-ripple-init
            data-mdb-dismiss="modal"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary" data-mdb-ripple-init>
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal, Ripple, initMDB } from "mdb-ui-kit";

export default {
  name: "PopupModal",
  mounted() {
    initMDB({ Modal, Ripple });
  },
};
</script>
