<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <div class="col-md-3 col-sm-4 justify-right">
    <open-btn :opened="opened" @setOpened="opened = !opened" />
    <button v-if="editing" class="itinerary-add-btn" @click="addPart(i)">
      +
    </button>
    <div class="itinerary-part-content">
    </div>
  </div>
  <div class="col-md-7 col-sm-6 itinerary-part-content">
    <input
      v-if="editing"
      :class="`itinerary-input-theme ${
        showError && part.title === '' ? 'itinerary-user-error-input' : ''
      }`"
      :value="part.title"
      type="text"
      @input="editName(i, $event.target.value)"
    />
    <h5 v-else>{{ part.title }}</h5>
  </div>
  <div :class="`col-2 ${editing ? 'itinerary-options' : 'justify-center'}`">
    <img
      v-if="editing"
      class="delete-icon"
      :src="require(`@/assets/img/projects/delete.svg`)"
      alt="delete"
      @click="removePart(i)"
    />
    <button
      v-else
      class="itinerary-see-btn"
      :class="{ disabled: !hasNeccesaryData }"
      @click="goToAnswers()"
    >
      {{ langFilter("see") }}
    </button>
  </div>
  <template v-if="opened">
    <itinerary-question
      v-for="(question, j) in part.questions"
      :key="j"
      :i="j"
      :lang="lang"
      :editing="editing"
      :question="question"
      :showError="showError"
      @removeQuestion="removeQuestion($event)"
      @editQuestionName="editQuestionName($event)"
      @editQuestion="editQuestion($event)"
      @addQuestion="addQuestion($event)"
    />
    <div
      v-if="part.questions.length === 0 && editing"
      class="col-12 justify-center"
    >
      <button
        class="itinerary-add-btn itinerary-add-btn-margin"
        @click="addQuestion(0)"
      >
        +
      </button>
    </div>
  </template>
</template>

<script>
// import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    i: {
      type: Number,
      required: true,
    },
    editing: {
      type: Boolean,
      required: true,
    },
    part: {
      type: Object,
      required: true,
    },
    showError: {
      type: Boolean,
      required: true,
    },
    themeId: {
      type: Number,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  emits: [
    "addPart",
    "removePart",
    "editPartName",
    "removeQuestion",
    "addQuestion",
    "editQuestionName",
    "editQuestion",
  ],
  data() {
    return {
      opened: true,
      traducciones: [
        {
          name: "see",
          es: "Ver",
          en: "See",
        },
      ],
    };
  },
  computed: {
    hasNeccesaryData() {
      return this.themeId && this.part?.id;
    },
  },
  methods: {
    addPart(i) {
      this.$emit("addPart", i);
    },
    removePart(i) {
      this.$emit("removePart", i);
    },
    editName(i, name) {
      this.$emit("editPartName", { i, name });
    },
    removeQuestion(i) {
      this.$emit("removeQuestion", { questionIndex: i, partIndex: this.i });
    },
    addQuestion(i) {
      this.$emit("addQuestion", { questionIndex: i, partIndex: this.i });
    },
    editQuestionName({ i, name }) {
      this.$emit("editQuestionName", {
        questionIndex: i,
        partIndex: this.i,
        name,
      });
    },
    goToAnswers() {
      if (!this.hasNeccesaryData) return;

      this.$router.push({
        path: `/parts/${this.projectId}/${this.themeId}/${this.part.id}`,
      });
    },
    editQuestion(question) {
      this.$emit("editQuestion", {
        questionIndex: question,
        partIndex: this.i,
      });
    },
  },
};
</script>
