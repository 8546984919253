<template>
  <MDBModal
    :modelValue="modelValue"
    @update:modelValue="updateModelValue"
    :backdrop="backdrop"
    :keyboard="keyboard"
    :focus="focus"
    :contentClass="['unified-modal', `modal-type-${type}`]"
    @keyup.esc="handleEscape"
    @keyup.enter="handleEnter"
    tabindex="-1"
    :aria-labelledby="modalTitle"
    :static="staticBackdrop"
  >
    <MDBModalHeader :class="`modal-header-${type}`">
      <MDBModalTitle :id="modalTitle">{{ title }}</MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody class="d-flex align-items-center">
      <div class="modal-icon-container me-3">
        <component :is="modalIcon" :class="`modal-icon-${type}`" size="lg" />
      </div>
      <div>{{ message }}</div>
    </MDBModalBody>
    <MDBModalFooter>
      <!-- Botones según la variante -->
      <template v-if="variant === 'yesNo'">
        <MDBBtn color="secondary" @click="handleNegative">No</MDBBtn>
        <MDBBtn :color="getButtonColor" @click="handlePositive">Sí</MDBBtn>
      </template>
      <template v-else-if="variant === 'acceptCancel'">
        <MDBBtn color="secondary" @click="handleNegative">Cancelar</MDBBtn>
        <MDBBtn :color="getButtonColor" @click="handlePositive">Aceptar</MDBBtn>
      </template>
      <template v-else>
        <MDBBtn :color="getButtonColor" @click="handlePositive">Aceptar</MDBBtn>
      </template>
    </MDBModalFooter>
  </MDBModal>
</template>

<script>
import {
  MDBModal,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
} from "mdb-vue-ui-kit";
import { computed, defineComponent } from "vue";
import {
  CheckCircle,
  AlertTriangle,
  AlertCircle,
  HelpCircle,
} from "lucide-vue-next";

export default defineComponent({
  name: "UnifiedModal",
  components: {
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn,
    CheckCircle,
    AlertTriangle,
    AlertCircle,
    HelpCircle,
  },
  props: {
    /**
     * Control de visibilidad del modal (v-model)
     */
    modelValue: {
      type: Boolean,
      required: true,
    },
    /**
     * Tipo de modal (afecta colores e iconos)
     */
    type: {
      type: String,
      validator: (value) =>
        ["info", "error", "warning", "question"].includes(value),
      default: "info",
    },
    /**
     * Variante de botones a mostrar
     */
    variant: {
      type: String,
      validator: (value) => ["yesNo", "acceptCancel", "accept"].includes(value),
      default: "accept",
    },
    /**
     * Título del modal
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * Mensaje principal a mostrar
     */
    message: {
      type: String,
      required: true,
    },
    /**
     * Habilitar cierre con clic fuera del modal
     */
    backdrop: {
      type: Boolean,
      default: true,
    },
    /**
     * Habilitar cierre con teclado (Esc)
     */
    keyboard: {
      type: Boolean,
      default: true,
    },
    /**
     * Habilitar focus automático
     */
    focus: {
      type: Boolean,
      default: true,
    },
    /**
     * Fondo estático (no se cierra al hacer clic fuera)
     */
    staticBackdrop: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "confirm", "cancel", "close"],
  setup(props, { emit }) {
    // ID único para el título del modal (accesibilidad)
    const modalTitle = `modal-title-${Date.now()}`;

    // Icono según el tipo de modal
    const modalIcon = computed(() => {
      switch (props.type) {
        case "info":
          return "CheckCircle";
        case "error":
          return "AlertCircle";
        case "warning":
          return "AlertTriangle";
        case "question":
          return "HelpCircle";
        default:
          return "CheckCircle";
      }
    });

    // Color del botón principal según el tipo de modal
    const getButtonColor = computed(() => {
      switch (props.type) {
        case "info":
          return "success";
        case "error":
          return "danger";
        case "warning":
          return "warning";
        case "question":
          return "primary";
        default:
          return "primary";
      }
    });

    // Métodos para manejar acciones del modal
    const updateModelValue = (value) => {
      emit("update:modelValue", value);
    };

    const close = () => {
      emit("close");
      updateModelValue(false);
    };

    const handlePositive = () => {
      emit("confirm");
      updateModelValue(false);
    };

    const handleNegative = () => {
      emit("cancel");
      updateModelValue(false);
    };

    const handleEscape = () => {
      if (props.keyboard) {
        close();
      }
    };

    const handleEnter = () => {
      // Solo confirmamos con Enter en modales de aceptación simple
      handlePositive();
    };

    return {
      modalTitle,
      modalIcon,
      getButtonColor,
      updateModelValue,
      close,
      handlePositive,
      handleNegative,
      handleEscape,
      handleEnter,
    };
  },
});
</script>

<style scoped>
.unified-modal {
  max-width: 500px;
}

/* Estilos por tipo de modal */
.modal-type-info .modal-header-info {
  border-bottom: 3px solid #00c851;
}

.modal-type-error .modal-header-error {
  border-bottom: 3px solid #ff3547;
}

.modal-type-warning .modal-header-warning {
  border-bottom: 3px solid #ffbb33;
}

.modal-type-question .modal-header-question {
  border-bottom: 3px solid #33b5e5;
}

/* Estilos de iconos */
.modal-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}

.modal-icon-info {
  color: #00c851;
}

.modal-icon-error {
  color: #ff3547;
}

.modal-icon-warning {
  color: #ffbb33;
}

.modal-icon-question {
  color: #33b5e5;
}
</style>
