<template>
  <div class="right-sidebar">
    <button
      v-for="(button, index) in filteredButtons"
      :key="index"
      :class="[
        view === index ? 'sidebar-btn-bg-selected' : 'sidebar-btn-bg',
        { 'disabled-btn': iindex === 4 && !taskManager },
        { 'sidebar-btn-disabled' : isDisabled(index) }
      ]"
      :disabled="index === 4 && !taskManager"
      @click="showView(index)"
    >
      <hover :content="langFilter(button)" >
        <img
          :class="(index === 3 || index === 4) && 'sidebar-btn-img'"
          :src="
            index === 3 || index === 4
              ? require(`@/assets/img/right-sidebar/${button}.webp`)
              : require(`@/assets/img/right-sidebar/${button}.svg`)
          "
          :alt="button"
        />
      </hover>
    </button>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
export default {
  props: {
    buttons: {
      type: Array,
      required: true,
    },
    view: {
      type: Number,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    space: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      taskManager: "",
      traducciones: [
        {
          name: "itinerary",
          es: "Itinerario",
          en: "Itinerary",
        },
        {
          name: "users",
          es: "Usuarios",
          en: "Users",
        },
        {
          name: "resources",
          es: "Recursos",
          en: "Resources",
        },
        {
          name: "taskManager",
          es: "Gestor de tareas",
          en: "Task Manager",
        },
        {
          name: "smartPrinting",
          es: "Smart Printing",
          en: "Smart Printing",
        },
        {
          name: "save",
          es: "Guardar",
          en: "Save",
        },
      ],
    };
  },
  computed: {
    filteredButtons() {
      return this.buttons;
    },
  },
  created() {
    axios
      .get(
        `${process.env.VUE_APP_API_URL}/myProjects/getProjectTaskManager/${this.projectId}`
      )
      .then((response) => {
        if (response.data && response.data.task_manager_url) {
          this.taskManager = response.data.task_manager_url;
        } else {
          this.taskManager = null;
        }
      })
      .catch((error) => {
        console.error(error);
        this.taskManager = null;
      });
  },

  methods: {
    showView(indexButton) {
      if (indexButton === 4 && this.taskManager) {
        window.open(this.taskManager, "_blank");
      } else {
        this.$emit("showView", indexButton);
      }
    },
    isDisabled(indexButton) {
      const disabledButtonIndex = this.buttons.indexOf("users");
      return this.space?.is_trial && this.space?.is_trial_active && indexButton === disabledButtonIndex;
    },
  },
  mixins: [translationMixin],
};
</script>

<style lang="css" scoped>
  .sidebar-btn-disabled {
    opacity: 0.5;
  }
  .sidebar-btn-disabled:active {
    pointer-events: none;
  }
  .sidebar-btn-disabled:hover {
    cursor: not-allowed;
  }
</style>