import { createApp } from "vue";
import router from "./router";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { useDebugMode } from "./debug";
import spaceConfigPlugin from "./plugins/spaceConfig";
import featuresPlugin from "./plugins/featuresPlugin";

import App from "./App.vue";
import Confirmation from "./components/ConfirmationComponent.vue";
import AlertMessage from "./components/AlertComponent.vue";
import AlertMessage2 from "./components/Alert2Component.vue";
import Pagination from "./components/PaginationComponent.vue";
import Hover from "./components/general/HoverComponent.vue";
import Error from "./components/ErrorComponent.vue";
import MyProjects from "./components/projects/MyProjectsComponent.vue";
import NewProjectsV2 from "./components/projects/NewProjectsV2Component.vue";
import NewProjectsContent from "./components/projects/NewProjectsContentComponent.vue";
import NewProjectsUsers from "./components/projects/NewProjectsUsersComponent.vue";
import NewProjectsInfo from "./components/projects/NewProjectsInfoComponent.vue";
import Program from "./components/program/ProgramComponent.vue";
import NewProgram from "./components/program/NewProgramComponent.vue";
import TrackRoot from "./components/track/TrackRootComponent.vue";
import TrackBanner from "./components/track/TrackBannerComponent.vue";
import TrackCatalog from "./components/track/TrackCatalogComponent.vue";
import TrackCategory from "./components/track/TrackCategoryComponent.vue";
import Tracks from "./components/track/TrackComponent.vue";
import Templates from "./components/track/TemplateComponent.vue";
import TrackItinerary from "./components/track/TrackItineraryComponent.vue";
import TemplateItinerary from "./components/track/TemplateItineraryComponent.vue";
import TrackModal from "./components/track/TrackModalComponent.vue";
import TemplateModal from "./components/track/TemplateModalComponent.vue";
import TrackResources from "./components/track/TrackResourcesComponent.vue";
import TemplateResources from "./components/track/TemplateResourcesComponent.vue";
import TrackSearch from "./components/track/TrackSearchComponent.vue";
import NewTrack from "./components/track/newTrack/NewTrackComponent.vue";
import Search from "./components/general/SearchComponent.vue";
import Atras from "./components/general/AtrasComponent.vue";
import Resources from "./components/ResourcesComponent.vue";
import NewResources from "./components/NewResourcesComponent.vue";
import Carrousel from "./components/general/CarrouselComponent.vue";
import Home from "./components/home/HomeComponent.vue";
import ProjectModule from "./components/home/ProjectModuleComponent.vue";
import NotificationModule from "./components/home/NotificationModuleComponent.vue";
import BulletinModule from "./components/home/BulletinModuleComponent.vue";
import Itinerary from "./components/itinerary/ItineraryComponent.vue";
import ItineraryHeader from "./components/itinerary/ItineraryHeaderComponent.vue";
import ItineraryHeaderTable from "./components/itinerary/ItineraryHeaderTableComponent.vue";
import ItineraryTheme from "./components/itinerary/ItineraryThemeTableComponent.vue";
import ItineraryApart from "./components/itinerary/ItineraryApartTableComponent.vue";
import ItineraryQuestion from "./components/itinerary/ItineraryQuestionTableComponent.vue";
import ItineraryProfile from "./components/itinerary/ItineraryProfileComponent.vue";
import ItineraryUserModal from "./components/itinerary/ItineraryUserModalComponent.vue";
import ItineraryEditQuestion from "./components/newitinerary/NewItineraryEditQuestionComponent.vue";
import newItinerary from "./components/newitinerary/newItineraryComponent.vue";
import HeaderItinerary from "./components/newitinerary/HeaderComponent.vue";
import rightSidebar from "./components/general/RightSidebarComponent.vue";
import NewItineraryTheme from "./components/newitinerary/ThemeComponent.vue";
import ItineraryData from "./components/newitinerary/ItineraryDataComponent.vue";
import ItineraryUsers from "./components/newitinerary/UsersComponent.vue";
import ItineraryUsersTable from "./components/newitinerary/UsersTableComponent.vue";
import ItineraryUsersModal from "./components/newitinerary/UsersModalComponent.vue";
import ItineraryUsersModalUser from "./components/newitinerary/UsersModalUserComponent.vue";
import SearchProject from "./components/newitinerary/SearchProjectComponent.vue";
import NewItineraryPart from "./components/newitinerary/PartComponent.vue";
import NewItineraryQuestion from "./components/newitinerary/QuestionComponent.vue";
import OpenBtn from "./components/newitinerary/OpenBtnComponent.vue";
import Sidebar from "./components/sidebar/SidebarComponent.vue";
import SidebarOption from "./components/sidebar/SidebarOptionComponent.vue";
import SidebarClosedOption from "./components/sidebar/SidebarClosedOptionComponent.vue";
import SidebarLeft from "./components/sidebar/SidebarLeftComponent.vue";
import SidebarRight from "./components/sidebar/SidebarRightComponent.vue";
import SidebarSpace from "./components/sidebar/SidebarSpaceComponent.vue";
import Tongue from "./components/sidebar/TongueComponent.vue";
import TongueContainer from "./components/sidebar/TongueContainerComponent.vue";
import TongueModal from "./components/sidebar/TongueModalComponent.vue";
import Login from "./components/LoginComponent.vue";
import ForgotPassword from "./components/ForgotPasswordComponent.vue";
import RecoverPassword from "./components/RecoverPasswordComponent.vue";
import Signup from "./components/auth/SignupComponent.vue";
import Notifications from "./components/notifications/NotificationsComponent.vue";
import NotificationsTable from "./components/notifications/NotificationsTableComponent.vue";
import NotificationsTableHeader from "./components/notifications/NotificationsTableHeaderComponent.vue";
import NotificationsTableNew from "./components/notifications/NotificationsTableNewComponent.vue";
import NotificationsTablePrevious from "./components/notifications/NotificationsTablePreviousComponent.vue";
import NotificationsTableNotification from "./components/notifications/NotificationsTableNotificationComponent.vue";
import NotificationsModal from "./components/notifications/NotificationsModalComponent.vue";
import SmartPrinting from "./components/projects/SmartPrintingModalComponent.vue";
import Faqs from "./components/faqs/FaqsComponent.vue";
import FaqCategory from "./components/faqs/FaqCategoryComponent.vue";
import FaqAnswer from "./components/faqs/FaqAnswerComponent.vue";
import BarraContact from "./components/faqs/BarraContactComponent.vue";
import AdminPanel from "./components/admin_panel/AdminPanelComponent.vue";
import AdminSearch from "./components/admin_panel/AdminPanelSearchComponent.vue";
import AdminUsers from "./components/admin_panel/AdminPanelUsersComponent.vue";
import AdminScreens from "./components/admin_panel/AdminPanelScreensComponent.vue";
import AdminPermissions from "./components/admin_panel/AdminPanelPermissionsComponent.vue";
import AdminOption from "./components/admin_panel/AdminPanelPermissionsOptionComponent.vue";
import Forum from "./components/forum/ForumComponent.vue";
import ForumPost from "./components/forum/PostComponent.vue";
import ForumSubpost from "./components/forum/SubPostComponent.vue";
import ForumThread from "./components/forum/ThreadComponent.vue";
import ForumBulletin from "./components/forum/BulletinComponent.vue";
import ForumSelectedBulletin from "./components/forum/SelectedBulletinComponent.vue";
import Pagination2 from "./components/Pagination2Component.vue";
import ModalComponent from "./components/modal/ModalComponent.vue";
import ModalFooter from "./components/modal/ModalFooterComponent.vue";
import ModalHead from "./components/modal/ModalHeadComponent.vue";
import ModalTypeInput from "./components/modal/ModalInputTypeComponent.vue";
import ModalInputs1 from "./components/modal/ModalInputs1Component.vue";
import ModalInputs2 from "./components/modal/ModalInputs2Component.vue";
import ModalInputs3 from "./components/modal/ModalInputs3Component.vue";
import ModalInputs4 from "./components/modal/ModalInputs4Component.vue";
import CustomerSupport from "./components/CustomerSupportComponent.vue";
import Feedback from "./components/FeedbackComponent.vue";
import Header from "./components/general/HeaderComponent.vue";
import NewProjectResources from "./components/NewProjectResourcesComponent.vue";
import EditProfile from "./components/users/EditProfileComponent.vue";
import ResourcesStation from "./components/ResourcesStationComponent.vue";
import Solution from "./components/solution/SolutionComponent.vue";
import SolutionHeader from "./components/solution/SolutionHeaderComponent.vue";
import SolutionFooter from "./components/solution/SolutionFooterComponent.vue";
import SolutionWrite from "./components/solution/SolutionWriteComponent.vue";
import SolutionMap from "./components/solution/SolutionMapComponent.vue";
import SolutionContainer from "./components/solution/SolutionContainerComponent.vue";
import SolutionSend from "./components/solution/SolutionSendCorrectionComponent.vue";
import ProgressBarSolution from "./components/solution/ProgressBarSolutionComponent.vue";
import ManageSpace from "./components/space/ManageSpaceComponent.vue";
import CreateUpdateSpace from "./components/space/CreateUpdateSpaceComponent.vue";
import DeleteSpace from "./components/space/DeleteSpaceComponent.vue";
import UserTableSpace from "./components/space/UserTableSpaceComponent.vue";
import TemplateTableSpace from "./components/space/TemplateSpaceComponent.vue";
import AssistantTableSpace from "./components/space/AssistantsSpaceComponent.vue";
import NewTemplate from "./components/template/NewTemplate/NewTemplateComponent.vue";
import NewTemplateContainer from "./components/template/NewTemplate/NewTemplateContainerComponent.vue";
import NewTemplateSteps from "./components/template/NewTemplate/NewTemplateStepsComponent.vue";
import NewTemplateFooter from "./components/template/NewTemplate/NewTemplateFooterComponent.vue";
import NewTemplateTitle from "./components/template/NewTemplate/NewTemplateTitleComponent.vue";
import NewTemplateBody from "./components/template/NewTemplate/NewTemplateBodyComponent.vue";
import NewTemplateTemplate from "./components/template/NewTemplate/NewTemplateTemplateComponent.vue";
import NewTemplateCreation from "./components/template/NewTemplate/NewTemplateCreationComponent.vue";
import NewTemplateCreationOptions from "./components/template/NewTemplate/NewTemplateCreationOptionsComponent.vue";
import NewTemplateCreationApart from "./components/template/NewTemplate/NewTemplateCreationApartComponent.vue";
import NewTemplateCreationQuestion from "./components/template/NewTemplate/NewTemplateCreationQuestionsComponent.vue";
import NewTemplateCreationTheme from "./components/template/NewTemplate/NewTemplateCreationThemeComponent.vue";
import NewTemplateEditQuestion from "./components/template/NewTemplate/NewTemplateEditQuestionComponent.vue";
import ResourcesModal from "./components/resource/ResourcesModalComponent.vue";
import ResourcesModalTemplate from "./components/newitinerary/ResourcesModalComponent.vue";
import NewResourcesModal from "./components/resource/NewResourcesModalComponent.vue";
import NewResourceModal from "./components/resource/NewResourceModalComponent.vue";
import ConfirmationModal from "./components/general/ConfirmationModalComponent.vue";
import TermsSidebar from "./components/TermsSidebarComponent.vue";
import CdtiModal from "./components/cdti/CdtiModalComponent.vue";
import CdtiShow from "./components/cdti/CdtiShowComponent.vue";
import Chatbot from "./components/gpt/ChatBotComponent.vue";
import ChatbotProject from "./components/gpt/ChatBotProjectComponent.vue";
import ChatWithGpt from "./components/gpt/ChatComponent.vue";
import SmartPrintingModal from "./components/smartPrinting/SmartPrintingComponent.vue";
import TabsContainer from "./components/tabs/TabsContainerComponent.vue";
import ImportTemplateModal from "./components/projects/ImportTemplateModal.vue";
import ChatBotProjectHeader from "./components/gpt/ChatBotProjectHeaderComponent.vue";
import ChatBotProjectChat from "./components/gpt/ChatBotProjectChatComponent.vue";
import ChatBotProjectChatAvatar from "./components/gpt/ChatBotProjectChatAvatarComponent.vue";
import ChatBotProjectChatMessages from "./components/gpt/ChatBotProjectChatMessagesComponent.vue";
import GenericModal from "./components/general/GenericModal.vue";
import ChatBotProjectChatAttachment from "./components/gpt/ChatBotProjectChatAttachmentComponent.vue";
import ChatFilesComponent from "./components/gpt/ChatFilesComponent.vue";
import AlertToastComponent from "./components/AlertToastComponent.vue";
import PopupModal from "./components/popup/PopupModalComponent.vue";
import UnifiedModal from "./components/popup/UnifiedModal.vue";

import Cookies from "js-cookie";

const app = createApp(App);
useDebugMode();

if (Cookies.get("token")) {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + Cookies.get("token");
}

axios.defaults.headers.common["Accept"] = "application/json";

app.use(router);
app.use(spaceConfigPlugin);
app.use(featuresPlugin);

app.directive("outside", {
  mounted(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

app
  //General Components
  .component("NewResources", NewResources)
  .component("search", Search)
  .component("atras", Atras)
  .component("carrousel", Carrousel)
  .component("confirmation", Confirmation)
  .component("alertMessage", AlertMessage)
  .component("alertMessage2", AlertMessage2)
  .component("alertToast", AlertToastComponent)
  .component("Pagination", Pagination)
  .component("Error", Error)
  .component("header-general", Header)
  .component("confirmation-modal", ConfirmationModal)
  .component("generic-modal", GenericModal)
  .component("hover", Hover)

  //MyProjects Components
  .component("myprojects", MyProjects)
  .component("newprojectsV2", NewProjectsV2)
  .component("newprojectsContent", NewProjectsContent)
  .component("newprojectsUsers", NewProjectsUsers)
  .component("newprojectsInfo", NewProjectsInfo)
  .component("smart-printing", SmartPrinting)
  .component("ImportTemplateModal", ImportTemplateModal)

  //Programs Components
  .component("program", Program)
  .component("newprogram", NewProgram)

  //Tracks Components
  .component("track-root", TrackRoot)
  .component("trackBanner", TrackBanner)
  .component("track-catalog", TrackCatalog)
  .component("track-category", TrackCategory)
  .component("tracks", Tracks)
  .component("templates", Templates)
  .component("track-itinerary", TrackItinerary)
  .component("template-itinerary", TemplateItinerary)
  .component("trackModal", TrackModal)
  .component("templateModal", TemplateModal)
  .component("track-resources", TrackResources)
  .component("template-resources", TemplateResources)
  .component("track-search", TrackSearch)
  .component("new-track", NewTrack)

  //itinerary Components
  .component("itinerary", Itinerary)
  .component("itinerary-header", ItineraryHeader)
  .component("itinerary-profile", ItineraryProfile)
  .component("itinerary-headertable", ItineraryHeaderTable)
  .component("itinerary-themetable", ItineraryTheme)
  .component("itinerary-aparttable", ItineraryApart)
  .component("itinerary-usermodal", ItineraryUserModal)
  .component("itinerary-questiontable", ItineraryQuestion)

  //new itinerary Components
  .component("new-itinerary", newItinerary)
  .component("header-itinerary", HeaderItinerary)
  .component("right-sidebar", rightSidebar)
  .component("itinerary-theme", NewItineraryTheme)
  .component("itinerary-part", NewItineraryPart)
  .component("itinerary-question", ItineraryQuestion)
  .component("itinerary-question", NewItineraryQuestion)
  .component("open-btn", OpenBtn)
  .component("itinerary-data", ItineraryData)
  .component("itinerary-users", ItineraryUsers)
  .component("itinerary-users-table", ItineraryUsersTable)
  .component("itinerary-users-modal", ItineraryUsersModal)
  .component("itinerary-users-modal-user", ItineraryUsersModalUser)
  .component("search-project", SearchProject)
  .component("itinerary-edit-question", ItineraryEditQuestion)

  //Home Components
  .component("home", Home)
  .component("project-module", ProjectModule)
  .component("notification-module", NotificationModule)
  .component("bulletin-module", BulletinModule)

  //Sidebar Components
  .component("sidebar", Sidebar)
  .component("sidebar-closed-option", SidebarClosedOption)
  .component("sidebar-option", SidebarOption)
  .component("sidebar-left", SidebarLeft)
  .component("sidebar-right", SidebarRight)
  .component("sidebar-space", SidebarSpace)
  .component("tongue", Tongue)
  .component("tongue-container", TongueContainer)
  .component("tongue-modal", TongueModal)

  //Auth Components
  .component("login", Login)
  .component("forgot-password", ForgotPassword)
  .component("recover-password", RecoverPassword)
  .component("signup", Signup)

  //Resources Components
  .component("Resources", Resources)
  .component("NewProjectResources", NewProjectResources)
  .component("ResourcesStation", ResourcesStation)

  //Users Components
  .component("edit-profile", EditProfile)

  //Notifications Component
  .component("notifications", Notifications)
  .component("notifications-table", NotificationsTable)
  .component("notifications-table-header", NotificationsTableHeader)
  .component("notifications-table-new", NotificationsTableNew)
  .component("notifications-table-previous", NotificationsTablePrevious)
  .component("notifications-table-notification", NotificationsTableNotification)
  .component("notifications-modal", NotificationsModal)

  //FAQs Component
  .component("faqs", Faqs)
  .component("faq-category", FaqCategory)
  .component("faq-answer", FaqAnswer)
  .component("barraContact", BarraContact)
  .component("Customersupport", CustomerSupport)
  .component("feedback", Feedback)

  //Forum Components
  .component("forum", Forum)
  .component("forum-post", ForumPost)
  .component("forum-subpost", ForumSubpost)
  .component("forum-thread", ForumThread)
  .component("forum-bulletin", ForumBulletin)
  .component("forum-selected-bulletin", ForumSelectedBulletin)
  .component("pagination2", Pagination2)

  //AdminPanel Components
  .component("admin-panel", AdminPanel)
  .component("admin-search", AdminSearch)
  .component("admin-users", AdminUsers)
  .component("admin-screens", AdminScreens)
  .component("admin-permissions", AdminPermissions)
  .component("admin-option", AdminOption)

  //Modal Components
  .component("modal-window", ModalComponent)
  .component("modal-head", ModalHead)
  .component("modal-footer", ModalFooter)
  .component("modal-type-input", ModalTypeInput)
  .component("modal-inputs-1", ModalInputs1)
  .component("modal-inputs-2", ModalInputs2)
  .component("modal-inputs-3", ModalInputs3)
  .component("modal-inputs-4", ModalInputs4)

  //Solution Components
  .component("solution", Solution)
  .component("solution-header", SolutionHeader)
  .component("solution-write", SolutionWrite)
  .component("solution-footer", SolutionFooter)
  .component("solution-map", SolutionMap)
  .component("solution-send", SolutionSend)
  .component("solution-container", SolutionContainer)
  .component("progressbar-solution", ProgressBarSolution)

  //Space Components
  .component("manage-space", ManageSpace)
  .component("create-update-space", CreateUpdateSpace)
  .component("delete-space", DeleteSpace)
  .component("user-table-space", UserTableSpace)
  .component("template-table-space", TemplateTableSpace)
  .component("assistant-table-space", AssistantTableSpace)

  //Template Components
  .component("new-template", NewTemplate)
  .component("template-container", NewTemplateContainer)
  .component("template-steps", NewTemplateSteps)
  .component("new-template-title", NewTemplateTitle)
  .component("new-template-body", NewTemplateBody)
  .component("new-template-footer", NewTemplateFooter)
  .component("new-template-template", NewTemplateTemplate)
  .component("new-template-creation", NewTemplateCreation)
  .component("new-template-creation-options", NewTemplateCreationOptions)
  .component("new-template-creation-apart", NewTemplateCreationApart)
  .component("new-template-creation-theme", NewTemplateCreationTheme)
  .component("new-template-creation-question", NewTemplateCreationQuestion)
  .component("new-template-edit-question", NewTemplateEditQuestion)

  //resources
  .component("resources-modal", ResourcesModal)
  .component("new-resources-modal", NewResourcesModal)
  .component("new-resource-modal", NewResourceModal)
  .component("resource-modal-template", ResourcesModalTemplate)

  //terms and conditions
  .component("terms-sidebar", TermsSidebar)

  //cdti
  .component("cdti-modal", CdtiModal)
  .component("cdti-show", CdtiShow)

  //chatGPT
  .component("chat-bot", Chatbot)
  .component("chat-bot-project", ChatbotProject)
  .component("chat-with-gpt", ChatWithGpt)
  .component("chat-bot-project-header", ChatBotProjectHeader)
  .component("chat-bot-project-chat", ChatBotProjectChat)
  .component("chat-bot-project-chat-avatar", ChatBotProjectChatAvatar)
  .component("chat-bot-project-chat-messages", ChatBotProjectChatMessages)
  .component("chat-bot-project-chat-attachment", ChatBotProjectChatAttachment)
  .component("chat-files", ChatFilesComponent)

  //SmartPrinting
  .component("smart-printing-modal", SmartPrintingModal)

  //Tabs
  .component("tabs-container", TabsContainer)

  //Popups
  .component("popup-modal", PopupModal)
  .component("UnifiedModal", UnifiedModal);

app.mount("#app");
