<template>
  <div v-if="filesUploaded.length > 0" class="file-button" @click="openFilesModal" @mouseover="hoverFiles = true"
    @mouseleave="hoverFiles = false">
    <img :src="fileIcon" alt="Files" />

    <span v-if="hoverFiles" class="general-hover hover-top">
      {{ langFilter("files") }}
    </span>
  </div>
  <div v-else class="no-file-button" @mouseover="hoverFiles = true" @mouseleave="hoverFiles = false">
    <img :src="fileIcon" alt="Files" />
    <span v-if="hoverFiles" class="general-hover hover-top">
      {{ langFilter("noFiles") }}
    </span>
  </div>

  <div v-if="showFiles">
    <div v-if="loading" class="loading-data-container">
      <img :src="require('@/assets/img/general/carga.gif')" alt="loading" />
    </div>
    <div class="files-uploaded-modal-fade" @click="handleFilesModal(false)" />
    <div class="files-uploaded-modal">
      <h3 v-if="modalTitle">{{ langFilter(modalTitle) }}</h3>
      <button class="file-close-btn" @click="handleFilesModal(false)">
        <img class="delete-icon" :src="closeIcon" alt="Close" />
      </button>
      <div v-if="filesUploaded.length" class="files-container">
        <div v-for="{ id, file_name: name } in filesUploaded" :key="id">
          <div class="file-row">
            <h3 class="file-name">
              {{ name }}
            </h3>
            <div class="pointer" @click="openConfirmModal(id)">
              <img :src="trashIcon" alt="Delete file" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <confirmation-modal v-if="isOpenConfirmationModal" :body="langFilter('deleteFileConfirmation')" :lang="lang" fixed
    @close-confirmation="closeConfirmModal" @confirm="confirmDeleteFile" />
</template>

<script>
import axios from "axios";
import translationMixin from "@/mixins/translationMixin";
import FileIcon from "@/components/solution/images/file.svg";
import CloseIcon from "@/assets/img/track/delete.svg";
import TrashIcon from "@/assets/img/projects/delete.svg"

import { ASSISTANTS_TYPE } from "@/constants";
const { EXPERT } = ASSISTANTS_TYPE;

export default {
  mixins: [translationMixin],
  inject: ["showAlert"],
  props: {
    lang: {
      type: String,
      required: true,
    },
    assistantType: {
      type: Number,
      required: true,
      default: EXPERT,
    },
    projectId: {
      type: Number,
      required: true,
      default: EXPERT,
    },
    refreshKey: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      showFiles: false,
      hoverFiles: false,
      filesUploaded: [],
      loading: false,
      fileIcon: FileIcon,
      closeIcon: CloseIcon,
      trashIcon: TrashIcon,
      isOpenConfirmationModal: false,
      selectedFileId: null,
      modalTitle: "",
      traducciones: [
        {
          name: "files",
          es: "Hay archivos",
          en: "There are files",
        },
        {
          name: "noFiles",
          es: "No hay archivos",
          en: "There are no files",
        },
        {
          name: "deleteFileConfirmation",
          es: "¿Estás seguro de que quieres borrar el archivo del hilo del proyecto? No podrás recuperarlo luego",
          en: "Are you sure you want to delete the file from the project thread? Once deleted, it cannot be recovered",
        },
        {
          name: "expertFiles",
          es: "Archivos subidos al experto",
          en: "Files uploaded to the expert",
        },
        {
          name: "evaluatorFiles",
          es: "Archivos subidos al evaluador",
          en: "Files uploaded to the evaluator",
        },
      ],
    };
  },  
  watch: {
    refreshKey() {
      this.loadUploadedFiles();
    }
  },
  mounted() {
    // this.intervalId = setInterval(this.loadUploadedFiles, 3000);
    this.loadUploadedFiles();
  },
  unmounted() {
    clearInterval(this.intervalId);
  },
  methods: {
    async loadUploadedFiles() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/getUploadedGptFiles/${this.projectId}/${this.assistantType}`
        );
        this.filesUploaded = response.data.project_uploaded_files;
      } catch (error) {
        this.showAlert({
          title: "error",
          text: "errorGettingFiles",
        });
        console.error("Error loading uploaded files", error);
      }
    },
    handleFilesModal(state) {
      this.showFiles = state;
    },
    openConfirmModal(fileId) {
      this.selectedFileId = fileId;
      this.isOpenConfirmationModal = true;
    },
    closeModal() {
      this.isOpenConfirmationModal = false;
    },
    cleanFileId() {
      this.selectedFileId = null;
    },
    closeConfirmModal() {
      this.closeModal();
      this.cleanFileId();
    },
    async openFilesModal() {
      try {
        this.loading = true;
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/getUploadedGptFiles/${this.projectId}/${this.assistantType}`
        );

        this.filesUploaded = response.data.project_uploaded_files;
        this.handleFilesModal(true);
        this.modalTitle = this.assistantType === EXPERT ? "expertFiles" : "evaluatorFiles";
      } catch (error) {
        this.showAlert({
          title: "error",
          text: "errorGettingFiles",
        });
        console.error("Error getting files", error);
      } finally {
        this.loading = false;
      }
    },
    async confirmDeleteFile() {
      this.closeModal();

      try {
        this.loading = true;
        await axios.delete(
          `${process.env.VUE_APP_API_URL}/projects/${this.projectId}/files/${this.selectedFileId}`
        );
        this.filesUploaded = this.filesUploaded.filter(
          ({ id }) => id !== this.selectedFileId
        );
      } catch (error) {
        this.showAlert({
          title: "error",
          text: "errorDeletingFile",
        });
        console.error("Error deleting file", error);
      } finally {
        this.cleanFileId();
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.file-button {
  align-items: center;
  background: #815bf0;
  border-radius: 26px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  position: relative;
  width: 59px !important;

  img {
    fill: white;
    height: 25px;
    width: 25px;
  }
}

.no-file-button {
  align-items: center;
  background: #B4B9C3;
  border-radius: 26px;
  display: flex;
  height: 40px;
  justify-content: center;
  position: relative;
  width: 59px !important;

  img {
    fill: white;
    height: 25px;
    width: 25px;
  }
}
</style>
