<template>
  <div class="container">
    <div class="row">
      <div class="col-6">
        <h5>{{ langFilter("assignUsers") }}</h5>
      </div>
      <div class="col-6">
        <h5>{{ langFilter("usersAssigned") }}</h5>
      </div>
      <br /><br />
      <div class="col-6">
        <input
          type="text"
          placeholder="Search Users"
          @input="searchUsers($event)"
        />
      </div>
      <div class="col-6" ></div>
      <br /><br />
      <div class="col-6 space-div-height space-user-template-container">
        <table class="space-user-container-table">
          <tr>
            <th>{{ langFilter("name") }}</th>
            <th>{{ langFilter("rol") }}</th>
            <th></th>
          </tr>
          <tr v-for="(user, userIndex) in users" :key="user.id">
            <td>{{ user.name }}</td>
            <td>
              <select @change="changeSelectedRole($event, userIndex, false)">
                <option :value="1">Admin</option>
                <option :value="2">{{ langFilter("miembro") }}</option>
              </select>
            </td>
            <td>
              <button class="btn btn-success" @click="addUser(user)">+</button>
            </td>
          </tr>
        </table>
      </div>
      <div class="col-6 space-div-height space-user-template-container">
        <table v-if="usersAssigned.length > 0" class="space-user-container-table">
          <tr>
            <th>{{ langFilter("name") }}</th>
            <th>{{ langFilter("role") }}</th>
            <th></th>
          </tr>
          <tr v-for="(user, userIndex) in usersAssigned" :key="user.id">
            <td>{{ user.name }}</td>
            <td>
              <select @change="changeSelectedRole($event, userIndex, true)">
                <option :value="1">Admin</option>
                <option :value="2" :selected="user.role == 2">
                  {{ langFilter("miembro") }}
                </option>
              </select>
            </td>
            <td>
              <button class="btn btn-danger" @click="removeUser(user.id)">
                -
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <br /><br />
  </div>
</template>

<script>
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  props: {
    spaceId: {
      type: [Number, null],
      required: true,
    },
  },
  emits: ["addUser", "removeUser"],
  data() {
    return {
      users: [],
      usersAssigned: [],
      selectedRole: 1,
      search: "",
      allUsers: [],
      traducciones: [
        {
          name: "assignUsers",
          es: "Asignar Usuarios",
          en: "Assign Users",
        },
        {
          name: "usersAssigned",
          es: "Usuarios Asignados",
          en: "Assigned Users",
        },
        {
          name: "miembro",
          es: "Miembro",
          en: "Member",
        },
        {
          name: "role",
          es: "Rol",
          en: "Role",
        },
      ],
    };
  },
  created() {
    this.getAllUsers();
  },
  methods: {
    getAllUsers() {
      this.addingUsers = !this.addingUsers;

      if (this.users.length === 0) {
        this.fetchAllUsers()
          .then(() => {
            if (this.spaceId != null) {
              return this.fetchUsersAssignedToSpace();
            }
          })
          .catch((error) => {
            console.error("Error fetching users:", error);
          });
      }
    },

    async fetchAllUsers() {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/general/getAllUsers`
      );
      this.users = response.data;
      this.allUsers = response.data;
    },

    async fetchUsersAssignedToSpace() {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/manageSpaces-acc/getUsersBySpace/${this.spaceId}`
      );
      this.usersAssigned = response.data.users;
      this.filterOutAssignedUsers();
      this.$emit("addUser", this.usersAssigned);
    },

    filterOutAssignedUsers() {
      this.users = this.users.filter((user) => {
        return !this.usersAssigned.some(
          (assignedUser) => assignedUser.id === user.id
        );
      });
    },

    searchUsers(event) {
      const search = event.target.value;
      this.search = search.toLowerCase();

      //filter users by search and ensure they are not in usersAssigned
      this.users = this.allUsers.filter((user) => {
        if (user.name != null) {
          return (
            user.name.toLowerCase().includes(this.search) &&
            !this.usersAssigned.some(
              (assignedUser) => assignedUser.name === user.name
            )
          );
        }
      });
    },
    addUser(user) {
      this.usersAssigned.push(user);
      this.users = this.users.filter((u) => u.id != user.id);
      this.allUsers = this.allUsers.filter((u) => u.id != user.id);
      this.$emit("addUser", this.usersAssigned);
    },
    removeUser(user_id) {
      this.users.push(this.usersAssigned.find((u) => u.id == user_id));
      this.allUsers.push(this.usersAssigned.find((u) => u.id == user_id));
      this.usersAssigned = this.usersAssigned.filter((u) => u.id != user_id);
      this.$emit("removeUser", user_id);
    },
    changeSelectedRole(event, index, selected) {
      if (!selected) {
        this.users[index].role = event.target.value;
      } else {
        this.usersAssigned[index].role = event.target.value;
      }
    },
  },
};
</script>
