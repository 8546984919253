<template>
  <div class="manage-space-container">
    <br /><br />
    <div class="manage-space-container-select-container">
      <select @change="setOption">
        <option :value="0">{{ langFilter("create") }}</option>
        <option :value="1">{{ langFilter("update") }}</option>
        <option :value="2">{{ langFilter("delete") }}</option>
      </select>
    </div>
    <br /><br />
    <create-update-space
      v-show="option < 2"
      :option="option"
      @msg="showMsg($event)"
    />
    <delete-space v-if="option === 2" @msg="showMsg($event)" />
    <p>{{ msg }}</p>
  </div>
</template>

<script>
import "@/assets/css/manage_spaces.css";
import translationMixin from "../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  data() {
    return {
      option: 0,
      msg: "",
      traducciones: [
        {
          name: "create",
          es: "Crear",
          en: "Create",
        },
        {
          name: "update",
          es: "Actualizar",
          en: "Update",
        },
        {
          name: "delete",
          es: "Eliminar",
          en: "Delete",
        },
      ],
    };
  },
  methods: {
    setOption(event) {
      this.msg = "";
      this.option = parseInt(event.target.value);
    },
    showMsg(msg) {
      this.msg = msg;
      setTimeout(() => {
        this.msg = "";
      }, 3000);
    },
  },
};
</script>
