<template>
  <div class="members-title">
    <span>{{ langFilter("projMembers") }}</span>
  </div>
  <div>
    <button
      v-if="editing"
      class="itinerary-add-btn itinerary-add-users-btn"
      @click="addingUsers = label"
    >
      +
    </button>
  </div>
  <div class="container-users-ap">
    <table class="table-light table-users w-100">
      <thead>
        <tr>
          <th>
            <div class="header-users-ap">
              <div class="location-name">
                <span class="names-ap">
                  {{ langFilter("name") }}
                </span>
                <img
                  class="arrow-up-ap"
                  :src="require('@/assets/img/admin_panel/arrow-up.svg')"
                  alt="arrow-up"
                  @click="sortUsers('name')"
                />
                <img
                  class="arrow-down-ap"
                  :src="require('@/assets/img/admin_panel/arrow-down.svg')"
                  alt="arrow-down"
                  @click="sortUsers('name')"
                />
              </div>
            </div>
          </th>
          <th>
            <div class="col header-users-ap">
              <div class="location-name">
                <span class="names-ap"> ID </span>
                <img
                  class="arrow-up-ap arrow-right-ap"
                  :src="require('@/assets/img/admin_panel/arrow-up.svg')"
                  alt="arrow-up"
                  @click="sortUsers('id')"
                />
                <img
                  class="arrow-down-ap arrow-right-ap"
                  :src="require('@/assets/img/admin_panel/arrow-down.svg')"
                  alt="arrow-down"
                  @click="sortUsers('id')"
                />
              </div>
            </div>
          </th>
          <th>
            <div class="col header-users-ap">
              <div class="location-name">
                <span class="names-ap"> Email </span>
                <img
                  class="arrow-up-ap arrow-right-ap"
                  :src="require('@/assets/img/admin_panel/arrow-up.svg')"
                  alt="arrow-up"
                  @click="sortUsers('email')"
                />
                <img
                  class="arrow-down-ap arrow-right-ap"
                  :src="require('@/assets/img/admin_panel/arrow-down.svg')"
                  alt="arrow-down"
                  @click="sortUsers('email')"
                />
              </div>
            </div>
          </th>
          <th>
            <div class="col header-users-ap">
              <div class="location-name">
                <span class="names-ap"> {{ langFilter("role") }} </span>
                <img
                  class="arrow-up-ap"
                  :src="require('@/assets/img/admin_panel/arrow-up.svg')"
                  alt="arrow-up"
                  @click="sortUsers('role')"
                />
                <img
                  class="arrow-down-ap"
                  :src="require('@/assets/img/admin_panel/arrow-down.svg')"
                  alt="arrow-down"
                  @click="sortUsers('role')"
                />
              </div>
            </div>
          </th>
          <th>
            <div class="col header-users-ap">
              <div class="location-name">
                <span class="names-ap"> {{ langFilter("status") }} </span>
              </div>
            </div>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(user, index) in sortedUsers"
          :key="index"
          class="tbl-users-name-ap"
          :class="{
            'background-users-ap': index % 2 === 0,
          }"
        >
          <td>
            <tr>
              <td>
                <img
                  class="img-user-ap"
                  :src="getAvatar(user.avatar)"
                  :alt="user.name ?? 'User avatar'"
                />
              </td>
              <td class="align-middle">
                {{ user.name }}
              </td>
            </tr>
          </td>
          <td class="text-center align-middle">{{ user.id }}</td>
          <td
            class="text-center align-middle email-role-tbl-ap users-td-email-overflow"
          >
            {{ user.email }}
          </td>
          <td class="text-center align-middle">
            <select
              v-if="user.role === ROLES.member"
              class="select-change-role-itinerary"
              @change="changeRole($event, user)"
            >
              <option :value="ROLES.member">{{ langFilter("members") }}</option>
              <option :value="ROLES.tutor">{{ langFilter("tutors") }}</option>
            </select>
            <select
              v-if="user.role === ROLES.tutor"
              class="select-change-role-itinerary"
              @change="changeRole($event, user)"
            >
              <option :value="ROLES.tutor">{{ langFilter("tutors") }}</option>
              <option :value="ROLES.member">{{ langFilter("members") }}</option>
            </select>
          </td>
          <td class="text-center align-middle">
            <div
              v-if="
                usersToRemove.tutors.some((u) => u.id === user.id) ||
                usersToRemove.members.some((u) => u.id === user.id)
              "
              id="status-users-red-circle"
              :title="langFilter('UsersDeleted')"
              class="d-inline-block"
            ></div>
            <div
              v-else-if="
                user.avatar !==
                'https://api.acceleralia.com/img/wordpresslogos/Avatar-8-150x150.jpg'
              "
              id="status-users-green-circle"
              :title="langFilter('UsersIn')"
              class="d-inline-block"
            ></div>
            <div
              v-else-if="
                user.avatar ===
                'https://api.acceleralia.com/img/wordpresslogos/Avatar-8-150x150.jpg'
              "
              id="status-users-orange-circle"
              :title="langFilter('UsersInvited')"
              class="d-inline-block"
            ></div>
          </td>
          <td class="text-center align-middle">
            <div
              v-if="
                usersToRemove.tutors.some((u) => u.id === user.id) ||
                usersToRemove.members.some((u) => u.id === user.id)
              "
            >
              <img
                class="box-project-myprojects-image recover-icon"
                :src="require('@/assets/img/projects/send.svg')"
                :title="langFilter('recover')"
                alt="recover"
                @click="recoverUser(user)"
              />
            </div>
            <div v-else>
              <img
                class="box-project-myprojects-image delete-icon"
                :src="require('@/assets/img/projects/delete.svg')"
                :title="langFilter('delete')"
                alt="delete"
                @click="removeUser(user)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <itinerary-users-modal
    v-if="addingUsers !== -1"
    :lang="lang"
    :space="space"
    :projectId="projectId"
    :addingUsers="addingUsers"
    :users-not-in-project="usersToAdd"
    @restore-list="restoreUsersNotInProject($event)"
    @close-modal="addingUsers = -1"
    @add-users="getUsersInProject"
    @add-project-users="addProjectUsers($event)"
    @handle-unselect="hanldeUnselect($event)"
    @select-user-to-invite="selectUserToInvite($event)"
  />
  <alertMessage ref="alerts" :lang="lang"></alertMessage>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
import { ROLES_IN_PROJECT } from "@/constants";
const { TUTOR, MEMBER } = ROLES_IN_PROJECT;
export default {
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    editing: Boolean,
    space: {
      type: Object,
      default: () => ({}),
    },
    projectId: [String, Number],
    usersInProject: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["handleUsers"],
  data() {
    return {
      addingUsers: -1,
      users: {
        tutors: [],
        members: [],
      },
      usersToAdd: [],
      usersToRemove: {
        members: [],
        tutors: [],
      },
      logged_user_email: "",
      errors: [],
      sorted: "name",
      sortDirection: {
        name: false,
        email: false,
        id: false,
      },
      ROLES: {
        tutor: TUTOR,
        member: MEMBER,
      },
      traducciones: [
        {
          name: "projMembers",
          es: "MIEMBROS DEL PROYECTO",
          en: "PROJECT MEMBERS",
        },
        {
          name: "members",
          es: "Miembro",
          en: "Member",
        },
        {
          name: "members_desc",
          es: "Miembro: Ejecuta y desarrolla el proyecto",
          en: "Member: Execute and develope the project",
        },
        {
          name: "tutors",
          es: "Tutor",
          en: "Tutor",
        },
        {
          name: "tutors_desc",
          es: "Tutor: Da feedback y deja comentarios en las respuestas del miembro",
          en: "Tutor: Give feedback and write comments in the member's answers",
        },
        {
          name: "name",
          es: "Nombre",
          en: "Name",
        },
        {
          name: "role",
          es: "Rol",
          en: "Role",
        },
        {
          name: "status",
          es: "Estado",
          en: "Status",
        },
        {
          name: "Ups... Something went wrong!",
          es: "Ups... Algo ha salido mal!",
          en: "Ups... Something went wrong!",
        },
        {
          name: "Please try again later",
          es: "Por favor inténtalo de nuevo más tarde",
          en: "Please try again later",
        },
        {
          name: "UsersErrorTitle",
          es: "Hubo un error con los usuarios",
          en: "There has been an error with the users",
        },
        {
          name: "UsersErrorDeleteText",
          es: "No se ha podido eliminar el usuario, inténtalo de nuevo más tarde",
          en: "That has been a problem deleting this user, please try again later",
        },
        {
          name: "UsersIn",
          es: "Usuario dentro del espacio",
          en: "User inside the space",
        },
        {
          name: "UsersInvited",
          es: "Usuario invitado al espacio",
          en: "User invited to the space",
        },
        {
          name: "UsersDeleted",
          es: "Usuario preparado para ser eliminado",
          en: "User ready to be deleted",
        },
        {
          name: "delete",
          es: "Eliminar",
          en: "Delete",
        },
        {
          name: "recover",
          es: "Recuperar usuario",
          en: "Recover user",
        },
      ],
    };
  },
  computed: {
    isNewProject() {
      return !this.projectId;
    },
    allUsers() {
      return [...this.users.tutors, ...this.users.members];
    },
    sortedUsers() {
      return [
        ...this.allUsers,
        ...this.usersToRemove.members,
        ...this.usersToRemove.tutors,
      ]
        .slice()
        .sort((a, b) => {
          const aValue = a.name ? a.name.toLowerCase() : "";
          const bValue = b.name ? b.name.toLowerCase() : "";
          return aValue.localeCompare(bValue);
        });
    },
  },
  async created() {
    try {
      await this.getUsersInProject();
      await this.getUsersNotInProject();
      this.checkUserRoles();
    } catch (error) {
      this.openErrorsAlert(
        this.langFilter("Ups... Something went wrong!"),
        this.langFilter("Please try again later")
      );
      console.error("Error creating users component", error);
    }
  },
  methods: {
    getAvatar(avatar) {
      if (avatar != null) {
        if (avatar.includes("http")) {
          return avatar;
        }
        return process.env.VUE_APP_API_STORAGE + avatar;
      } else {
        return "/img/login_register/Avatar4.png";
      }
    },
    restoreUsersNotInProject(data) {
      this.usersToAdd.push(...data);
    },
    removeUser(user) {
      const userType = user.role === TUTOR ? "tutors" : "members";
      if (!this.users[userType]) {
        this.openErrorsAlert(
          this.langFilter("UsersErrorTitle"),
          this.langFilter("UsersErrorDeleteText")
        );
        return;
      }
      let index = this.users[userType].findIndex((u) => u.id === user.id);
      if (index === -1) {
        const otherUserType = userType === "tutors" ? "members" : "tutors";
        index = this.users[otherUserType].findIndex((u) => u.id === user.id);
      } else if (index !== -1) {
        const userTypeToRemove =
          index < this.users[userType].length
            ? userType
            : userType === "tutors"
              ? "members"
              : "tutors";
        const [removedUser] = this.users[userTypeToRemove].splice(index, 1);
        this.usersToRemove[userTypeToRemove] = [
          ...(this.usersToRemove[userTypeToRemove] || []),
          removedUser,
        ];
      } else {
        this.openErrorsAlert(
          this.langFilter("UsersErrorTitle"),
          this.langFilter("UsersErrorDeleteText")
        );
      }
    },
    addProjectUsers({ type, data }) {
      this.users[type] = [...this.users[type], ...data];
      const dataIds = new Set(data.map(({ id }) => id));
      this.usersToRemove[type] =
        this.usersToRemove[type]?.filter(({ id }) => !dataIds.has(id)) || [];

      this.$emit("handleUsers", {
        data: this.users,
      });
    },
    selectUserToInvite({ id: userId }) {
      this.usersToAdd = this.usersToAdd.filter(({ id }) => id !== userId);
    },
    hanldeUnselect(user) {
      this.usersToAdd = [...this.usersToAdd, user];
    },
    openErrorsAlert(title, text) {
      this.$refs.alerts.title = title;
      this.$refs.alerts.text = text;

      if (this.errors.length == 0) {
        this.errors.push("error");
        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
        }, 12000);
      }
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);
      this.errors = [];
    },
    async getUsersInProject() {
      const { members = [], tutors = [] } = this.usersInProject || {};
      if (members.length || tutors.length || this.isNewProject) {
        this.users = this.usersInProject;
        return;
      }

      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/getUsersBySpaceInProject/${this.space.id}/${this.projectId}`
        );

        const { members, tutors } = data;
        this.users = {
          tutors,
          members,
        };

        this.$emit("handleUsers", { data: this.users });
      } catch (error) {
        throw new Error("Error getting users in project", { cause: error });
      }
    },
    async getUsersNotInProject() {
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/getUsersBySpaceNotInProject/${this.space.id}/${this.projectId}`
        );
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/edit-profile/get_user`
        );
        this.logged_user = response.data.email;
        this.usersToAdd = data.users.filter(
          (user) => user.email !== this.logged_user
        );
      } catch (error) {
        throw new Error("Error getting users not in project", { cause: error });
      }
    },
    changeRole(event, user) {
      const selectedValue = parseInt(event.target.value);
      const newRole = selectedValue;
      const currentType = user.role === TUTOR ? "tutors" : "members";

      if (user.role !== newRole) {
        const newType = newRole === TUTOR ? "tutors" : "members";

        const index = this.users[currentType].findIndex(
          (u) => u.id === user.id
        );

        if (index !== -1) {
          const [movedUser] = this.users[currentType].splice(index, 1);

          movedUser.role = newRole;

          this.users[newType].push(movedUser);

          user.role = newRole;
        }
      }
    },
    sortUsers(type) {
      this.sorted = type;

      this.sortDirection[type] = !this.sortDirection[type];
    },
    recoverUser(user) {
      const userType = user.role === TUTOR ? "tutors" : "members";

      const indexToRemove = this.usersToRemove[userType].findIndex(
        (u) => u.id === user.id
      );
      if (indexToRemove !== -1) {
        const [removedUser] = this.usersToRemove[userType].splice(
          indexToRemove,
          1
        );

        this.users[userType].push(removedUser);

        this.$emit("handleUsers", {
          data: this.users,
        });
      }
    },
    checkUserRoles() {
      this.allUsers.forEach((user) => {
        if (
          user.role === MEMBER &&
          this.users.tutors.some((u) => u.id === user.id)
        ) {
          user.role = TUTOR;
        } else if (
          user.role === TUTOR &&
          this.users.members.some((u) => u.id === user.id)
        ) {
          user.role = MEMBER;
        }
      });
    },
  },
};
</script>
