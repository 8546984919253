<script setup>
import ManageSpace from "../components/space/ManageSpaceComponent.vue";
</script>

<template>
  <ManageSpace v-if="access" />
</template>

<script>
import axios from "axios";
import translationMixin from "../mixins/translationMixin.js";

export default {
  name: "ManageSpaceView",
  mixins: [translationMixin],
  inject: ["spaceConfig"],
  emits: ["openAlert"],
  data() {
    return {
      access: false,
      traducciones: [
        {
          name: "noAccessToManageSpaces",
          es: "No tienes acceso a la gestión de espacios",
          en: "You do not have access to manage spaces",
        },
        {
          name: "errorCheckingAccess",
          es: "Error comprobando acceso al gestor de espacios",
          en: "Error checking access to manage spaces",
        },
      ],
    };
  },
  async created() {
    if (this.spaceConfig.isTrialFinished) {      
      return;
    }
    
    const url = `${process.env.VUE_APP_API_URL}/manageSpaces/access`;

    try {
      const res = await axios.get(url);
      if (!res.data.access) {
        this.openAlert("noAccessToManageSpaces");

        this.$router.replace("/");
      } else {
        this.access = res.data.access;
      }
    } catch (error) {
      console.error("Error fetching access:", error);
      this.openAlert("errorCheckingAccess");
      this.$router.replace("/");
    }
  },
  methods: {
    openAlert(msg) {
      this.$emit("openAlert", {
        text: this.langFilter(msg),
        successful: false,
      });
    },
  },
};
</script>
