<template>
  <div class="sol-center-header">
    <div
      :class="[
        { 'solution-closed-carrousel': !showHeader },
        'solutionCarrousel container-solutionCarrousel allign-center-projects',
      ]"
    >
      <carrousel
        ref="carrousel"
        :projectId="projectId"
        :loading="loading"
        :lang="lang"
        :space="space"
        page="solution"
        :showHeader="showHeader"
        :percentage="percentage"
        @change-project="changeProject($event)"
      />
      <tabs-container
        v-if="showHeader"
        :tabs="getTabs()"
        :selectedTab="selectedTab"
        @select-tab="selectTab($event)"
      />
      <div
        class="solution-close-header div-center"
        @click="showHeader = !showHeader"
      >
        <img
          v-if="showHeader"
          :src="require('@/assets/img/solution/arrow-up.svg')"
          alt="open/close"
          class="solution-arrow-header"
        />
        <img
          v-else
          :src="require('@/assets/img/solution/arrow-down.svg')"
          alt="open/close"
          class="solution-arrow-header"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Carrousel from "../general/CarrouselComponent.vue";
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
import generalMethodsMixin from "@/mixins/generalMethodsMixin";
import { ROLES_IN_PROJECT } from "@/constants";
const { TUTOR, MEMBER } = ROLES_IN_PROJECT;

export default {
  components: {
    Carrousel,
  },
  mixins: [translationMixin, generalMethodsMixin],
  inject: ['spaceConfig'],
  props: {
    mapOpened: {
      type: Boolean,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    solutionId: {
      type: Number,
      required: true,
    },
    stationId: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    percentage: {
      type: Number,
      required: true,
    },
    space: {
      type: Object,
      required: true,
    },
    role: {
      type: Number,
      required: true,
    },
    selectedTab: {
      type: Number,
      required: true,
    },
  },
  emits: ["selectTab", "changeProject"],
  data() {
    return {
      projectCarrousel: [],
      selectOpened: false,
      initCarrousel: 0,
      finalCarrousel: 3,
      showHeader: true,
      percentagesSecondaries: [0, 0, 0],
      traducciones: [
        {
          name: "itinerario",
          es: "Itinerario",
          en: "Itinerary",
        },
        {
          name: "assistant",
          es: "Asistente",
          en: "Assistant",
        },
        {
          name: "expert",
          es: "Experto",
          en: "Expert",
        },
        {
          name: "evaluatorAssistant",
          es: "Evaluador",
          en: "Assistant",
        },
      ],
    };
  },
  computed: {
    isTutor() {
      return this.role === TUTOR;
    },
    isMember() {
      return this.role === MEMBER;
    },
  },
  created() {
    this.getTabs();
  },
  methods: {
    getTabs() {
      const tabs = [
        { label: this.langFilter("itinerario"), value: 0 },
        { label: this.langFilter("expert"), value: 1 },
        { label: this.langFilter("evaluatorAssistant"), value: 2 },
      ];

      if (this.role !== null) {
        if (this.spaceConfig.hasRestrictions(this.space.id)) {
          const optionSpace = this.spaceConfig.getOptionsBySpaceId(this.space.id);
          const tabRole = this.isTutor ? optionSpace.tutor.tabs : optionSpace.member.tabs;

          return tabs.filter(tab => tabRole.includes(tab.value));
        }

        return tabs;
      }
    },
    selectTab(tabIndex) {
      this.$emit("selectTab", tabIndex);
    },
    loadCarrousel() {
      this.$refs.carrousel.on_created_carrousel();
    },
    changeProject(projectId) {
      this.$parent.loading = true;

      axios
        .get(
          `${process.env.VUE_APP_API_URL}/solution/getFirstStationSolutionByProject`,
          {
            params: {
              project_id: projectId,
            },
          }
        )
        .then((response) => {
          const { solution, station } = response?.data || {};

          const solutionId = solution ?? 0;
          const stationId = station ?? 0;

          this.$emit("changeProject", { projectId, stationId, solutionId });
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
