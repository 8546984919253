<template>
  <div>
    <div
      class="sidebar-option-content"
      @mouseover="$parent.sidebarHover(optionIndex)"
      @mouseleave="$parent.leaveSidebarHover(optionIndex)"
    >
      <a
        :href="getUrl(optionIndex)"
        :class="{
          'sidebar-option-content-disabled': isDisabled(optionIndex),
        }"
        :title="isDisabled() ? langFilter('OptionDisabled') : langFilter(option.name)"
        @click.prevent="goToUrl(getUrl(optionIndex))"
      >
        <div>
          <img
            v-show="optionSelected"
            :src="require(`@/assets/img/sidebar/hovers/${option.img}.svg`)"
            class="sidebar-option-image"
            alt="sidebar option"
          />
          <img
            v-show="!optionSelected"
            :src="require(`@/assets/img/sidebar/logos/${option.img}.svg`)"
            class="sidebar-option-image"
            alt="sidebar option"
          />
        </div>
        <p
          class="sidebar-option-text"
          :class="{
            'sidebar-option-selected': optionSelected,
          }"
        >
          {{ langFilter(option.name) }}
        </p>
      </a>
    </div>
    <div
      v-if="optionIndex !== $parent.page"
      :id="`sidebar-mouse-over-${optionIndex}`"
      class="sidebar-mouse-over"
    ></div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
export default {
  mixins: [translationMixin],
  inject: ['spaceConfig'],
  props: {
    option: {
      type: Object,
      required: true,
    },
    optionIndex: {
      type: Number,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    optionSelected: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      traducciones: [
        {
          name: "Home",
          es: "Home",
          en: "Home",
        },
        {
          name: "Projects",
          es: "Proyectos",
          en: "Projects",
        },
        {
          name: "Programs",
          es: "Programas",
          en: "Programs",
        },
        {
          name: "Itinerary",
          es: "Plantillas",
          en: "Templates",
        },
        {
          name: "Forum",
          es: "Foro",
          en: "Forum",
        },
        {
          name: "spaces",
          es: "Espacios",
          en: "Spaces",
        },
        {
          name: "Admin Panel",
          es: "Admin Panel",
          en: "Admin Panel",
        },
        {
          name: "Assistants",
          es: "Asistentes",
          en: "Assistants",
        },
        {
          name: "OptionDisabled",
          es: "Deshabilitado",
          en: "Disabled",
        },
      ],
    };
  },
  methods: {
    getUrl(optionIndex) {
      switch (optionIndex) {
        case 0:
          return "/home";
        case 1:
          return "/programs";
        case 2:
          return "/my-projects";
        case 3:
          return "/templates";
        case 4:
          return "/forum";
        case 5:
          return "/adminpanel";
        case 6:
          return "/manageSpaces";
        case 7:
          return "/assistants";
      }
    },
    goToUrl(url) {
      if (url.includes("forum") && this.$route.path.includes("forum-thread")) {
        window.location.href = "/forum";
      } else {
        this.$router.push(url, { force: true });
      }
    },
    isDisabled(index) {
      if (this.spaceConfig.isTrialFinished) {
        return true;
      }

      if (this.spaceConfig.isTrialEnabled) {
        return index == 5;
      }
    },
  },
};
</script>

<style lang="css" scoped>
  .sidebar-option-content-disabled {
    opacity: 0.5;
  }

  .sidebar-option-content-disabled:active {
    pointer-events: none;
  }

  .sidebar-option-content-disabled:hover {
    cursor: not-allowed;
  }
</style>