<template>
  <div>
    <div id="trackModal" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div
          class="modal-content modal-track center-modal"
          style="height: auto; text-align: left"
        >
          <div
            v-if="track.img != null"
            :style="`background-image: url('${getTrackHeader(track.img)}'); `"
            class="modal-track-header banner-track-img"
          >
            <img
              class="close-button"
              :src="require('@/assets/img/track/close-modal.png')"
              alt="close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <span class="track-name-modal">{{ track.name }}</span>
          </div>
          <div class="modal-body box-shadow-modal">
            <div class="desc-track-modal">
              {{ track.description }}
            </div>
            <div class="data-track-modal">
              <span class="data-track-name"
                >{{ langFilter("categoria") }}:</span
              >
              <span v-if="selTrackCat == '' || selTrackCat == null">{{
                langFilter("sin_cat")
              }}</span>
              <span v-else>{{ selTrackCat[0].name }}</span> <br />
            </div>
          </div>
          <div class="modal-final">
            <span v-if="$parent.selectorModal == 0" class="index-modal-track">{{
              langFilter("indice")
            }}</span>
            <span v-else class="index-modal-track">{{
              langFilter("recursos")
            }}</span>
            <select
              id="select-modal-track"
              class="select-modal-track"
              @change="selectorTrack($event)"
            >
              <option value="0" class="option-modal-track">
                {{ langFilter("itinerario") }}
              </option>
              <option value="1" class="option-modal-track">
                {{ langFilter("recursos") }}
              </option>
            </select>
            <div class="track-rec-it-div">
              <trackItinerary
                v-show="$parent.selectorModal == 0"
                :trackStatSol="trackStatSol"
                :track="track"
                :itinerary="itinerary"
                :questions="selQuestions"
                :lang="lang"
              />
              <trackResources
                v-show="$parent.selectorModal != 0"
                :resources="selResources"
                :resourcesStat="selResourcesStat"
                :lang="lang"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import trackResources from "./TrackResourcesComponent.vue";
import trackItinerary from "./TrackItineraryComponent.vue";
import $ from "jquery";
import axios from "axios";

export default {
  components: {
    trackResources,
    trackItinerary,
  },

  mixins: [translationMixin],
  inject: ['spaceConfig'],

  props: {
    lang: {
      type: String,
      required: true,
    },
    track: {
      type: Object || String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selTrackCat: "",
      selResources: "",
      selResourcesStat: "",
      selQuestions: "",
      itinerary: [],
      resources: [],
      resourcesStat: [],
      questions: [],
      trackStatSol: [],
      traducciones: [
        {
          name: "indice",
          es: "Índice",
          en: "Index",
        },
        {
          name: "sin_cat",
          es: "Sin categoría",
          en: "No category",
        },
        {
          name: "itinerario",
          es: "Plantilla",
          en: "Template",
        },
        {
          name: "preguntas",
          es: "Preguntas",
          en: "Questions",
        },
        {
          name: "recursos",
          es: "Recursos",
          en: "Resources",
        },
        {
          name: "categoria",
          es: "Categoría",
          en: "Category",
        },
        {
          name: "duracion",
          es: "Duración",
          en: "Duration",
        },
        {
          name: "creacion",
          es: "Creación",
          en: "Creation",
        },
        {
          name: "select",
          es: "Seleccionar Track",
          en: "Select Track",
        },
      ],
    };
  },

  watch: {
    track(val) {
      let template_id = val.id;
      if (val.template_id !== undefined) template_id = val.template_id;

      this.selTrackCat = this.categories.filter(
        (item) => val.category_id == item.id
      );

      this.itinerary = this.trackStatSol.filter(
        (item) => item.track_id == template_id
      );

      this.itinerary = this.itinerary.reduce((r, a) => {
        r[a.station_id] = [...(r[a.station_id] || []), a];
        return r;
      }, {});

      let new_itinerary = [];

      $.each(this.itinerary, function (index, item) {
        new_itinerary.push(item);
      });

      this.itinerary = new_itinerary;

      let reorder = [];

      this.itinerary.forEach((element, index) => {
        reorder.push({
          solution_order: element[0].solution_order,
          index: index,
        });
      });

      reorder.sort(function (a, b) {
        return a.solution_order - b.solution_order;
      });

      new_itinerary = [];

      reorder.forEach((element) => {
        new_itinerary.push(this.itinerary[element.index]);
      });

      this.itinerary = new_itinerary;

      this.selQuestions = this.questions.filter(
        (item) => item.track_id == template_id
      );

      this.selQuestions = this.selQuestions.reduce((r, a) => {
        r[a.solution_id] = [...(r[a.solution_id] || []), a];
        return r;
      }, {});

      this.selResources = this.resources.filter(
        (item) => item.track_id == template_id
      );

      this.selResourcesStat = this.resourcesStat.filter(
        (item) => item.track_id == template_id
      );
    },
  },

  created() {
    axios
      .post(`${process.env.VUE_APP_API_URL}/tracks/getTrackStatSol`)
      .then((response) => {
        this.trackStatSol = response.data;
      });
    axios
      .post(`${process.env.VUE_APP_API_URL}/tracks/getTrackQuestions`)
      .then((response) => {
        this.questions = response.data;
      });
    axios
      .post(
        `${process.env.VUE_APP_API_URL}/tracks/getTrackResourcesBySolutions`
      )
      .then((response) => {
        this.resources = response.data;
      });
    axios
      .post(`${process.env.VUE_APP_API_URL}/tracks/getTrackResourcesByStations`)
      .then((response) => {
        this.resourcesStat = response.data;
      });
  },

  methods: {
    getTrackHeader(track) {
      return `${process.env.VUE_APP_API_STORAGE}/img/track_headers/${track}`;
    },
    selectorTrack($event) {
      this.$parent.selectorModal = $event.target.value;
    },
  },
};
</script>
