<template>
  <chat-with-gpt v-if="chatOpened" :lang="lang" />
  <div class="gpt-chatbot-container" :class="{ 'alex-logo-chatbot-disabled': isDisabled() }">
    <div @click="chatToggle">
      <img
        class="alex-logo-chatbot"
        :src="chatOpened ? CloseGPT : Alex"
        alt="Alex"
        :title="isDisabled() ? langFilter('AlexChatbotDisabled') : 'Alex'"
      />
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import Alex from "./images/alex.webp";
import CloseGPT from "../solution/images/close-gpt.png";

export default {
  mixins: [translationMixin],
  inject: ['spaceConfig'],
  props: {
    lang: {
      type: String,
      required: true,
    },
    spaceId: {
      type: Number,
      required: true,
    },
    space: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      CloseGPT: CloseGPT,
      Alex: Alex,
      chatOpened: false,
      traducciones: [
        {
          name: "AlexChatbotDisabled",
          es: "Deshabilitado",
          en: "Disabled",
        },
      ],
    };
  },
  methods: {
    chatToggle() {
      this.chatOpened = !this.chatOpened;
    },
    isDisabled() {
      return this.spaceConfig.isTrialFinished;
    }
  }
};
</script>

<style lang="css" scoped>
  .alex-logo-chatbot-disabled {
    opacity: 0.5;
  }

  .alex-logo-chatbot-disabled:active {
    pointer-events: none;
  }

  .alex-logo-chatbot-disabled:hover {
    cursor: not-allowed;
  }
</style>
