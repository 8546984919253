<template>
  <div class="modal-container-general">
    <div class="modal-content modal-content-resources">
      <div class="modal-header-resources">
        <h5 class="modal-title-resources">
          {{ langFilter("resources") }}
        </h5>
        <button class="confirmation-close-btn" @click="checkChanges" />
      </div>
      <div class="modal-body-resources">
        <div v-if="itinerary" class="modal-right-direction">
          <button class="modal-filter-button" @click="showFilter = !showFilter">
            <img
              style="margin-right: 8px"
              :src="require('@/assets/img/projects/filter.svg')"
            />
            <span class="modal-filter-text">{{ langFilter("filter") }}</span>
          </button>
          <button class="modal-lupa-button">
            <img :src="require('@/assets/img/general/lupa-purple.svg')" />
            <input
              v-model="filterSearch"
              :placeholder="langFilter('search')"
              class="modal-input-search"
              type="text"
              @keyup="filterResources"
            />
          </button>
          <div v-if="showFilter" class="modal-filter-modal">
            <div
              style="
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
              "
              @click="clickSelectAll"
            >
              <div
                class="modal-filter-tick"
                :style="
                  selectAll ? 'background: #2A3B5A;' : 'background: #D9D9D9;'
                "
                style="margin-left: 10px"
              >
                <img
                  :src="require('@/assets/img/projects/tick.svg')"
                  alt="tick"
                />
              </div>
              <span class="modal-filter-text-modal" style="width: auto">{{
                langFilter("selectAll")
              }}</span>
            </div>
            <div
              v-for="(chapters, chapterIndex) in updatedThemes"
              :key="'filter-' + chapterIndex"
              class="modal-each-modal-filter"
              @click="checkFilter(chapterIndex)"
            >
              <div class="modal-filter-text-modal">
                {{
                  langFilter("theme") +
                  " " +
                  (chapterIndex + 1) +
                  ". " +
                  chapters.title
                }}
              </div>
              <div
                class="modal-filter-tick"
                :style="
                  chapters.tick
                    ? 'background: #2A3B5A;'
                    : 'background: #D9D9D9;'
                "
              >
                <img
                  :src="require('@/assets/img/projects/tick.svg')"
                  alt="tick"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-for="(theme, themeIndex) in theme" :key="themeIndex">
          <div class="modal-div-resources">
            <button
              v-if="edit"
              class="new-btn-purple-add"
              @click="newResourceModal(themeIndex, null)"
            />
            <p>
              {{ langFilter("theme") }} {{ themeIndex + 1 }}.
              <span class="resource-bold-name">{{ theme.title }}</span>
            </p>
          </div>
          <draggable
            v-model="updatedThemes[themeIndex].resources"
            draggable=".resource"
            handle=".dragg-me-task"
            animation="500"
            ghostClass="ghost"
            chosenClass="chosen"
            :options="{
              animation: 500,
              handle: '.dragg-me-task',
              ghostClass: 'ghost',
              chosenClass: 'chosen',
              group: 'resource',
              itemKey: 'id',
            }"
          >
            <div
              v-for="(res, resourceIndex) in getThemeResources(theme)"
              :key="resourceIndex"
              class="modal-div-resources-link resource"
            >
              <div class="content-resource-div">
                <div :class="getResourceIcon(res.link)" />
                <a :href="getLinkRes(res.link)" target="_blank">
                  <span class="resource-name">
                    {{ res.name }}
                  </span>
                </a>
              </div>
              <new-template-creation-options
                v-if="edit"
                class="new-template-padding-question"
                :edit="true"
                :lang="lang"
                :themeIndex="themeIndex"
                :resourceIndex="resourceIndex"
                :type="3"
                @click="selectResource(themeIndex, null, resourceIndex, res)"
                @open-modal-confirmation="
                  openModalConfirmation($event, res.resource_id)
                "
              />
            </div>
          </draggable>
          <div v-for="(apart, apartIndex) in theme.aparts" :key="apartIndex">
            <div class="modal-div-resources resources-apart-title">
              <button
                v-if="edit"
                class="new-btn-purple-add"
                @click="newResourceModal(themeIndex, apartIndex)"
              />
              <p>
                {{ langFilter("part") }}
                {{ apartIndex + 1 }}.
                <span class="resource-bold-name">{{ apart.title }}</span>
              </p>
            </div>

            <draggable
              v-model="updatedThemes[themeIndex].aparts[apartIndex].resources"
              draggable=".resource2"
              handle=".dragg-me-task"
              animation="500"
              ghostClass="ghost"
              chosenClass="chosen"
              :options="{
                animation: 500,
                handle: '.dragg-me-task',
                ghostClass: 'ghost',
                chosenClass: 'chosen',
                group: 'resource2',
                itemKey: 'id',
              }"
            >
              <div
                v-for="(res, resourceIndex) in getApartResources(apart)"
                :key="resourceIndex"
                class="modal-div-resources-link resources-apart-resource resource2"
              >
                <div class="content-resource-div">
                  <div :class="getResourceIcon(res.link)" />
                  <a :href="getLinkRes(res.link)" target="_blank">
                    <span class="resource-name">
                      {{ res.name }}
                    </span>
                  </a>
                </div>

                <new-template-creation-options
                  v-if="edit"
                  class="new-template-padding-question"
                  :edit="true"
                  :lang="lang"
                  :themeIndex="themeIndex"
                  :apartIndex="apartIndex"
                  :resourceIndex="resourceIndex"
                  :type="3"
                  @click="
                    selectResource(themeIndex, apartIndex, resourceIndex, res)
                  "
                  @open-modal-confirmation="
                    openModalConfirmation($event, res.resource_id)
                  "
                />
              </div>
            </draggable>
          </div>
          <hr
            v-if="themeIndex + 1 !== updatedThemes.length"
            class="separator-resources"
          />
        </div>
      </div>
      <div class="modal-div-footer">
        <button
          v-if="edit"
          :class="[
            // { 'modal-div-footer-btn-enabled': btnEnabled },
            { 'modal-div-footer-btn-enabled': true },
            'modal-div-footer-btn',
          ]"
          @click="confirmUpdate()"
        >
          {{ langFilter("save") }}
        </button>
      </div>
      <new-resource-modal
        v-if="showNewResourceModal"
        :editing="editing"
        :lang="lang"
        @new-resource-modal="openCloseNewResourceModal"
        @add-resource="addResource($event)"
      />
      <confirmation-modal
        v-if="confirmationModal"
        :lang="lang"
        :body="confirmationModalBody"
        :fixed="true"
        @close-confirmation="showConfirmationModal"
        @confirm="confirmationConfirm"
        @cancel="confirmationCancel"
      />
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
export default {
  props: [
    "lang",
    "themes",
    "title",
    "resource",
    "edit",
    "itinerary",
    "savedResources",
  ],
  emits: ["clearResources", "closeResources"],
  data() {
    return {
      showNewResourceModal: false,
      indexesSelected: [],
      confirmationModal: false,
      editing: null,
      confirmationData: "",
      closeResources: false,
      showFilter: false,
      filterSearch: "",
      selectAll: true,
      editIndex: null,
      updatedThemes: [],
      newResources: [],
      resourcesDeleted: [],
      btnEnabled: false,
      editedCount: 0,
      myResources: [],
      resourceIdToDelete: null,
      confirmationModalBody: "",
      traducciones: [
        {
          name: "resources",
          es: "Recursos",
          en: "Resources",
        },
        {
          name: "save",
          es: "Guardar",
          en: "Save",
        },
        {
          name: "part",
          es: "Apartado",
          en: "Part",
        },
        {
          name: "theme",
          es: "Tema",
          en: "Theme",
        },
        {
          name: "filter",
          es: "Filtrar",
          en: "Filter",
        },
        {
          name: "selectAll",
          es: "Seleccionar todo",
          en: "Select all",
        },
        {
          name: "search",
          es: "Buscar...",
          en: "Search...",
        },
      ],
    };
  },
  created() {
    this.updatedThemes = JSON.parse(JSON.stringify(this.themes));

    // change parts for aparts in the json
    this.updatedThemes.forEach((theme) => {
      if (theme.parts) {
        theme.aparts = theme.parts;
        delete theme.parts;
      }
    });

    this.theme = this.updatedThemes;
    if (this.savedResources.length > 0) {
      this.myResources = this.savedResources;
    } else {
      // get current url
      let url = window.location.href;
      let id = 0;
      if (url.includes("new-template")) {
        const newUrl = url.split("/");
        id = newUrl[newUrl.length - 1];
        url = `/getResourcesByTemplate/${id}`;
      } else if (url.includes("itinerary")) {
        const newUrl = url.split("/");
        id = newUrl[newUrl.length - 1];
        url = `/getResourcesByProject/${id}`;
      } else if (url.includes("parts")) {
        const data = window.location.pathname.split("/");
        id = parseInt(data[2]);
        url = `/getResourcesByProject/${id}`;
      } else {
        const newUrl = url.split("/");
        id = newUrl[newUrl.length - 1];
        url = `/getResourcesByTrack/${id}`;
      }
      axios.get(process.env.VUE_APP_API_URL + url).then((response) => {
        const newResources = [];
        response.data[0].forEach((el) => {
          newResources.push({
            resource_id: el.resource_id,
            name: el.resource,
            link: el.link,
            solution_id: el.solution_id,
            track_id: id,
            station_id: el.station_id,
          });
        });

        response.data[1].forEach((el) => {
          // check if resource is already in array
          if (
            !newResources.find(
              (item) =>
                item.resource_id == el.resource_id &&
                item.solution_id == el.solution_id
            )
          )
            newResources.push({
              resource_id: el.resource_id,
              name: el.resource,
              link: el.link,
              solution_id: el.solution_id,
              track_id: id,
              station_id: el.station_id,
            });
        });
        response.data[2].forEach((el) => {
          // check if resource is already in array
          if (!newResources.find((item) => item.resource_id == el.resource_id))
            newResources.push({
              resource_id: el.resource_id,
              name: el.resource,
              link: el.link,
              solution_id: el.solution_id,
              track_id: id,
              station_id: el.station_id,
            });
        });
        response.data[3].forEach((el) => {
          // check if resource is already in array
          if (!newResources.find((item) => item.resource_id == el.resource_id))
            newResources.push({
              resource_id: el.resource_id,
              name: el.resource,
              link: el.link,
              solution_id: el.solution_id,
              track_id: id,
              station_id: el.station_id,
            });
        });
        this.myResources = newResources;

        if (this.edit) {
          this.$emit("saveResources", newResources);
        }
      });
    }
  },
  methods: {
    getResourceIcon(link) {
      if (link.includes("api.acceleralia.com")) return "link-file-div";
      return "link-icon-div";
    },
    getLinkRes(link) {
      if (link.startsWith("/")) {
        link = link.substring(1);
        const newLink = process.env.VUE_APP_API_URL + link;
        return newLink.replace("apifiles", "files");
      }
      return link;
    },
    getApartResources(apart) {
      const resources = [];
      if (apart.id === undefined) {
        apart.id = apart.preId;
      }
      this.myResources.forEach((resource) => {
        if (resource.solution_id === apart.id) {
          resources.push(resource);
        }
      });
      return resources;
    },
    getThemeResources(theme) {
      const resources = [];
      this.myResources.forEach((resource) => {
        if (theme.id === undefined) {
          theme.id = theme.preId;
        }
        if (
          resource.station_id === theme.id &&
          (resource.solution_id === null || resource.solution_id === undefined)
        ) {
          resources.push(resource);
        }
      });
      return resources;
    },
    checkChanges() {
      if (
        this.resourcesDeleted.length === 0 &&
        this.newResources.length === 0 &&
        this.editedCount === 0
      )
        this.$emit("closeResources");
      else this.confirmationClose();
    },
    clickSelectAll() {
      !this.selectAll
        ? this.updatedThemes.map((theme) => (theme.tick = true))
        : this.updatedThemes.map((theme) => (theme.tick = false));
      this.theme = this.updatedThemes.filter((theme) => theme.tick);
      this.selectAll = !this.selectAll;
    },
    filterResources() {
      this.theme = this.updatedThemes.filter((theme) =>
        theme.title.toLowerCase().includes(this.filterSearch.toLowerCase())
      );
    },
    checkFilter(index) {
      this.updatedThemes[index];
      if (!this.updatedThemes[index]["tick"])
        this.updatedThemes[index]["tick"] = true;
      else this.updatedThemes[index]["tick"] = false;
      this.theme = this.updatedThemes.filter((theme) => theme.tick);
      this.selectAll = false;
    },
    confirmUpdate() {
      //   if (this.btnEnabled) {
      this.$emit("updateResources", this.updatedThemes);
      this.$emit("resourcesChanged", this.newResources, this.resourcesDeleted);
      this.$emit("deleteResources", this.resourcesDeleted);
      this.updatedThemes = [];
      this.resourcesDeleted = [];
      this.newResources = [];

      if (this.edit) {
        this.$emit("saveResources", this.myResources);
      }
      //}
    },
    selectResource(themeIndex, apartIndex, resourceIndex, resource) {
      this.editIndex = [themeIndex, apartIndex, resourceIndex, resource];
      this.editing = this.myResources.find(
        (res) => res.resource_id === resource.resource_id
      );
    },
    showConfirmationModal() {
      this.confirmationModal = !this.confirmationModal;
    },
    confirmationClose() {
      if (this.edit) {
        this.confirmationModalBody = "noSave";
        this.confirmationModal = true;
        this.closeResources = true;
      } else {
        this.$emit("closeResources");
      }
    },
    confirmationCancel() {
      this.closeResources = false;
      this.confirmationData = "";
      this.showConfirmationModal();
    },
    confirmationConfirm() {
      this.showConfirmationModal();
      if (this.closeResources) {
        this.newResources.forEach((resource) => {
          this.deleteResourceDb(resource);
        });
        this.$emit("clearResources", this.newResources);
        this.$emit("closeResources");
      } else {
        this.deleteResource();
      }
      this.closeResources = false;
    },
    checkIfResourcesNamesAreEmpty() {
      let empty = false;
      this.updatedThemes.forEach((theme) => {
        theme.resources.forEach((resource) => {
          if (resource.name === "") {
            empty = true;
          }
        });
        theme.aparts.forEach((apart) => {
          apart.resources.forEach((resource) => {
            if (resource.name === "") {
              empty = true;
            }
          });
        });
      });
      return empty;
    },
    checkIfResourceNameIsEmpty(themeIndex, apartIndex, resourceIndex) {
      if (apartIndex == null) {
        return this.updatedThemes[themeIndex].resources[resourceIndex].name ===
          ""
          ? true
          : false;
      } else {
        return this.updatedThemes[themeIndex].aparts[apartIndex].resources[
          resourceIndex
        ].name === ""
          ? true
          : false;
      }
    },
    updateResourceName(event, themeIndex, apartIndex, resourceIndex) {
      const value = event.target.innerText;
      if (apartIndex == null) {
        this.updatedThemes[themeIndex].resources[resourceIndex].name = value;
        this.myResources.forEach((resource) => {
          if (resource.solution_id === this.updatedThemes[themeIndex].id) {
            resource.name = value;
          }
        });
      } else {
        this.updatedThemes[themeIndex].aparts[apartIndex].resources[
          resourceIndex
        ].name = value;
        this.myResources.forEach((resource) => {
          if (
            resource.solution_id ===
            this.updatedThemes[themeIndex].aparts[apartIndex].id
          ) {
            resource.name = value;
          }
        });
      }
    },
    addResource(resource) {
      let url = window.location.href;
      if (!this.newResources.includes(resource.id) && this.editIndex === null) {
        this.newResources.push(resource.id);
      }
      this.openCloseNewResourceModal();
      if (this.editIndex !== null) {
        this.editedCount++;
        this.btnEnabled = true;
        if (this.editIndex[1] === null) {
          this.updatedThemes[this.editIndex[0]].resources[this.editIndex[2]] =
            resource;
          this.editIndex = null;
          this.editing = null;
          // edit resource in myResources
          this.myResources.forEach((myResource) => {
            if (myResource.resource_id === resource.id) {
              myResource.name = resource.name;
              myResource.link = resource.link;
            }
          });
          return;
        }

        if (url.includes("new-template"))
          this.updatedThemes[this.editIndex[0]].aparts[
            this.editIndex[1]
          ].resources[this.editIndex[2]] = resource;
        this.editIndex = null;
        this.editing = null;

        // edit resource in myResources
        this.myResources.forEach((myResource) => {
          if (myResource.resource_id === resource.id) {
            myResource.name = resource.name;
            myResource.link = resource.link;
          }
        });
        return;
      }
      if (this.indexesSelected[1] === null) {
        let apId = this.updatedThemes[this.indexesSelected[0]].id;
        apId =
          apId === undefined
            ? this.updatedThemes[this.indexesSelected[0]].preId
            : apId;
        this.myResources.push({
          resource_id: resource.id,
          name: resource.name,
          link: resource.link,
          solution_id: null,
          track_id: null,
          station_id: apId,
        });
        this.updatedThemes[this.indexesSelected[0]].resources.push(resource);
      } else {
        let apId =
          this.updatedThemes[this.indexesSelected[0]].aparts[
            this.indexesSelected[1]
          ].id;
        apId =
          apId === undefined
            ? this.updatedThemes[this.indexesSelected[0]].aparts[
                this.indexesSelected[1]
              ].preId
            : apId;
        this.myResources.push({
          resource_id: resource.id,
          name: resource.name,
          link: resource.link,
          solution_id: apId,
          track_id: null,
          station_id: this.updatedThemes[this.indexesSelected[0]].id,
        });
        this.updatedThemes[this.indexesSelected[0]].aparts[
          this.indexesSelected[1]
        ].resources.push(resource);
      }
      this.indexesSelected = [];
      this.btnEnabled = true;
    },
    openCloseNewResourceModal() {
      this.showNewResourceModal = !this.showNewResourceModal;
    },
    newResourceModal(themeIndex, apartIndex) {
      this.indexesSelected = [themeIndex, apartIndex];
      this.editing = null;
      this.openCloseNewResourceModal();
    },
    openModalConfirmation(data, resource_id) {
      this.resourceIdToDelete = resource_id;
      if (data[1] !== 3) {
        this.newResourceModal(0, null);
      } else {
        this.confirmationModalBody = "deleteSure";
        this.confirmationData = data;
        this.showConfirmationModal();
      }
    },
    deleteResourceDb(id) {
      //   axios.delete(
      //     `${process.env.VUE_APP_API_URL}/resources/deleteResource/${id}`
      //   );
    },
    deleteResource() {
      //   const data = this.confirmationData;
      //   const index = data[2];
      //   if (index[1] == null) {
      //     if (this.updatedThemes[index[0]].resources[index[3]] !== undefined) {
      //       this.myResources = this.myResources.filter(
      //         (resource) =>
      //           resource.resource_id !==
      //           this.updatedThemes[index[0]].resources[index[3]].id
      //       );
      //       this.resourcesDeleted.push(
      //         this.updatedThemes[index[0]].resources[index[3]].id
      //       );
      //       this.updatedThemes[index[0]].resources.splice(index[3], 1);
      //     } else {
      //       this.myResources = this.myResources.filter(
      //         (resource) => resource.resource_id !== this.resourceIdToDelete
      //       );
      //       this.resourcesDeleted.push(this.resourceIdToDelete);
      //       this.resourceIdToDelete = null;
      //     }
      //   } else {
      //     if (
      //       this.updatedThemes[index[0]].aparts[index[1]].resources[index[3]] !==
      //       undefined
      //     ) {
      //       this.myResources = this.myResources.filter(
      //         (resource) =>
      //           resource.resource_id !==
      //           this.updatedThemes[index[0]].aparts[index[1]].resources[index[3]]
      //             .id
      //       );
      //       this.resourcesDeleted.push(
      //         this.updatedThemes[index[0]].aparts[index[1]].resources[index[3]].id
      //       );
      //       this.updatedThemes[index[0]].aparts[index[1]].resources.splice(
      //         index[3],
      //         1
      //       );
      //     } else {
      //       this.myResources = this.myResources.filter(
      //         (resource) => resource.resource_id !== this.resourceIdToDelete
      //       );
      //       this.resourcesDeleted.push(this.resourceIdToDelete);
      //       this.resourceIdToDelete = null;
      //     }
      //   }
      this.myResources = this.myResources.filter(
        (resource) => resource.resource_id !== this.editing.resource_id
      );
      this.resourcesDeleted.push(this.editing.resource_id);
      this.editing = null;
      this.btnEnabled = true;
      this.editIndex = null;
    },
  },
  mixins: [translationMixin],
};
</script>

<style lang="scss">
@import "../../../src/assets/css/resources/resources.css";
</style>
